<template>
  <div>
    <b-alert variant="danger" :show="failed && errorList.length > 0">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Kennitala:"
        label-for="ssn"
        :state="submitted && errors.has('ssn') ? false : null"
        :feedback="errors.first('ssn')"
        description="Kennitala vinnustaðar (án bandstriks)."
      >
        <b-input-group>
          <b-form-input
            id="ssn"
            name="ssn"
            type="text"
            v-model="form.ssn"
            required
            placeholder="Sláðu inn kennitölu"
            :disabled="id !== null"
            :state="submitted && errors.has('username') ? false : null"
            v-validate="'required|length:10'"
            data-vv-as="kennitala"
            @input="checkSsn"
          ></b-form-input>
          <b-input-group-addon v-if="SSNCheckSuccess !== null" is-text>
            <strong class="text-danger" v-if="SSNCheckSuccess === false"><i class="fa fa-exclamation"></i></strong>
            <strong class="text-success" v-if="SSNCheckSuccess === true"><i class="fa fa-check"></i></strong>
          </b-input-group-addon>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Nafn:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
        description="Nafn vinnustaðar"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder=""
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="nafn"
        ></b-form-input>
      </b-form-group>
      <!-- <b-form-group
        label="Fj. plássa* :"
        label-for="slots"
        :state="submitted && errors.has('slots') ? false : null"
        :feedback="errors.first('slotsname')"
        description="Fjöldi nema sem vinnustaður getur tekið að sér í einu"
      >
        <b-form-input
          id="slots"
          name="slots"
          type="number"
          v-model="form.slots"
          required
          :state="submitted && errors.has('slots') ? false : null"
          v-validate="'required'"
          data-vv-as="pláss"
        ></b-form-input>
      </b-form-group> -->
      <!-- <b-form-group label="Starfsgreinar:" description="Starfsgreinar sem vinnustaður getur kennt">
        <shuttle v-model="this.form.professions" :list="professionList" required></shuttle>
      </b-form-group> -->
      <b-form-group
        label="Símanúmer:"
        label-for="phonenumber"
        :state="submitted && errors.has('phonenumber') ? false : null"
        :feedback="errors.first('phonenumber')"
        description="Símanúmer vinnustaðar"
      >
        <b-form-input
          id="phonenumber"
          name="phonenumber"
          type="tel"
          v-model="form.phonenumber"
          required
          placeholder=""
          :state="submitted && errors.has('phonenumber') ? false : null"
          v-validate="'required'"
          data-vv-as="simanumer"
        ></b-form-input>
      </b-form-group>
      <!-- <b-form-group v-if="this.id">
        <div>
          <div><strong>Tengiliðir</strong></div>
          <div v-if="form.contacts.length == 0"><i>Enginn tengiliður skráður</i></div>
          <table class="table mb-0" v-else>
            <tr
              v-for="(contact, index) in form.contacts"
              :key="contact.id"
              style="padding: 0px"
              :class="{ lightGray: index % 2 === 0, white: index % 2 !== 0 }"
            >
              <td>{{ contact.name }}</td>
              <td>{{ contact.email }}</td>
              <td>{{ contact.mobile }}</td>
              <td>
                <div
                  class="p-0 pull-right"
                  style="cursor: pointer; color: red"
                  v-b-tooltip.hover
                  title="Eyða tengilið"
                  @click.prevent="deleteContact(contact.id)"
                >
                  <i class="fa fa-fw fa-trash"></i>
                </div>
              </td>
            </tr>
          </table>

          <br />
          <div>Bæta við tengilið</div>
          <b-form inline>
            <b-row>
              <b-col>
                <b-form-group label-for="name" :state="submitted && errors.has('name') ? false : null" :feedback="errors.first('name')">
                  <b-form-input
                    id="contactName"
                    name="name"
                    type="text"
                    v-model="contact.name"
                    required
                    placeholder="Sláðu inn nafn"
                    :state="submitted && errors.has('name') ? false : null"
                    v-validate="'required'"
                    data-vv-as="nafn"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="email" :state="submitted && errors.has('email') ? false : null" :feedback="errors.first('email')">
                  <b-form-input
                    id="contactEmail"
                    name="email"
                    type="email"
                    v-model="contact.email"
                    required
                    placeholder="Sláðu inn netfang"
                    :state="submitted && errors.has('email') ? false : null"
                    v-validate="'required'"
                    data-vv-as="netfang"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="mobile"
                  :state="submitted && errors.has('mobile') ? false : null"
                  :feedback="errors.first('mobile')"
                >
                  <b-form-input
                    id="contactMobile"
                    name="mobile"
                    type="tel"
                    v-model="contact.mobile"
                    required
                    placeholder="Sláðu inn símanúmer"
                    :state="submitted && errors.has('mobile') ? false : null"
                    v-validate="'required'"
                    data-vv-as="símanúmer"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-btn @click.stop.prevent="saveContact"><i class="fa fa-fw fa-plus-circle"></i> Bæta við</b-btn>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-form-group> -->
      <br />
      <b-form-group
        label="Starfsgreinar:"
        description="Starfsgreinar sem vinnustaður getur kennt"
        label-for="professions"
        :state="submitted && errors.has('professions') ? false : null"
        :feedback="errors.first('professions')"
      >
        <v-select
          multiple
          placeholder="Skrifaðu inn í reitinn til að leita að starfsgreinum."
          @input="professionsChanged"
          :debounce="250"
          v-model="selected"
          :options="options"
        >
          <template slot="no-options">Engar fleiri starfsgreinar</template>
        </v-select>
      </b-form-group>
      <table class="table table-sm" v-if="form.professions.length > 0">
        <thead>
          <tr>
            <th>Starfsgrein</th>
            <th>Fj. plássa</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(p, index) in form.professions" :key="p.id">
            <td>{{ p.name }}</td>
            <td><b-form-input v-model="p.slots" :id="`type-${p.id}`" type="number" style="width: 100px"></b-form-input></td>
            <td><i class="fa fa-fw fa-trash" @click="remove(index)"></i></td>
          </tr>
        </tbody>
      </table>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'workplace-create-form',
  components: {},
  computed: {
    options() {
      return this.professionList
        .filter((v) => this.form.professions.filter((p) => p.id === v.id).length === 0)
        .map((v) => ({ value: v.id, label: v.name }));
    },
  },
  props: ['id'],
  data() {
    return {
      SSNCheckSuccess: null,
      submitted: false,
      loading: false,
      failed: false,
      errorList: [],
      professionList: [],
      selected: [],
      form: {
        ssn: '',
        name: '',
        phonenumber: '',
        slots: 0,
        professions: [],
        contacts: [],
      },
      contact: {
        name: '',
        email: '',
        mobile: '',
      },
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await core.professionList({ edit: 1 });
        this.professionList = response.data;
        if (this.id) {
          this.loadWorkplace();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja lista yfir starfsgreinar. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async loadWorkplace() {
      this.loading = true;
      try {
        const response = await core.workplaceDetail(this.id);
        this.form.ssn = response.data.ssn;
        this.form.name = response.data.name;
        this.form.phonenumber = response.data.phonenumber;
        this.form.professions = response.data.professions;
        this.form.contacts = response.data.contacts.map((h) => ({
          id: h.id,
          name: h.name,
          mobile: h.mobile,
          email: h.email,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja starfsgreinar vinnustaðar. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    professionsChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }
      val.forEach((v) =>
        this.form.professions.push({
          id: v.value,
          name: v.label,
          slots: 0,
        }),
      );
    },
    remove(index) {
      this.form.professions.splice(index, 1);
    },
    async checkSsn(val) {
      // return val;
      this.form.name = '';
      if (val && val.length === 10) {
        try {
          const response = await core.nationalRegistryCompany(val);
          this.SSNCheckSuccess = !response.data.user && response.data.is_thjodskra;
          if (!response.data.is_thjodskra) {
            this.displayError('Kennitala finnst ekki í fyrirtækjaskrá. Handskráðu kennitölu einstaklings og nafn ef þarf');
          }
          if (response.data.user) {
            this.displayError('Kennitala er nú þegar skráð');
          }

          if (!response.data.user && response.data.is_thjodskra) {
            this.form.name = response.data.name;
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(
            `Óvænt villa (${
              e.response ? e.response.status : -1
            }) kom upp við að sækja upplýsingar um kennitölu. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    async saveContact() {
      this.contactLoading = true;
      try {
        await core.contactCreate({
          name: this.contact.name,
          email: this.contact.email,
          mobile: this.contact.mobile,
          institution: null,
          workplace: this.id,
        });
        this.contact.name = '';
        this.contact.email = '';
        this.contact.mobile = '';
        this.getContactList();
        this.displaySuccess(`Tengiliðurinn „${this.contact.name}“ skráður.`);
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá tengilið. Vinsamlegast reyndu aftur.`);
      } finally {
        this.contactLoading = false;
      }
    },

    async deleteContact(id) {
      this.contactLoading = true;
      try {
        await core.contactDelete(id);
        this.getContactList();
        this.displaySuccess(`Tengilið hefur verið eytt.`);
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá tengilið. Vinsamlegast reyndu aftur.`);
      } finally {
        this.contactLoading = false;
      }
    },

    async getContactList() {
      try {
        this.Loading = false;
        const response = await core.workplaceContactList(this.id);
        this.form.contacts = response.data.results.map((h) => ({
          id: h.uuid,
          name: h.name,
          mobile: h.mobile,
          email: h.email,
        }));
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá tengilið. Vinsamlegast reyndu aftur.`);
      } finally {
        this.Loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        if (this.form.professions.length === 0) throw this.displayError('Vinsamlegast skráðu starfsgrein fyrirtækis');
        if (this.form.professions.length === 0) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        // Engar villur í formi.
        try {
          this.loading = true;

          const response = await core.workplaceUpdate(null, {
            ssn: this.form.ssn,
            name: this.form.name,
            phonenumber: this.form.phonenumber,
            slots: this.form.slots,
            professions: JSON.stringify(this.form.professions.map((g) => ({ id: g.id, slots: g.slots }))),
          });

          if (this.id) {
            this.displaySuccess(`Vinnustaðurinn „${this.form.name}“ hefur verið uppfærður.`);
          } else {
            this.displaySuccess(`Vinnustaðurinn „${this.form.name}“ hefur verið stofnaður.`);
          }
          this.id = response.data.id;

          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(
              `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá fyrirtæki. Vinsamlegast reyndu aftur.`,
            );
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss">
input,
textarea {
  width: 100%;
}
.style-chooser .vs__open-indicator {
  fill: white;
}
</style>
