<template>
  <div>
    <div v-if="id != null && book != null && book.profession_version != null">
      <b-card no-body class="mb-3 topBox">
        <b-row>
          <b-col md="12" lg="5">
            <table class="table mb-0">
              <tr>
                <td>Skóli</td>
                <td>{{ book.school.name }}</td>
              </tr>
              <tr>
                <td>Starfsgrein</td>
                <td>{{ book.profession_version.profession.name }}</td>
              </tr>
              <tr>
                <td>Nemandi</td>
                <td>
                  <div>{{ book.student.name }} kt.{{ book.student.id }}</div>
                </td>
              </tr>
              <tr>
                <td>Dags. frá</td>
                <td>
                  {{ book.date_from | moment('DD.MM.YYYY') }}
                </td>
              </tr>
              <tr>
                <td>Dags. til</td>
                <td>
                  {{ book.date_to | moment('DD.MM.YYYY') }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col md="12" lg="6">
            <table class="table mb-0">
              <tr v-if="book.is_mms === 1">
                <td>Vinnustaður&nbsp;</td>
                <td>
                  <span v-for="(workplace, index) in workplaces" :key="workplace.id">
                    <span v-if="index != 0"><br /></span>
                    {{ workplace.name }} kt.{{ workplace.ssn | subStr }}
                  </span>
                </td>
              </tr>
              <tr>
                <td v-if="book.is_education_center === 1">Matsaðili&nbsp;</td>
                <td v-else>Tilsjónaraðili&nbsp;</td>
                <td>
                  <span v-for="(supervisor, index) in supervisors" :key="supervisor.id">
                    <span v-if="index != 0"><br /></span>
                    {{ supervisor.name }} kt.{{ supervisor.id | subStr }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Umsýsluaðili&nbsp;</td>
                <td>
                  <span v-for="(instructor, index) in instructors" :key="instructor.id">
                    <span v-if="index != 0"><br /></span>
                    {{ instructor.name }} kt.{{ instructor.id | subStr }}
                  </span>
                </td>
              </tr>
            </table>
          </b-col>
          <b-col md="12" lg="">
            <table class="table mb-0">
              <b-link
                :to="{
                  name: 'EditCareerbookFrom',
                  params: {
                    profession: book.profession_version.profession.id,
                    id: book.id,
                  },
                }"
                v-if="editBook || supervisorEditBook"
                class="floatRight"
                style="position: absolute, padding-top: 0px; color: gray"
                v-b-tooltip.hover
                title="Breyta upplýsingum"
                ><i class="fa fa-pencil"></i
              ></b-link>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <hr />
          </b-col>
        </b-row>
        <b-row class="progress-row">
          <b-col sm="12" md="6">
            <div>
              <div class="d-flex justify-content-between">
                Síðasta skráning nemanda: {{ studentCompleted.lastRecord ?? 'Engin skráning' | date }}
                <span
                  class="top-right-tooltip"
                  v-b-tooltip.topleft
                  :title="`${getPercentage(studentCompleted.percentage)} hæfni náð`"
                  v-if="studentCompleted.percentage > 0"
                  >{{ studentCompleted.percentage | percentage }}</span
                >
              </div>
              <b-progress
                v-if="studentCompleted.percentage > 0"
                height="3px"
                :variant="studentCompleted.percentage === 0 ? 'success' : 'default'"
                :value="studentCompleted.percentage"
                max="1"
                class="mb-1"
              ></b-progress>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div>
              <div class="d-flex justify-content-between">
                Síðasta skráning tilsjónaraðila: {{ supervisorCompleted.lastRecord ?? 'Engin skráning' | date }}
                <span
                  v-b-tooltip.topleft
                  :title="`${getPercentage(supervisorCompleted.percentage)} staðfest af tilsjónaraðila`"
                  v-if="supervisorCompleted.percentage > 0"
                  >{{ supervisorCompleted.percentage | percentage }}</span
                >
              </div>
              <b-progress
                v-if="supervisorCompleted.percentage > 0"
                height="3px"
                :variant="supervisorCompleted.percentage === 0 ? 'success' : 'default'"
                :value="supervisorCompleted.percentage"
                max="1"
              ></b-progress>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else>
      <h3 class="text-center">{{ professionName }}</h3>
      <div class="text-center" v-if="versionName">
        <i>Útgáfa {{ versionName }}</i>
      </div>
      <b-btn variant="secondary" class="mb-2" style="width: 100%" v-if="canEdit" @click.prevent="versionModal">
        <i class="fa fa-fw fa-plus-circle"></i>
        Gefa út útgáfu
      </b-btn>
    </div>
    <category-modals
      ref="versionModal"
      :type="8"
      :professionId="professionId"
      :professionName="professionName"
      @successful="goToVersion"
    ></category-modals>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import CategoryModals from '@/components/careerbook/modal/CategoryModals.vue';

export default {
  name: 'careerbook-header',
  components: {
    CategoryModals,
  },
  props: [
    'professionName',
    'book',
    'id',
    'editBook',
    'canEdit',
    'professionId',
    'versionName',
    'supervisorEditBook',
    'studentCompleted',
    'supervisorCompleted',
  ],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    workplaces() {
      return this.book.workplaces.filter((v) => !v.is_deleted);
    },
    supervisors() {
      return this.book.supervisors.filter((v) => !v.is_deleted);
    },
    instructors() {
      return this.book.instructors.filter((v) => !v.is_deleted);
    },
  },
  filters: {
    subStr(string) {
      const newString = `${string.substring(0, 6)}-${string.substring(6, 10)}`;
      return newString;
    },
    date(string) {
      const date = moment(string);
      return date.isValid() ? date.format('DD.MM.YYYY') : string;
    },
    percentage(decimal) {
      const percentage = Math.round(decimal * 100);
      if (percentage === 100 && decimal !== 1) {
        return '99%';
      }
      return `${percentage}%`;
    },
  },
  methods: {
    goToVersion(version) {
      if (version && version.id) {
        this.$router.push({ name: 'CareerbookVersion', params: { profession: this.professionId, version: version.id } });
        this.$router.go();
      }
    },
    getPercentage(decimal) {
      return this.$options.filters.percentage(decimal);
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
    /*
     * Aðgerðir á modal gluggum.
     */
    versionModal() {
      this.$refs.versionModal.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  tr {
    th,
    td {
      &:first-child {
        font-weight: bold;
      }
      padding: 0px;
    }
  }
}
h3 {
  font-weight: 200;
}
.topBox {
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: -18px;
  border: none;
}
.floatRight {
  float: right;
}
tr:nth-child(even) {
  background: none;
}
tr:nth-child(odd) {
  background: none;
}
.progress-row {
  .progress {
    background-color: #fff;
  }
}
</style>
