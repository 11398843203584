<template>
  <div>
    <p>Athugið! Nauðsynlegt er að hafa kennitölu, netfang og farsímanúmer svo hægt sé að senda til undirritunar.</p>
    <p>Eftirfarandi aðilar fá boð um undirritun námssamnings:</p>
    <div class="pt-3 line lineWidth bold">Nemi</div>
    <div v-for="student in contacts" :key="student.uuid">
      <table width="100%">
        <tr>
          <td width="30">
            {{ student.name }}
          </td>
          <td width="30%">
            {{ student.ssn }}
          </td>
          <td width="30%" :class="{ redBack: !student.email }">
            {{ student.email }}
          </td>
          <td width="10%" :class="{ redBack: !student.mobile }">
            {{ student.mobile }}
          </td>
        </tr>
      </table>
      <div v-if="student.supervisors.length === 1" class="pt-3 line lineWidth bold">Tilsjónaraðili</div>
      <div v-else-if="student.supervisors.length > 1" class="pt-3 line lineWidth bold">Tilsjónaraðilar</div>
      <div v-for="supervisor in student.supervisors" :key="supervisor.uuid">
        <table width="100%">
          <tr>
            <td width="30%" if>
              {{ supervisor.name }}
            </td>
            <td width="30%">
              {{ supervisor.ssn }}
            </td>
            <td width="30%" :class="{ redBack: !supervisor.email }">
              {{ supervisor.email }}
            </td>
            <td width="10%" :class="{ redBack: !supervisor.mobile }">
              {{ supervisor.mobile }}
            </td>
          </tr>
        </table>
      </div>
      <div v-if="student.instructors.length === 1" class="pt-3 line lineWidth bold">Umsýsluaðili</div>
      <div v-else-if="student.instructors.length > 1" class="pt-3 line lineWidth bold">Umsýsluaðilar</div>
      <div v-for="instructor in student.instructors" :key="instructor.uuid">
        <table width="100%">
          <tr>
            <td width="30%">
              {{ instructor.name }}
            </td>
            <td width="30%">
              {{ instructor.ssn }}
            </td>
            <td width="30%" :class="{ redBack: !instructor.email }">
              {{ instructor.email }}
            </td>
            <td width="10%" :class="{ redBack: !instructor.mobile }">
              {{ instructor.mobile }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import book from '@/api/book';

export default {
  name: 'contract-contacts',
  props: ['id'],
  data() {
    return {
      loading: false,
      contacts: [],
    };
  },
  methods: {
    async getContacts() {
      this.loading = true;
      this.contacts = [];
      try {
        const response = await book.contractContactsList(this.id);
        this.contacts = response.data.results;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },

    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getContacts();
  },
};
</script>
<style lang="scss">
.lineWidth {
  width: 100%;
}
.bold {
  font-weight: bold;
}
.redBack {
  background-color: rgb(245, 120, 120);
}
</style>
