<template>
  <div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-checkbox
        v-for="reason in availableReason"
        :key="reason.id"
        v-model="cancel"
        :value="reason.text"
        :unchecked-value="false"
        :disabled="isDisabled(reason)"
      >
        {{ reason.text }}
        <br />
        <br />
      </b-form-checkbox>
    </b-form>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import book from '@/api/book';

export default {
  name: 'contract-cancel',
  props: ['id'],
  components: {},
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      cancel: '',
      availableReason: [
        { id: 0, text: '11. gr. reglugerðar' },
        { id: 1, text: 'Nemandi skiptir um vinnustað' },
        { id: 2, text: 'Nemandi hættir' },
        { id: 3, text: 'Annað' },
      ],
      form: {
        cancelReason: false,
      },
    };
  },
  methods: {
    isDisabled(reason) {
      const { cancel } = this;
      return cancel.length === 1 && cancel[0] !== reason.text;
    },
    async validateBeforeSubmit() {
      try {
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        try {
          const item = {
            deactivate: 1,
            cancelReason: this.cancel,
          };
          await book.signetContractDeactivate(this.id, item);
          this.displaySuccess('Samningur afvirkjaður');
          this.$emit('successful', {});
        } catch (e) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {},
};
</script>
