import Vue from 'vue';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir starfsgreinar.
   */
  professionCategoryList() {
    return Vue.http
      .get(config.PROFESSION_CATEGORY_LIST_URL, {})
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja einn starfsgreinaflokk.
   *
   * @param {number} id Einkenni starfsgreinaflokks til að sækja.
   */
  professionCategoryDetail(id) {
    return Vue.http
      .get(config.PROFESSION_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá starfsgreinaflokk
   *
   * @param {object} data Gögn um nýjna starfsgreinaflokk.
   */
  professionCategoryCreate(data) {
    return Vue.http
      .post(config.PROFESSION_CATEGORY_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra starfsgreinaflokk.
   *
   * @param {number} id Einkenni starfsgreinaflokks til að uppfæra.
   * @param {object} data Gögn um breytta starfsgreinaflokk.
   */
  professionCategoryUpdate(id, data) {
    return Vue.http
      .put(config.PROFESSION_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir starfsgreinar.
   */
  professionList({ category, createBook, edit, institution }) {
    return Vue.http
      .get(config.PROFESSION_LIST_URL, {
        params: {
          category,
          create_book: createBook,
          edit,
          institution,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina starfsgrein.
   *
   * @param {number} id Einkenni starfsgreinar til að sækja.
   */
  professionDetail(id) {
    return Vue.http
      .get(config.PROFESSION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá starfsgrein.
   *
   * @param {object} data Gögn um nýja starfsgrein.
   */
  professionCreate(data) {
    return Vue.http
      .post(config.PROFESSION_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra starfsgrein.
   *
   * @param {number} id Einkenni starfsgreinar til að uppfæra.
   * @param {object} data Gögn um breytta starfsgrein.
   */
  professionUpdate(id, data) {
    return Vue.http
      .put(config.PROFESSION_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða starfsgrein.
   *
   * @param {number} id Einkenni starfsgreinar til að eyða.
   */
  professionDelete(id) {
    return Vue.http
      .delete(config.PROFESSION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá samning.
   *
   * @param {object} data Gögn um nýjan samning.
   */
  contractCreate(data) {
    return Vue.http
      .post(config.CONTRACT_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja samninga
   */
  contractList() {
    return Vue.http
      .get(config.CONTRACT_LIST_URL, {})
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja signet samninga ferilbóka
   */
  signetContractList(page = null, limit = null, searchName = null, searchStatus = null, deactivated = null, userInstitution = null) {
    return Vue.http
      .get(config.SIGNET_CONTRACT_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
          searchStatus: searchStatus !== null ? searchStatus : null,
          deactivated,
          userInstitution,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá tengilið.
   *
   * @param {object} data Gögn um nýjan tengilið.
   */
  contactCreate(data) {
    return Vue.http
      .post(config.CONTACT_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða tengilið.
   *
   * @param {number} id Einkenni tengiliðs til að eyða.
   */
  contactDelete(id) {
    return Vue.http
      .delete(config.CONTACT_DETAILL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tengiliði stofnunar.
   * @param {number} id Einkenni stofnunar sem tengiliði tengjast.
   */
  institutionContactList(id) {
    return Vue.http
      .get(config.INSTITUTION_CONTACT_LIST_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tengiliði vinnustaðar.
   * @param {number} id Einkenni vinnustaðar sem tengiliði tengjast.
   */
  workplaceContactList(id) {
    return Vue.http
      .get(config.WORKPLACE_CONTACT_LIST_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir stofnanir.
   */
  institutionList(isSchool = null, allSchools = null, createUsers = null, searchName = null) {
    return Vue.http
      .get(config.INSTITUTION_LIST_URL, {
        params: {
          is_school: isSchool,
          all_school: allSchools,
          create_users: createUsers,
          search_name: searchName !== '' ? searchName : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina stofnun.
   *
   * @param {number} id Einkenni stofnunnar til að sækja.
   */
  institutionDetail(id) {
    return Vue.http
      .get(config.INSTITUTION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá stofnun.
   *
   * @param {object} data Gögn um nýja stofnun.
   */
  institutionCreate(data) {
    return Vue.http
      .post(config.INSTITUTION_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra stofnun.
   *
   * @param {number} id Einkenni stofnunnar til að uppfæra.
   * @param {object} data Gögn um breytta stofnun.
   */
  institutionUpdate(id, data) {
    return Vue.http
      .put(config.INSTITUTION_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða stofnun.
   *
   * @param {number} id Einkenni stofnunnar til að eyða.
   */
  institutionDelete(id) {
    return Vue.http
      .delete(config.INSTITUTION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir verkflokka.
   */
  factorCategoryList(profession, version) {
    return Vue.http
      .get(config.FACTOR_CATEGORY_LIST_URL, {
        params: {
          profession: !version ? profession : null,
          version,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn verkflokk.
   *
   * @param {number} id Einkenni verkflokks til að sækja.
   */
  factorCategoryDetail(id) {
    return Vue.http
      .get(config.FACTOR_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá verkflokk.
   *
   * @param {object} data Gögn um nýjan verkflokk.
   */
  factorCategoryCreate(data) {
    return Vue.http
      .post(config.FACTOR_CATEGORY_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra verkflokk.
   *
   * @param {number} id Einkenni verkflokks til að uppfæra.
   * @param {object} data Gögn um breyttan verkflokk.
   */
  factorCategoryUpdate(id, data) {
    return Vue.http
      .put(config.FACTOR_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra verkflokk.
   *
   * @param {number} id Einkenni verkflokks til að uppfæra.
   * @param {object} data Gögn um breyttan verkflokk. Bara sent inn það sem á að breyta
   */
  factorCategoryPatch(id, data) {
    // return Vue.http.patch(config.FACTOR_CATEGORY_DETAIL_URL.replace('{id}',
    // encodeURIComponent(id)), data)
    return Vue.http
      .put(config.FACTOR_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða verkflokk.
   *
   * @param {number} id Einkenni verkflokks til að eyða.
   */
  factorCategoryDelete(id) {
    return Vue.http
      .delete(config.FACTOR_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að raða öllum verkflokkum
   *
   * Ef alpha: true þá er raðað í stafrófsröð, ef clear: true þá er raðað eftir dagestningu
   */
  factorCategorySortAll(data) {
    return Vue.http
      .post(config.FACTOR_CATEGORY_SORT_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að raða einum verkflokk
   *
   * Ef up: true þá er fært einum ofan, ef down: true þá er fært einn niður
   */
  factorCategorySortOne(id, data) {
    return Vue.http
      .put(config.FACTOR_CATEGORY_SORT_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir flokk verkþátta.
   */
  factorSubCategoryList(category) {
    return Vue.http
      .get(config.FACTOR_SUBCATEGORY_LIST_URL, {
        params: {
          category,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn flokk verkþátts.
   */
  factorSubCategoryDetail(id) {
    return Vue.http
      .get(config.FACTOR_SUBCATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá  flokk verkþátts.
   */
  factorSubCategoryCreate(data) {
    return Vue.http
      .post(config.FACTOR_SUBCATEGORY_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra flokk verkþátts
   */
  factorSubCategoryUpdate(id, data) {
    return Vue.http
      .put(config.FACTOR_SUBCATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða flokk verkþátts
   */
  factorSubCategoryDelete(id) {
    return Vue.http
      .delete(config.FACTOR_SUBCATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að raða öllum vefþáttum
   *
   * Ef alpha: true þá er raðað í stafrófsröð, ef clear: true þá er raðað eftir dagestningu
   */
  factorSubCategorySortAll(data) {
    return Vue.http
      .post(config.FACTOR_SUBCATEGORY_SORT_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að raða öllum vefþáttum
   *
   * Ef up: true þá er fært einum ofan, ef down: true þá er fært einn niður
   */
  factorSubCategorySortOne(id, data) {
    return Vue.http
      .put(config.FACTOR_SUBCATEGORY_SORT_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir verkþætti.
   */
  factorList(category) {
    return Vue.http
      .get(config.FACTOR_LIST_URL, {
        params: {
          category,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn verkþátt.
   *
   * @param {number} id Einkenni verkþáttar til að sækja.
   */
  factorDetail(id) {
    return Vue.http
      .get(config.FACTOR_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn verkþátt.
   *
   * @param {number} id Einkenni verkþáttar til að sækja.
   */
  factorDescription(id) {
    return Vue.http
      .get(config.FACTOR_DESCRIPTION_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá verkþátt.
   *
   * @param {object} data Gögn um nýjan verkþátt.
   */
  factorCreate(data) {
    return Vue.http
      .post(config.FACTOR_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra verkþátt.
   *
   * @param {number} id Einkenni verkþátt til að uppfæra.
   * @param {object} data Gögn um breyttan verkþátt.
   */
  factorUpdate(id, data) {
    return Vue.http
      .put(config.FACTOR_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra verkþátt.
   */
  factorPatch(id, data) {
    return (
      Vue.http
        .put(config.FACTOR_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
        // return Vue.http.patch(config.FACTOR_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error))
    );
  },

  /**
   * Vefþjónusta til þess að eyða verkþætti.
   *
   * @param {number} id Einkenni verkþátts til að eyða.
   */
  factorDelete(id) {
    return Vue.http
      .delete(config.FACTOR_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að raða öllum vefþáttum
   *
   * Ef alpha: true þá er raðað í stafrófsröð, ef clear: true þá er raðað eftir dagestningu
   */
  factorSortAll(data) {
    return Vue.http
      .post(config.FACTOR_SORT_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að raða öllum vefþáttum
   *
   * Ef up: true þá er fært einum ofan, ef down: true þá er fært einn niður
   */
  factorSortOne(id, data) {
    return Vue.http
      .put(config.FACTOR_SORT_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra verkþátt.
   *
   * @param {number} id Einkenni verkþátt til að uppfæra.
   * @param {object} data Gögn um breyttan verkþátt.
   */
  factorUpdateFeature(id, data) {
    return Vue.http
      .post(config.FACTOR_FEATURE_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir lög og reglugerðir.
   */
  regulationList({ profession, version }) {
    return Vue.http
      .get(config.REGULATION_LIST_URL, {
        params: {
          profession,
          version,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja eina lög og reglugerð.
   *
   * @param {number} id Einkenni lög og reglugerðar til að sækja.
   */
  regulationDetail(id) {
    return Vue.http
      .get(config.REGULATION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá lög og reglugerð.
   *
   * @param {object} data Gögn um nýja lög og reglugerð.
   */
  regulationCreate(data) {
    return Vue.http
      .post(config.REGULATION_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra lög og reglugerð.
   *
   * @param {number} id Einkenni lög og reglugerðar til að uppfæra.
   * @param {object} data Gögn um breytta lög og reglugerð.
   */
  regulationUpdate(id, data) {
    return Vue.http
      .put(config.REGULATION_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða lög og reglugerð.
   *
   * @param {number} id Einkenni lög og reglugerðar til að eyða.
   */
  regulationDelete(id) {
    return Vue.http
      .delete(config.REGULATION_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir vinnustaði.
   */
  workplaceList(page = null, limit = null, profession = null, name = null) {
    return Vue.http
      .get(config.WORKPLACE_LIST_URL, {
        params: {
          page,
          limit,
          profession: profession !== null ? profession.id : null,
          name: name !== '' ? name : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn vinnustað.
   *
   * @param {number} id Einkenni vinnstaðar til að sækja.
   */
  workplaceDetail(id) {
    return Vue.http
      .get(config.WORKPLACE_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá vinnustað.
   *
   * @param {object} data Gögn um nýjan vinnustað.
   */
  workplaceCreate(data) {
    return Vue.http
      .post(config.WORKPLACE_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra vinnustað.
   *
   * @param {number} id Einkenni vinnustaðar til að uppfæra.
   * @param {object} data Gögn um breyttan vinnustað.
   */
  workplaceUpdate(id, data) {
    return Vue.http
      .put(config.WORKPLACE_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða vinnustað.
   *
   * @param {number} id Einkenni vinnustaðar til að eyða.
   */
  workplaceDelete(id) {
    return Vue.http
      .delete(config.WORKPLACE_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að fletta upp kennitölu fyrirtækis í þjóðskrá og fá nafn til baka
   *
   */
  nationalRegistryCompany(ssn) {
    return Vue.http
      .get(config.NATIONAL_REGISTRY_COMPANY_URL.replace('{ssn}', encodeURIComponent(ssn)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja starfslýsingu.
   */
  professionDescription(id) {
    return Vue.http
      .get(config.PROFESSION_DESCRIPTION_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra starfslýsingu.
   */
  professionDescriptionUpdate(id, data) {
    return Vue.http
      .put(config.PROFESSION_DESCRIPTION_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir flokka hæfnikrafna starfsgreinar.
   */
  competenceCategoryList(profession, version) {
    return Vue.http
      .get(config.COMPETENCE_CATEGORY_LIST_URL, {
        params: {
          profession: !version ? profession : null,
          version,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að skrá flokk hæfnikrafna.
   */
  competenceCategoryCreate(data) {
    return Vue.http
      .post(config.COMPETENCE_CATEGORY_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að uppfæra flokk hæfnikrafna.
   */
  competenceCategoryUpdate(id, data) {
    return Vue.http
      .put(config.COMPETENCE_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða flokk hæfnikrafna.
   */
  competenceCategoryDelete(id) {
    return Vue.http
      .delete(config.COMPETENCE_CATEGORY_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja hæfnikröfur flokks
   */
  competenceList(category) {
    return Vue.http
      .get(config.COMPETENCE_LIST_URL, {
        params: {
          category,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að skrá hæfnikröfur.
   */
  competenceCreate(data) {
    return Vue.http
      .post(config.COMPETENCE_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að uppfæra hæfnikröfur.
   */
  competenceUpdate(id, data) {
    return Vue.http
      .put(config.COMPETENCE_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að eyða hæfnikröfu.
   */
  competenceDelete(id) {
    return Vue.http
      .delete(config.COMPETENCE_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að tengja hæfnikröfu
   */
  competenceConnect(data) {
    return Vue.http
      .post(config.COMPETENCE_CONNECT_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja leiðbeiningar
   */
  instructionsList({ profession, version }) {
    return Vue.http
      .get(config.INSTRUCTIONS_LIST_URL, {
        params: {
          profession,
          version,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að skrá leiðbeiningar.
   */
  instructionsCreate(data) {
    return Vue.http
      .post(config.INSTRUCTIONS_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja yfirlit yfir útgáfur starfsgreinar.
   */
  professionVersionList(id) {
    return Vue.http
      .get(config.PROFESSION_VERSION_LIST_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn útgáfu.
   *
   * @param {number} id Einkenni útgáfu til að sækja.
   */
  professionVersionDetail(id, vid) {
    return Vue.http
      .get(config.PROFESSION_VERSION_DETAIL_URL.replace('{id}', encodeURIComponent(id)).replace('{vid}', encodeURIComponent(vid)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að stofna útgáfu starfsgreinar.
   *
   * @param {object} data Gögn um nýja útgáfu.
   */
  professionVersionCreate(id, data) {
    return Vue.http
      .post(config.PROFESSION_VERSION_LIST_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að útgáfu í excel skjali.
   *
   */
  VersionToExcel(profession, version) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.VERSION_GET_URL}?profession=${profession}&version=${version}`,
    }).then((success) => {
      const a = document.createElement('a');
      const disposition = success.headers['content-disposition'];
      let filename = null;

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      // if (window.navigator.msSaveOrOpenBlob) {
      //   // Fyrir IE
      //   window.navigator.msSaveOrOpenBlob(file);
      // } else {
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
      // }
    });
  },
  factorToExcel(profession, version) {
    Vue.http({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `${config.FACTOR_GET_URL}?profession=${profession}&version=${version}`,
    }).then((success) => {
      const a = document.createElement('a');
      const disposition = success.headers['content-disposition'];
      let filename = null;

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
      const file = new Blob([success.data], { type: success.headers['content-type'] });
      // if (window.navigator.msSaveOrOpenBlob) {
      //   // Fyrir IE
      //   window.navigator.msSaveOrOpenBlob(file);
      // } else {
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
      // }
    });
  },
  /**
   * Vefþjónusta til þess að skrá útgáfu starfsgreinar úr excel skjali.
   *
   */
  CareerbookVersionExcel(profession) {
    return Vue.http
      .post(config.CAREERBOOK_VERSION_POST_URL, profession)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá verkflokk úr excel skjali.
   *
   * @param {object} data Gögn um nýjan verkflokk.
   */
  factorCategoryExcel(data) {
    return Vue.http
      .post(config.FACTOR_CATEGORY_POST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá verkþátt úr excel skjali.
   *
   * @param {object} data Gögn um nýjan verkflokk.
   */
  factorExcel(data) {
    return Vue.http
      .post(config.FACTOR_POST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
