<template>
  <div class="text-center" @click.prevent="close">
    <img
      :src="`/fbk/book/download-image?key=${image.local_source}&type=1&book=${id}&token=${token}`"
      v-if="!loading"
      alt="Image broken"
      style="width: 600px"
    />
    <i class="fa fa-fw fa-spin fa-spinner" v-if="loading" style="font-size: 50px; color: #fff"></i>
  </div>
</template>

<script>
import auth from '@/api/auth';

export default {
  name: 'factor-photo',
  props: ['image', 'id'],
  data() {
    return {
      url: '',
      loading: false,
      token: auth.getToken(),
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style></style>
