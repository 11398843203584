<template>
  <div id="app">
    <router-view></router-view>
    <toast position="ne"></toast>
  </div>
</template>

<script>
import { Toast } from 'vuex-toast';
import { mapActions } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'app',
  components: {
    Toast,
  },
  data() {
    return {
      pingTimeout: null,
    };
  },
  methods: {
    async ping() {
      try {
        // Sækja innihald innskráningar.
        // const payload = auth.getPayload();
        const token = auth.getToken();

        // this.$log.debug(`ping ${!!payload}`);
        if (token) {
          const response = await auth.ping();
          if (response.successful && response.timeleft) {
            auth.setTokenExpire(response.timeleft);
          }
        } /*  else {
            auth.removeToken();
            this.doClean();
            this.$router.push({ name: 'Login', query: { timeout: 'true' } });
          } */
      } catch (e) {
        this.$log.debug(e);
        auth.removeToken();
        this.doClean();
        this.$router.push({ name: 'Login', query: { timeout: 'true' } });
      } finally {
        this.pingTimeout = setTimeout(() => {
          this.ping();
        }, 120000);
      }
    },
    ...mapActions(['doClean']),
  },
  created() {
    this.ping();
  },
  beforeDestroy() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
  },
};
</script>

<style lang="scss">
@import '~flatpickr/dist/flatpickr.css';
@import '~vuex-toast/dist/vuex-toast.css';
@import './style/bootstrap';
@import './style/main';

/**
  * Types
  */
.toast-type-info {
  // background-color: $state-info-bg;
}

.toast-type-success {
  background-color: $success;
}

.toast-type-warning {
  // background-color: $state-warning-bg;
}

.toast-type-danger {
  background-color: $danger;
}

.form-control {
  &.is-valid {
    border-color: #ced4da;
    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}
</style>
