<template>
  <div class="pb-2" :class="{ light_gray: index % 2 === 0, white: index % 2 !== 0 }">
    <div v-if="canEditCompetence">
      <b-row>
        <b-col>
          <inline-edit class="mb-0" field="name" :doEdit="edit" @editField="editField" @saveField="validateBeforeSubmit">
            <span slot="content"
              >{{ categoryIndex + 1 }}.{{ index + 1 }}. {{ item.name }}
              <strong class="cursorPointer" v-b-popover.hover.html.left="getFactors" title="Verkþættir">
                ({{ item.connected.length }})
              </strong></span
            >
            <span slot="input">
              <b-form-input
                id="name"
                name="name"
                type="text"
                v-model="name"
                required
                placeholder="Sláðu inn heiti"
                :state="submitted && errors.has('name') ? false : null"
                v-validate="'required'"
                data-vv-as="heiti"
                v-on:keyup.enter="validateBeforeSubmit"
                ref="input"
              ></b-form-input>
            </span>
          </inline-edit>
        </b-col>
        <b-col cols="1" style="margin-top: 8px">
          <div class="p-0 pull-right" style="color: red" v-b-tooltip.hover title="Eyða hæfnikröfu" @click.prevent="deleteCompetence">
            <p class="fa fa-fw fa-trash d-inline" style="font-size: 17px"></p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      {{ categoryIndex + 1 }}.{{ index + 1 }}. {{ item.name
      }}<strong class="cursorPointer" v-b-popover.hover.html.left="getFactors" title="Verkþættir">({{ item.connected.length }})</strong>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import vClickOutside from 'v-click-outside'; // eslint-disable-line import/no-unresolved
import core from '@/api/core';
import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'competence-item',
  props: ['item', 'index', 'categoryIndex', 'canEditCompetence'],
  components: {
    InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      edit: false,
      name: null,
      editFields: {},
      submitted: false,
      errorList: null,
    };
  },
  methods: {
    getFactors() {
      let html = '<ul>';
      this.item.connected.forEach((c) => {
        html += `<li>${c.factor.category_name}:  ${c.factor.name}</li>`;
      });
      html += '</ul>';

      if (this.item.connected.length === 0) {
        html = '<i>Engir tengdir</i>';
      }
      return html;
    },

    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          await core.competenceUpdate(this.item.id, {
            name: this.name,
          });

          this.item.name = this.name;
          this.displaySuccess(`Hæfnikrafa „${this.name}“ hefur verið uppfært.`);
          this.name = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að vista hæfnikröfu. Vinsamlegast reyndu aftur.`,
          );
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editField({ doEdit }) {
      if (doEdit) {
        this.edit = true;
        this.name = this.item.name;

        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      } else {
        this.edit = false;
        this.name = null;
      }
    },
    async deleteCompetence() {
        const conf = confirm(`Ertu viss um að þú viljir eyða hæfnikröfunni "${this.item.name}"?`); //eslint-disable-line
      if (conf) {
        try {
          await core.competenceDelete(this.item.id);
          this.$emit('updateCompetence', {});
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða hæfnikröfu. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style>
.light_gray {
  background-color: #f1f1f1;
  padding: 5px 5px 5px 5px;
}
.white {
  background-color: white;
  padding: 5px 5px 5px 5px;
}
.cursorPointer {
  float: right;
  cursor: pointer;
}
</style>
