<template>
  <div>
    <h4 v-if="!id">Hérna kemur yfirlit yfir verkflokkinn</h4>
    <b-card
      v-if="factorList.length > 0"
      header="Staða ferilbókar"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3 table table-hover"
    >
      <div class="card-text">
        <table style="width: 100%">
          <tr>
            <th style="width: 40%">Verkþáttur</th>
            <th style="width: 10%; text-align: center">Hæfni náð</th>
            <th style="width: 10%; text-align: center">Staðfest</th>
            <th style="width: 20%; text-align: center">Tímaskráning</th>
            <!-- <th style="width: 20%; text-align: center">Hversu oft</th> -->
          </tr>
          <tbody>
            <tr v-for="factor in factorList" :key="factor.id">
              <td>
                <a style="cursor: pointer" @click="viewOverview(factor)">{{ factor.name }}</a>
              </td>
              <td style="text-align: center">
                <i class="fa fa-fw" v-bind:class="{ 'fa-check text-success': factor.complete }"></i>
              </td>
              <td v-if="showTimelog" style="text-align: center">
                <i
                  class="fa fa-fw"
                  v-bind:class="{
                    'fa-check text-success': factor.supervisor_confirmed,
                  }"
                ></i>
              </td>
              <td style="text-align: center">{{ totalTime(factor) }} klst.</td>
              <!-- <td style="text-align: center">{{ factor.how_often }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <b-card
      v-if="showDescription"
      header="Lýsingar verkþátta"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3 table table-hover"
    >
      <div class="card-text">
        <table style="width: 100%">
          <tr>
            <th>Verkþáttur</th>
            <th>Athugasemd</th>
          </tr>
          <tbody>
            <tr v-for="factor in factorList" :key="factor.id">
              <td>
                <a style="cursor: pointer" @click="viewOverview(factor)">{{ factor.name }}</a>
              </td>
              <td><span v-html="factor.description"></span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <b-card
      v-if="showEvaluation"
      header="Námsmat"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3 table table-hover"
    >
      <div class="card-text">
        <table style="width: 100%">
          <tr>
            <th style="width: 30%">Verkþáttur</th>
            <th>Umsagnaraðili</th>
            <th>Umsögn</th>
            <th style="text-align: center">Einkunn tilsjónaraðila</th>
          </tr>

          <tbody v-for="factor in factorList" :key="factor.id">
            <tr v-for="evaluation in factor.evaluations" :key="evaluation.id">
              <td>
                <a style="cursor: pointer" @click="viewOverview(factor)">{{ factor.name }}</a>
              </td>
              <td>{{ evaluation.name }}</td>
              <td><span v-html="evaluation.text"></span></td>
              <td v-if="showGrade" style="text-align: center">{{ factor.grade }}</td>
            </tr>
            <!-- <tr v-if="factor.evaluations.length == 0">
                <td>
                  <a style="cursor:pointer" @click="viewOverview(factor)">{{ factor.name }}</a>
                </td>
              </tr> -->
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import book from '@/api/book';

export default {
  name: 'category-overview',
  props: ['professionId', 'category', 'id'],
  computed: {
    showTimelog() {
      return this.factorList.filter((f) => f.features.time_log).length > 0;
    },
    // showHowOften() {
    //   return this.factorList.filter(f => f.features.how_often).length > 0;
    // },
    showDescription() {
      return this.factorList.filter((f) => f.features.description).length > 0;
    },
    showEvaluation() {
      return this.factorList.filter((f) => f.features.supervisor_evaluation || f.features.instructor_evaluation).length > 0;
    },
    showGrade() {
      return this.factorList.filter((f) => f.features.grade).length > 0;
    },
  },
  data() {
    return {
      factorList: [],
      loading: false,
    };
  },
  methods: {
    viewOverview(factor) {
      this.$emit('successful', { factor: factor.id });
    },
    async getFactors() {
      this.loading = true;
      try {
        if (this.id) {
          const response = await book.careerbookFactorList(this.id, this.category);
          this.factorList = response.data;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
        Útreikningar
      */
    totalTime(factor) {
      let total = 0;
      factor.timelogs.forEach((log) => {
        total += parseFloat(log.hours);
      });
      return total;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getFactors();
  },
};
</script>
<style>
.lightGray {
  background-color: #f1f1f1;
}
.white {
  background-color: white;
}
</style>
