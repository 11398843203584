<template>
  <fbk-page>
    <b-card>
      <b-row>
        <b-col>
          <h5 class="my-4">Yfirlit yfir vinnustaði</h5>
        </b-col>
        <b-col>
          <div class="my-4 pull-right" v-if="loggedInUser.can_edit === 1">
            <b-link class="btn btn-secondary" href @click.prevent="workplaceCreateModal"
              ><i class="fa fa-fw fa-plus-circle"></i> Skrá vinnustað</b-link
            >
          </div>
        </b-col>
      </b-row>
      <!--div class="text-center" v-if="loading.workplace">
        <i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...
      </div-->
      <table class="table">
        <tr>
          <th style="padding: 10px 10px 10px 0px; width: 50%">
            <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir heiti eða kennitölu">
            </b-form-input>
          </th>
          <th style="padding: 10px 0px 10px 0px; width: 50%">
            <b-form-select id="profession" name="profession" v-model="searchProfession">
              <option :value="null">-- Veldu starfsgrein --</option>
              <option v-for="(profession, index) in professionList" :key="index" :value="profession">{{ profession.name }}</option>
            </b-form-select>
          </th>
        </tr>
      </table>

      <div>
        <table class="table table-hover">
          <tr>
            <th>Heiti</th>
            <th>Kennitala</th>
            <th>Símanúmer</th>
            <th>Fjöldi plássa</th>
            <th>Starfsgreinar</th>
          </tr>
          <tbody>
            <tr v-if="!loading.workplace && workplaceList.length == 0">
              <td colspan="3">
                <i>Engar leitarniðurstöður fundust.</i>
              </td>
            </tr>
            <tr v-if="loading.workplace">
              <td colspan="3">
                <i class="fa fa-fw fa-spin fa-spinner text-center"></i>
              </td>
            </tr>
            <tr v-for="(workplace, index) in workplaceList" :key="index">
              <!--td style="width: 40px;" class="pr-0 pt-3">
                <avatar :name="workplace.name" :color="0" class="d-inline-block mr-2"></avatar>
              </td-->
              <td>
                {{ workplace.name }}
                <b-link
                  class="text-secondary"
                  @click.prevent="workplaceUpdateModal(workplace.id)"
                  v-if="canEdit(workplace)"
                  v-b-tooltip.hover
                  title="Breyta vinnustað"
                >
                  <i class="fa fa-fw fa-pencil hover-edit"></i>
                </b-link>
              </td>
              <td>
                {{ workplace.ssn | subStr }}
              </td>
              <td>
                {{ workplace.phonenumber }}
              </td>
              <td>
                {{ workplace.slots }}
              </td>
              <td>
                <span v-for="(profession, index) in workplace.professions" :key="profession.id">
                  <span v-if="index != 0">, </span>{{ profession.name }}({{ profession.slots }})
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
      </div>
    </b-card>
    <!-- Modal til að skrá vinnustað -->
    <workplace-crud-modal ref="workplaceCreateModal" @successful="workplaceUpdated"></workplace-crud-modal>
    <!-- Modal til að uppfæra vinnustað -->
    <workplace-crud-modal ref="workplaceUpdateModal" @successful="workplaceUpdated"></workplace-crud-modal>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
import Pagination from '@/components/Pagination.vue';
import WorkplaceCrudModal from '@/components/workplace/WorkplaceCrudModal.vue';

import { debounce, paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'workplace-list',
  mixins: [paginationMixin],
  components: {
    Pagination,
    WorkplaceCrudModal,
  },
  data() {
    return {
      loading: {
        workplace: false,
        profession: false,
      },
      page: null,
      pagination: null,
      workplaceList: [],
      professionList: [],
      searchProfession: null,
      searchName: '',
    };
  },
  filters: {
    subStr(string) {
      const newString = `${string.substring(0, 6)}-${string.substring(6, 10)}`;
      return newString;
    },
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir vinnustaði
     */
    async loadList(page = null, limit = null) {
      this.loading.workplace = true;
      this.pagination = null;
      this.workplaceList = [];
      try {
        const response = await core.workplaceList(page, limit, this.searchProfession, this.searchName);
        this.workplaceList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir vinnustaði. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading.workplace = false;
      }
    },
    async loadProfession() {
      this.loading.profession = true;
      try {
        const response = await core.professionList({});
        this.professionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${
            e.response ? e.response.status : -1
          }) kom upp við að sækja yfirlit yfir starfsgreinar. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading.profession = false;
      }
    },
    canEdit(workplace) {
      return workplace.professions.filter((x) => x.can_edit === 1).length > 0;
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    workplaceCreateModal() {
      this.$refs.workplaceCreateModal.show();
    },
    workplaceUpdateModal(id) {
      this.$refs.workplaceUpdateModal.show(id);
    },
    workplaceUpdated() {
      this.loadList();
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
    searchProfession: debounce(function () {
      this.loadList(null, null);
    }, 500),
  },
  created() {
    this.loadList();
    this.loadProfession();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
