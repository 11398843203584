<template>
  <div>
    <b-alert variant="danger" :show="failed">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Nafn:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
        description="Nafn stofnunar"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder="Sláðu inn nafn"
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="nafn"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Kennitala:"
        label-for="ssn"
        :state="submitted && errors.has('ssn') ? false : null"
        :feedback="errors.first('ssn')"
        description="Kennitala stofnunar"
      >
        <b-form-input
          id="ssn"
          name="ssn"
          type="text"
          v-model="form.ssn"
          required
          placeholder="Sláðu inn kennitölu"
          :state="submitted && errors.has('ssn') ? false : null"
          v-validate="'required'"
          data-vv-as="kennitala"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Símanúmer:"
        label-for="phonenumber"
        :state="submitted && errors.has('phonenumber') ? false : null"
        :feedback="errors.first('phonenumber')"
        description="Símanúmer stofnunar"
      >
        <b-form-input
          id="phonenumber"
          name="phonenumber"
          type="tel"
          v-model="form.phone_number"
          required
          placeholder="Sláðu inn símanúmer"
          :state="submitted && errors.has('phonenumber') ? false : null"
          v-validate="'required'"
          data-vv-as="símanúmer"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group>
        <div>
          <div><strong>Tengiliðir</strong></div>
          <div v-if="form.contacts.length == 0"><i>Enginn tengiliður skráður</i></div>
          <table class="table mb-0" v-else>
            <tr
              v-for="(contact, index) in form.contacts"
              :key="contact.id"
              style="padding: 0px"
              :class="{ lightGray: index % 2 === 0, white: index % 2 !== 0 }"
            >
              <td>{{ contact.name }}</td>
              <td>{{ contact.email }}</td>
              <td>{{ contact.mobile }}</td>
              <td>
                <div
                  class="p-0 pull-right"
                  style="cursor: pointer; color: red"
                  v-b-tooltip.hover
                  title="Eyða tengilið"
                  @click.prevent="deleteContact(contact.id)"
                >
                  <i class="fa fa-fw fa-trash"></i>
                </div>
              </td>
            </tr>
          </table>

          <br />
          <div>Bæta við tengilið</div>
          <b-form inline>
            <b-row>
              <b-col>
                <b-form-group label-for="name" :state="submitted && errors.has('name') ? false : null" :feedback="errors.first('name')">
                  <b-form-input
                    id="contactName"
                    name="name"
                    type="text"
                    v-model="contact.name"
                    required
                    placeholder="Sláðu inn nafn"
                    :state="submitted && errors.has('name') ? false : null"
                    v-validate="'required'"
                    data-vv-as="nafn"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="email" :state="submitted && errors.has('email') ? false : null" :feedback="errors.first('email')">
                  <b-form-input
                    id="contactEmail"
                    name="email"
                    type="email"
                    v-model="contact.email"
                    required
                    placeholder="Sláðu inn netfang"
                    :state="submitted && errors.has('email') ? false : null"
                    v-validate="'required'"
                    data-vv-as="netfang"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="mobile"
                  :state="submitted && errors.has('mobile') ? false : null"
                  :feedback="errors.first('mobile')"
                >
                  <b-form-input
                    id="contactMobile"
                    name="mobile"
                    type="tel"
                    v-model="contact.mobile"
                    required
                    placeholder="Sláðu inn símanúmer"
                    :state="submitted && errors.has('mobile') ? false : null"
                    v-validate="'required'"
                    data-vv-as="símanúmer"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-btn @click.stop.prevent="saveContact" class="pull-right"><i class="fa fa-fw fa-plus-circle"></i> Bæta við</b-btn>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-form-group> -->

      <b-form-group
        label="Skóli:"
        label-for="is_school"
        :state="submitted && errors.has('is_school') ? false : ''"
        :feedback="errors.first('is_school')"
        description="Er stofnun skóli ?"
      >
        <b-form-checkbox id="is_school" name="is_school" v-model="form.is_school" :value="true" :unchecked-value="false"> </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Aðgangur að starfsgreinum:"
        label-for="professions"
        :state="submitted && errors.has('professions') ? false : null"
        description="Veldu hlut úr listanum til þess að bæta við aðgang."
        :feedback="errors.first('professions')"
      >
        <v-select
          id="professions"
          name="professions"
          multiple
          placeholder="Skrifaðu inn í reitinn til að leita að starfsgreinum."
          @input="professionsChanged"
          :debounce="250"
          v-model="selected"
          :options="options"
          class="style-chooser"
        >
          <template slot="no-options">Engar fleiri stofnanir.</template>
        </v-select>
      </b-form-group>

      <table class="table table-sm">
        <thead>
          <tr>
            <th>Starfsgrein</th>
            <th>Lesaðgangur</th>
            <th>Skrifaðgangur</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in form.professions" :key="p.id">
            <td>{{ p.name }}</td>
            <td>
              <b-form-checkbox v-model="p.read" :disabled="p.write" :value="true" :unchecked-value="false"> </b-form-checkbox>
            </td>
            <td>
              <b-form-checkbox v-model="p.write" @change="p.read = true" :value="true" :unchecked-value="false"> </b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'institution-update-form',
  props: ['id'],
  data() {
    return {
      submitted: false,
      loading: false,
      contactLoading: false,
      failed: false,
      updated: false,
      errorList: [],
      professionList: [],
      selected: [],
      name: null,
      form: {
        name: '',
        ssn: '',
        phone_number: '',
        is_school: '',
        professions: [],
        contacts: [],
      },
      contact: {
        name: '',
        email: '',
        mobile: '',
      },
    };
  },
  computed: {
    options() {
      return this.professionList
        .filter((v) => this.form.professions.filter((p) => p.id === v.id).length === 0)
        .map((v) => ({ value: v.id, label: v.name }));
    },
  },
  methods: {
    professionsChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }

      val.forEach((v) =>
        this.form.professions.push({
          id: v.value,
          name: v.label,
          read: true,
          write: false,
        }),
      );
    },
    async load() {
      try {
        this.loading = true;
        const response = await Promise.all([core.institutionDetail(this.id), core.professionList({ institution: this.id })]);
        this.form.name = response[0].data.name;
        this.form.ssn = response[0].data.ssn;
        this.form.phone_number = response[0].data.phonenumber;
        this.form.professions = response[0].data.professions.map((g) => ({
          id: g.id,
          name: g.name,
          read: g.read === 1,
          write: g.write === 1,
        }));
        this.form.contacts = response[0].data.contacts.map((h) => ({
          id: h.id,
          name: h.name,
          mobile: h.mobile,
          email: h.email,
        }));
        this.form.is_school = response[0].data.is_school === 1;
        this.professionList = response[1].data;
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },

    async saveContact() {
      this.contactLoading = true;
      try {
        await core.contactCreate({
          name: this.contact.name,
          email: this.contact.email,
          mobile: this.contact.mobile,
          institution: this.id,
          workplace: null,
        });
        this.contact.name = '';
        this.contact.email = '';
        this.contact.mobile = '';
        this.getContactList();
        this.displaySuccess(`Tengiliðurinn „${this.contact.name}“ skráður.`);
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá tengilið. Vinsamlegast reyndu aftur.`);
      } finally {
        this.contactLoading = false;
      }
    },

    async deleteContact(id) {
      this.contactLoading = true;
      try {
        await core.contactDelete(id);
        this.getContactList();
        this.displaySuccess(`Tengilið hefur verið eytt.`);
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá tengilið.
      Vinsamlegast reyndu aftur.`);
      } finally {
        this.contactLoading = false;
      }
    },

    async getContactList() {
      try {
        this.Loading = false;
        const response = await core.institutionContactList(this.id);
        this.form.contacts = response.data.results.map((h) => ({
          id: h.uuid,
          name: h.name,
          mobile: h.mobile,
          email: h.email,
        }));
      } catch (e) {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skrá tengilið. Vinsamlegast reyndu aftur.`);
      } finally {
        this.Loading = false;
      }
    },

    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const professions = this.form.professions.map((g) => ({
            id: g.id,
            write: g.write === true ? 1 : 0,
          }));

          await core.institutionUpdate(this.id, {
            name: this.form.name,
            ssn: this.form.ssn,
            phone_number: this.form.phone_number,
            is_school: this.form.is_school,
            professions: JSON.stringify(professions),
          });

          this.updated = true;
          this.displaySuccess(`Stofnunin „${this.form.name}“ uppfærð.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss">
input,
textarea {
  width: 100%;
}
.style-chooser .vs__open-indicator {
  fill: white;
}
</style>
