<template>
  <div>
    <b-card
      v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1"
      header="Námssamningur"
      header-bg-variant="secondary"
      header-text-variant="white"
      header-class="h5"
      class="mt-3"
    >
      <div></div>
      <div v-if="contracts.length !== 0">
        <div>
          <table width="100%">
            <thead>
              <tr>
                <th width="10%">Námsleið</th>
                <th width="20%">Skóli</th>
                <th style="text-align: center" width="5%"></th>
                <th width="20%">Staða</th>
                <th width="15%">Dags. sent</th>
                <th width="15%">Dags. undirritun</th>
                <th width="20%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contract in contracts" :key="contract.uuid">
                <td v-if="contract.contract === 'SKOLALEID'">Skólaleið</td>
                <td v-if="contract.contract === 'MEISTARALEID'">Meistaraleið</td>
                <td width="15">
                  {{ contract.institution }}
                </td>
                <td :id="`contract-${contract.book_contract_id}`" style="text-align: center">
                  <i class="fa fa-fw fa-users"></i>
                </td>
                <b-popover :target="`contract-${contract.book_contract_id}`" triggers="hover">
                  <template #title>Undirritendur</template>
                  <ContactListPopover
                    :contract="contract"
                    :showGuardian="studentUnderAge === true && contract.status !== 'REGISTERED'"
                    :guardianSignerName="guardianSigner.name"
                  ></ContactListPopover>
                </b-popover>
                <td v-if="contract.status === 'NEW'">Vistaður</td>
                <td v-if="contract.status === 'SIGNED'">Undirritaður</td>
                <td v-if="contract.status === 'SUBMITTED'">Sendur til undirritunar</td>
                <td v-if="contract.status === 'REJECTED'">Hafnað</td>
                <td v-if="contract.status === 'DEACTIVATED'">
                  Afvirkjaður<i class="fa fa-fw fa-info-circle" v-b-tooltip.hover :title="`Ástæða: ${contract.deactivate_reason}`"></i>
                </td>
                <td v-if="contract.status === 'REGISTERED'">Undirritaður (skráður samningur)</td>
                <td v-if="!contract.submitted_at"></td>
                <td v-else>
                  {{ contract.submitted_at | moment('DD.MM.YYYY') }}
                </td>
                <td v-if="!contract.signed_at"></td>
                <td v-else>
                  {{ contract.signed_at | moment('DD.MM.YYYY') }}
                </td>
                <td style="min-width: 185px">
                  <b-link
                    v-if="isInstructor === true && contract.status === 'NEW'"
                    variant="primary"
                    class="mb-2 pull-right"
                    @click="deleteContract(contract.uuid)"
                    :disabled="submittingContract"
                  >
                    <i class="fa fa-fw fa-spin fa-spinner" v-if="submittingContract"></i>
                    <i class="fa fa-fw fa-trash"></i>
                    Eyða
                  </b-link>
                  <b-link
                    v-if="
                      (isInstructor === true || loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1) &&
                      contract.status === 'NEW'
                    "
                    variant="primary"
                    class="mb-2 mr-2 pull-right"
                    @click="submitContract(contract.uuid)"
                    :disabled="submittingContract"
                  >
                    <i class="fa fa-fw fa-spin fa-spinner" v-if="submittingContract"></i>
                    <i class="fa fa-fw fa-send"></i>
                    Senda
                  </b-link>
                  <b-link
                    v-if="contract.status === 'NEW'"
                    variant="primary"
                    class="mb-2 mr-2 pull-right"
                    @click="previewContract(contract.uuid)"
                    :disabled="submittingContract"
                  >
                    <i class="fa fa-fw fa-spin fa-spinner" v-if="submittingContract"></i>
                    <i class="fa fa-fw fa-search"></i>
                    Skoða
                  </b-link>
                  <b-link
                    v-if="contract.status === 'SIGNED'"
                    variant="primary"
                    class="mb-2 mr-2 pull-right"
                    @click="viewContract(contract.uuid)"
                    ><i class="fa fa-fw fa-download"></i> Sækja
                  </b-link>
                  <b-link
                    v-if="contract.status === 'SIGNED' || contract.status === 'SUBMITTED'"
                    variant="primary"
                    class="mb-2 mr-2 pull-right"
                    href
                    @click.prevent="cancelContractModal(contract.uuid)"
                    ><i class="fa fa-fw fa-times"></i> Afvirkja
                  </b-link>
                  <b-link
                    v-if="contract.status === 'SUBMITTED'"
                    variant="primary"
                    class="mb-2 mr-2 pull-right"
                    href
                    @click.prevent="remindContractModal(contract)"
                    ><i class="fa fa-fw fa-bell"></i> Minna á
                  </b-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else-if="!id && canEdit"><i>Námssamningar koma hér</i></div>
      <div v-else><i>Námssamningur ekki vistaður</i></div>
      <div class="pull-right pt-3" v-if="(isInstructor || (loggedInUser.is_admin === 1) === true) && !(!id && canEdit)">
        <div>
          <b-link :to="{ name: 'SendContract', params: { id: id } }" v-b-tooltip.hover title="Sniðmát fyrir samning"
            ><i class="fa fa-fw fa-plus"></i>Stofna nýjan samning</b-link
          >
        </div>
        <br />
        <div>
          <b-link
            :to="{ name: 'RegisterContract', params: { id: id, institution: book.school ? book.school.name : '' } }"
            v-b-tooltip.hover
            title="Sniðmát fyrir gildan samning"
          >
            <i class="fa fa-fw fa-plus"></i>Skrá gildan samning
          </b-link>
        </div>
      </div>
    </b-card>
    <b-card header="Upplýsingar" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
      <h5>Almennar upplýsingar</h5>
      <div v-html="instructions"></div>
      <div v-if="instructions == null || instructions.length == 0"><i>Upplýsingar ekki skráðar</i></div>
      <br />
      <h5 ng-show="professionInstructions.length > 0 || !id">
        Upplýsingar tengdar <strong>{{ professionName }}</strong>
      </h5>
      <profession-instructions-edit
        v-if="!id && canEdit"
        :instructions="professionInstructions"
        :professionId="professionId"
        @successful="loadProfessionInstructions"
      >
      </profession-instructions-edit>
      <div v-else>
        <div v-html="professionInstructions"></div>
        <div v-if="professionInstructions == null || professionInstructions.length == 0"><i>Upplýsingar ekki skráðar</i></div>
      </div>
    </b-card>
    <b-card header="Lög og reglugerðir" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
      <h5>Almenn lög og reglugerðir</h5>
      <div v-for="link in globalLinks" :key="link.id">
        <a :href="link.url" target="_blank">{{ link.name }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
      </div>
      <div v-if="globalLinks.length === 0"><i>Engar reglugerðir skráðar</i></div>
      <br />
      <h5 ng-show="professionLinks.length > 0 || !id">
        Lög og reglugerðir tengdar <strong>{{ professionName }}</strong>
      </h5>
      <div v-if="professionLinks.length === 0 && !canEdit"><i>Engar reglugerðir skráðar</i></div>
      <div v-for="link in professionLinks" :key="link.id">
        <div v-if="!id && canEdit">
          <regulation-list-item :item="link" @successful="loadProfessionList"></regulation-list-item>
        </div>
        <div v-else>
          <a :href="link.url" target="_blank">{{ link.name }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
        </div>
      </div>
    </b-card>
    <b-form @submit.prevent="saveRegulation" novalidate v-if="!id && canEdit" class="mt-3">
      <b-row>
        <b-col>
          <b-form-group label-for="name" :state="submitting && errors.has('name') ? false : null" :feedback="errors.first('name')">
            <b-form-input
              id="name"
              name="name"
              type="text"
              v-model="regulation.name"
              required
              placeholder="Sláðu inn heiti reglugerðar"
              :state="submitting && errors.has('name') ? false : null"
              v-validate="'required'"
              class="mr-2"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="url" :state="submitting && errors.has('url') ? false : null" :feedback="errors.first('url')">
            <b-input-group>
              <b-form-input
                id="url"
                name="url"
                type="text"
                v-model="regulation.url"
                required
                placeholder="Sláðu inn hlekk á reglugerðina"
                :state="submitting && errors.has('url') ? false : null"
                v-validate="'required'"
                v-on:keyup.enter="saveRegulation()"
              >
              </b-form-input>
              <b-input-group-append>
                <button type="button" class="btn btn-sm btn-primary" @click="saveRegulation()">Bæta við</button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <br />
    <contract-cancel-modal ref="cancelContractModal" @successful="cancelContractDone"></contract-cancel-modal>
    <contract-remind-modal ref="remindContractModal" @accept="remindContract"></contract-remind-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';
import core from '@/api/core';
import book from '@/api/book';
import { djangoErrorsToVee } from '@/utils';
import RegulationListItem from '@/components/careerbook/regulation/RegulationListItem.vue';
import ProfessionInstructionsEdit from '@/components/careerbook/regulation/ProfessionInstructionsEdit.vue';
import { ContractsApiAxiosParamCreator } from '@/api/openapi';
import ContractCancelModal from '@/components/careerbook/regulation/ContractCancelModal.vue';
import ContactListPopover from '@/components/careerbook/regulation/ContactListPopover.vue';
import ContractRemindModal from '@/components/careerbook/regulation/ContractRemindModal.vue';

export default {
  name: 'careerbook-regulation-list',
  components: {
    RegulationListItem,
    ProfessionInstructionsEdit,
    ContractCancelModal,
    ContactListPopover,
    ContractRemindModal,
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  props: ['professionId', 'professionName', 'isInstructor', 'canEdit', 'versionId', 'id', 'book'],
  data() {
    return {
      loading: false,
      loadingProfession: false,
      editProfession: false,
      regulation: { name: '', url: '' },
      instructions: '',
      professionInstructions: '',
      submitting: false,
      globalLinks: [],
      professionLinks: [],
      contracts: [],
      submittingContract: false,
      remindingContract: false,
      studentUnderAge: false,
      guardianAge: 0,
      guardians: [],
      guardianSigner: {},
    };
  },
  methods: {
    async getStudentGuardianSignetSigner() {
      this.studentUnderAge = false;
      this.guardianAge = 0;
      if (this.book.student.age < 18) {
        this.studentUnderAge = true;
        try {
          const response = await book.searchStudentInformation(this.book.student.id, this.book.school.ssn);
          if (response.data.results[0]) {
            this.guardians = response.data.results[0].adstandendur;

            this.guardians.forEach((e) => {
              if (e.forrad_adstand === 1 && e.aldur > this.guardianAge) {
                this.guardianAge = e.aldur;
                this.guardianSigner = {};
                this.guardianSigner = {
                  ssn: e.ktala,
                  name: e.nafn,
                  email: e.netfang,
                  mobile: e.simi_gsm,
                  user_id: this.book.student.user_id,
                };
              }
            });
          }
        } catch (e) {
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async validateGuardianSignetSigner(contractId) {
      if (!this.guardianSigner.email || !this.guardianSigner.mobile) {
        this.displayError(
          this.guardianSigner.name
            ? `Upplýsingar fyrir forsjáraðilann ${this.guardianSigner.name} í INNU vantar. Vinsamlegast fylltu út réttar upplýsingar.`
            : 'Forsjáraðila frá INNU vantar. Vinsamlegast fylltu út réttar upplýsingar',
        );
        return false;
      }
      try {
        const item = {
          ssn: this.guardianSigner.ssn,
          name: this.guardianSigner.name,
          email: this.guardianSigner.email,
          mobile: this.guardianSigner.mobile,
          user_id: this.guardianSigner.user_id,
          book_contract_id: contractId,
        };
        await book.signetContractGuardianContact(item);
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
      return true;
    },
    async previewContract(id) {
      const params = await ContractsApiAxiosParamCreator().preview(id);
      window.open(`${params.url}?token=${auth.getToken()}`, '_blank');
    },
    async viewContract(id) {
      const params = await ContractsApiAxiosParamCreator().view(id);
      window.open(`${params.url}?token=${auth.getToken()}`, '_blank');
    },
    async deleteContract(id) {
      const conf = confirm(`Ertu viss um að þú viljir eyða samningnum"?`); //eslint-disable-line
      if (conf) {
        try {
          await book.signetContractDelete(id);
          this.loadContracts();
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða samningum. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    async submitContract(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const conf = confirm('Ert þú viss um að þú viljir senda þennan samning til undirritunar?');

      if (!conf) {
        return;
      }
      if (this.studentUnderAge && !(await this.validateGuardianSignetSigner(id))) {
        return;
      }

      this.submittingContract = true;
      try {
        await this.$contractsApi.submit({
          contractId: id,
        });
        this.loadContracts();
        this.displaySuccess('Samningur hefur verið sendur til undirritunar.');
      } catch {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submittingContract = false;
      }
    },
    async loadGlobalList() {
      this.loading = true;
      this.globalLinks = [];
      try {
        const response = await core.regulationList({});
        this.globalLinks = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadProfessionList() {
      this.loadingProfession = true;
      this.professionLinks = [];
      try {
        const params = {
          profession: !this.versionId ? this.professionId : null,
          version: this.versionId,
        };
        const response = await core.regulationList(params);
        this.professionLinks = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingProfession = false;
      }
    },
    async loadProfessionInstructions() {
      try {
        const params = {
          profession: !this.versionId ? this.professionId : null,
          version: this.versionId,
        };
        const response = await core.instructionsList(params);
        this.professionInstructions = response.data.instructions;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadInstructions() {
      try {
        const response = await core.instructionsList({});
        this.instructions = response.data.instructions;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadContracts() {
      try {
        const response = await book.signetContracts(this.id);
        this.contracts = response.data.results;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    validateUrl() {
      const regex = /^(http|https)/;
      if (this.regulation.url.length > 3 && !this.regulation.url.match(regex)) {
        this.regulation.url = `http://${this.regulation.url}`;
      }
    },
    async saveRegulation() {
      try {
        this.submitting = true;
        await (this.$validator.validate('url') && this.$validator.validate('name')).then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          this.validateUrl();
          await core.regulationCreate({
            profession: this.professionId,
            url: this.regulation.url,
            name: this.regulation.name,
          });
          this.loadProfessionList();
          this.displaySuccess(`Reglugerðin „${this.regulation.name}“ stofnuð`);
          this.regulation.name = '';
          this.regulation.url = '';
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.submitting = true;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    cancelContractModal(id) {
      this.$refs.cancelContractModal.show(id);
    },
    cancelContractDone() {
      // loada listum aftur
      if (this.id) this.getStudentGuardianSignetSigner();
      this.loadGlobalList();
      this.loadProfessionList();
      this.loadInstructions();
      this.loadProfessionInstructions();
      this.loadContracts();
    },
    remindContractModal(contract) {
      const model = {
        id: contract.uuid,
        lastReminder: contract.reminded_at,
        contacts: contract.contacts.map((c) => ({
          name: c.name,
          signedAt: c.signed_at,
        })),
      };
      if (contract.relative_contact) {
        model.contacts.push(contract.relative_contact);
      }
      this.$refs.remindContractModal.show(model);
    },
    async remindContract(id) {
      this.remindingContract = true;
      try {
        await this.$contractsApi.remind({
          contractId: id,
        });
        this.displaySuccess('Áminning hefur verið send til þeirra sem eiga eftir að skrifa undir samning.');
      } catch {
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.remindingContract = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if (this.id) this.getStudentGuardianSignetSigner();
    this.loadGlobalList();
    this.loadProfessionList();
    this.loadInstructions();
    this.loadProfessionInstructions();
    this.loadContracts();
  },
};
</script>

<style>
.signed-icon {
  color: green;
}
.unsigned-icon {
  color: lightgray;
}
</style>
