/* eslint-disable */
import Vue from 'vue';

// https://raw.githubusercontent.com/ibufu/vue2-scrollspy/master/src/index.js
/**
 * Created by lingchenxuan on 16-11-9.
 */
let scrollSections = [];

const bodyScrollEl = {};

function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();

    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function init(el) {
    const sections = el.children;
    if (sections[0] && sections[0].offsetParent !== el) {
        el[scrollSpyContext].eventEl = window;
        el[scrollSpyContext].scrollEl = bodyScrollEl;
    }
}

const scrollSpyContext = '@@scrollSpyContext';

Vue.directive('scroll-spy', {
    bind: function(el, binding, vnode) {
        function onScroll() {
            const { scrollEl, expression } = el[scrollSpyContext];

            let i = 0;

            while (i < el.children.length) {
              if (isElementInViewport(el.children[i])) {
                break;
              }
              i++;
            }

            vnode.context.$data[expression] = i ? i : 0
        }

        el[scrollSpyContext] = {
            onScroll,
            expression: binding.expression,
            eventEl: el,
            scrollEl: el
        };
    },
    inserted: function (el) {
        init(el);

        const { eventEl, onScroll } = el[scrollSpyContext];
        eventEl.addEventListener('scroll', onScroll);
    },
    componentUpdated: function(el) {
        init(el);
    },
    unbind: function(el) {
        const { eventEl, onScroll } = el[scrollSpyContext];
        eventEl.removeEventListener('scroll', onScroll);
    }
});
/* eslint-enable */
