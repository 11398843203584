<template>
  <div>
    <b-modal
      id="professionCreateModal"
      title="Skrá starfsgrein stofnunar"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      :ok-disabled="loading"
      ref="createModal"
    >
      <profession-create-form ref="professionCreateForm" @successful="successful" @loading="setLoading"></profession-create-form>
    </b-modal>
  </div>
</template>

<script>
import ProfessionCreateForm from '@/components/profession/ProfessionCreateForm.vue';

export default {
  name: 'profession-crud-modal',
  components: {
    ProfessionCreateForm,
  },
  data() {
    return {
      id: null,
      loading: false,
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      this.$refs.createModal.show();
    },
    handleCreate() {
      this.$refs.professionCreateForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.createModal.hide();
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>
