<template>
  <div>
    <b-modal
      id="copyModal"
      title="Afrita ferilbók"
      size="xl"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Staðfesta"
      cancel-title="Hætta við"
      @ok.prevent="handleCopy"
      ref="copyModal"
    >
      <copy-form ref="copyForm" :id="id" @successful="successful"></copy-form>
    </b-modal>
  </div>
</template>

<script>
import CopyForm from '@/components/careerbook/create/copyForm.vue';

export default {
  name: 'copy-crud_modal',
  components: {
    CopyForm,
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      this.$refs.copyModal.show();
    },
    handleCopy() {
      this.$refs.copyForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.copyModal.hide();
    },
  },
};
</script>
