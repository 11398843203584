// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import VueMoment from 'vue-moment';
import VueLogger from 'vuejs-logger';
import VueScrollTo from 'vue-scrollto';
import sanitizeHtml from 'sanitize-html';
import { sync } from 'vuex-router-sync';
import flatpickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import VueCompositionAPI from '@vue/composition-api';

import moment from 'moment';

import 'moment/locale/is';

import FbkPage from './components/FbkPage.vue';
import App from './App.vue';
import router from './router';
import is from './locale/validation/is';
import store from './store';

import './http';
import './scrollspy';

Vue.config.productionTip = false;

Validator.localize('is', is);

Vue.use(BootstrapVue);
Vue.component('v-select', vSelect);
Vue.use(VeeValidate, {
  locale: 'is',
});
Vue.use(VueMoment, {
  moment,
});

Vue.use(require('vue-easeljs'));

Vue.use(VueLogger as any, {
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: 'debug',
  // optional : defaults to false if not specified
  stringifyArguments: false,
  // optional : defaults to false if not specified
  showLogLevel: true,
  // optional : defaults to false if not specified
  showMethodName: false,
  // optional : defaults to '|' if not specified
  separator: '|',
  // optional : defaults to false if not specified
  showConsoleColors: true,
});
Vue.use(flatpickr);
Vue.use(VueScrollTo);
Vue.use(VueCompositionAPI); // Er notað af vue2 útgáfunni af @vueform/slider

Vue.prototype.sanitizeHtml = (value: string | undefined) => (!value ? '' : sanitizeHtml(value.toString()));

Vue.filter('sanitize', (value: string | undefined) => {
  if (!value) return '';
  return sanitizeHtml(value.toString());
});

Vue.filter('limitTo', (value: string | undefined, length: number) => {
  if (!value) return '';
  if (value.length <= length) return value;
  return `${value.substring(0, length)}..`;
});

Vue.component('fbk-page', FbkPage);

sync(store, router);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
