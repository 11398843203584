<template>
  <div>
    <h4 v-if="!id">Hér koma allar myndir skráðar fyrir þennan verkflokk</h4>
    <div v-for="factor in factorList" :key="factor.id">
      <div>
        <category-factor-photos
          :careerbookId="id"
          :factorId="factor.id"
          :categoryId="category"
          :factorName="factor.name"
          :isModal="true"
          class="photo"
        ></category-factor-photos>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import book from '@/api/book';
import CategoryFactorPhotos from '@/components/careerbook/modal/CategoryFactorPhotos.vue';

export default {
  name: 'category-photo',
  props: ['professionId', 'category', 'id'],
  components: {
    CategoryFactorPhotos,
  },
  data() {
    return {
      factorList: [],
      loading: false,
    };
  },
  methods: {
    async getFactors() {
      this.loading = true;
      try {
        if (this.id) {
          const response = await book.careerbookFactorList(this.id, this.category);
          this.factorList = response.data;
          this.loading = false;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getFactors();
  },
};
</script>
<style lang="scss">
.photos {
  text-align: center;
  // padding-left: 2px;
  width: 100%;
}
.photo {
  display: inline-block;
  width: 100%;
  text-align: center;
}
</style>
