<template>
  <div>
    <table class="table">
      <td>
        <strong>{{ item.name }}</strong>
      </td>
      <!-- <td v-if="loggedInUser.is_admin === 1" style="text-align: right">
        <b-link :to="{ name: 'ProfessionVersionUpload', params: { profession: item.id, version: version } }" v-b-tooltip.hover
          >Setja inn nýja útgáfu</b-link
        >
      </td> -->
    </table>
    <i v-if="loading" class="fa fa-spinner fa-spin"></i>
    <div v-else>
      <div v-if="versionList.length === 0"><i>Engar útgáfur fundust</i></div>
      <table v-else class="table">
        <tr v-for="version in versionList" :key="version.id">
          <td width="1">
            <b-link
              :to="{ name: 'CareerbookVersion', params: { profession: item.id, version: version.id } }"
              class="btn btn-secondary pt-0 pb-0 pl-1 pr-1 mt-1"
              v-b-tooltip.hover
              title="Skoða útgáfu"
              ><i class="fa fa-search"></i
            ></b-link>
          </td>
          <td style="vertical-align: bottom"><strong>Útgáfa: </strong>{{ version.version }}</td>
          <td style="vertical-align: bottom"><strong>Stofnað: </strong>{{ version.date_created | moment('DD.MM.YYYY') }}</td>
          <!-- <td
            v-if="loggedInUser.is_admin === 1"
            class="fa fa-file-excel-o"
            style="text-align: right; cursor:pointer; margin-top: 5px; font-size: 18px; color: green; float: right"
            @click="getExcel(item.id, version.id)"
            title="Sækja útgáfu í excel"
          ></td> -->
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';

export default {
  name: 'profession-item',
  props: ['item'],
  data() {
    return {
      loading: false,
      versionList: [],
      version: 0,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async loadList() {
      this.loading = true;
      this.versionList = [];
      try {
        const response = await core.professionVersionList(this.item.id);
        this.versionList = response.data;
        this.version = this.versionList.length;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp.
        Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getExcel(profession, version) {
      try {
        await core.VersionToExcel(profession, version);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
