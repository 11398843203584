<template>
  <ul>
    <li v-once v-for="contact in contacts" :key="contact.name">
      <i
        v-if="contact.signedAt"
        class="fa fa-check-circle-o signed-icon"
        v-b-tooltip.hover
        :title="`Skrifaði undir ${contact.signedAt}`"
      ></i>
      <i v-else class="fa fa-circle-o unsigned-icon" v-b-tooltip.hover title="Hefur ekki skrifað undir"></i>
      {{ contact.name }}
      <span v-if="contact.isGuardian"> (forsjáraðili)</span>
    </li>
    {{
      guardianMissing
    }}
  </ul>
</template>

<script>
import moment from 'moment';

export default {
  name: 'contact-list-popover',
  props: ['contract', 'showGuardian', 'guardianSignerName'],
  computed: {
    contacts() {
      const list = this.contract.contacts.map((c) => ({
        name: c.name,
        signedAt: c.signed_at ? moment(c.signed_at).format('DD.MM.YYYY') : null,
        isGuardian: false,
      }));
      if (this.showGuardian && (this.contract.relative_contact?.name || this.guardianSignerName)) {
        list.push({
          name: this.contract.relative_contact?.name ?? this.guardianSignerName,
          signedAt: this.contract.relative_contact?.signedAt ? moment(this.contract.relative_contact?.signedAt).format('DD.MM.YYYY') : null,
          isGuardian: true,
        });
      }
      return list;
    },
    guardianMissing() {
      if (this.showGuardian && (this.contract.relative_contact?.name || this.guardianSignerName) === undefined) {
        return '(forsjáraðila vantar)';
      }
      return undefined;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0px;
}
.signed-icon {
  color: green;
}
.unsigned-icon {
  color: lightgray;
}
.fa {
  margin-right: 3px;
}
</style>
