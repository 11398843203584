var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fbk-page',[_c('b-card',[_c('b-row',[_c('b-col',[_c('h5',{staticClass:"my-4"},[_vm._v("Yfirlit yfir ferilbækur umsækjanda")])])],1),_c('b-row',[_c('b-col',[_c('i',[_vm._v("Hér að neðan er listi af þeim ferilbókum nemanda sem eiga umsóknir í stöðunni \"Í vinnslu\" eða \"Samþykkt, í bið\" í viðeigandi skóla. "),_c('br'),_vm._v(" Ferilbækur nemanda koma frá Símenntunarmiðstöðvum. ")])])],1),_c('br'),_c('br'),_c('table',{staticClass:"table"},[_c('tr',[_c('th',{staticStyle:{"padding":"10px 0px 10px 0px"}},[_c('b-form-input',{attrs:{"id":"name","name":"name","type":"text","placeholder":"Leita eftir nafni eða kennitölu nemanda"},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)])]),_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Nafn")]),_c('th',[_vm._v("Starfsgrein")]),_c('th',[_vm._v("Gildir frá")]),_c('th',[_vm._v("Gildir til")]),_c('th',[_vm._v("Vinnustaður")]),_c('th')])]),_c('tbody',[(_vm.careerbookApplicationList.length == 0)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('i',[_vm._v("Engar leitarniðurstöður fundust.")])])]):_vm._e(),_vm._l((_vm.careerbookApplicationList),function(careerbookApplication,index){return _c('tr',{key:index,staticClass:"table-row-linkable",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
              name: 'CareerbookById',
              params: {
                id: _vm.careerbookApplicationList.id,
                isApplicationCareerbook: true,
              },
            })}}},[_c('td',{staticClass:"with-link",staticStyle:{"font-size":"12px"}},[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
                name: 'CareerbookById',
                params: { profession: careerbookApplication.profession.id, id: careerbookApplication.id, isApplicationCareerbook: true },
              },"title":"Smelltu til að skoða ferilbók"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(careerbookApplication.name)+" "),_c('br'),_c('div',{staticClass:"grayText"},[_vm._v(_vm._s(_vm._f("subStr")(careerbookApplication.ssn)))])])],1),_c('td',{staticClass:"with-link",staticStyle:{"font-size":"12px"}},[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
                name: 'CareerbookById',
                params: { profession: careerbookApplication.profession, id: careerbookApplication.id, isApplicationCareerbook: true },
              },"title":"Smelltu til að skoða ferilbók"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(careerbookApplication.profession)+" "),_c('br'),_c('div',{staticClass:"grayText"},[_vm._v("Útgáfa "+_vm._s(careerbookApplication.profession_version))])])],1),_c('td',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(careerbookApplication.date_from,'DD.MM.YYYY'))+" ")]),_c('td',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(careerbookApplication.date_to,'DD.MM.YYYY'))+" ")]),_c('td',{staticStyle:{"font-size":"12px"}},_vm._l((_vm.workplaces(careerbookApplication.workplaces)),function(workplace,index){return _c('span',{key:index},[(index != 0)?_c('span',[_c('br')]):_vm._e(),_vm._v(" "+_vm._s(workplace.workplace_name)+" ")])}),0),_c('td',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Afrita ferilbók"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyModal(careerbookApplication.id)}}},[_c('i',{staticClass:"fa fa-fw fa-copy"})])])])})],2)]),(_vm.pagination)?_c('pagination',{attrs:{"pagination-response":_vm.pagination},on:{"change-limit":_vm.changeLimit,"change-page":_vm.changePage}}):_vm._e(),_c('copy-crud-modal',{ref:"copyModal"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }