<template>
  <fbk-page>
    <b-card class="careerbook-tabs">
      <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1">
        <b-row>
          <b-col>
            <h5 class="my-4">Samningar</h5>
          </b-col>
        </b-row>
        <b-row cols="2" class="d-none d-sm-none d-md-none d-lg-block">
          <b-col>
            <span ref="tabs"></span>
            <div class="pb-2">
              <b-nav class="mobile-navbar">
                <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Yfirlit yfir samninga</b-nav-item>
                <b-nav-item @click.prevent="tabIndex = 1" :active="tabIndex === 1">Samningstexti</b-nav-item>
                <!-- <b-nav-item @click.prevent="tabIndex = 2" :active="tabIndex === 2">Viðaukar</b-nav-item> -->
              </b-nav>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-col sm="12" md="12" lg="12" style="padding: 0px">
        <contracts v-show="tabIndex === 0"> </contracts>
        <school-contract v-show="tabIndex === 1"> </school-contract>
      </b-col>
    </b-card>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SchoolContract from '@/components/contract/SchoolContract.vue';
import Contracts from '@/components/contract/contracts.vue';

export default {
  name: 'contract-list',
  components: {
    SchoolContract,
    Contracts,
  },
  data() {
    return {
      tabsFixed: false,
      tabIndex: 0,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },

  created() {},
};
</script>
<style lang="scss">
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
