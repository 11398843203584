<template>
  <b-form @submit.prevent="validateBeforeSubmit" novalidate v-on:keyup.esc="editField(false)">
    <div v-if="!edit">
      <a @click.prevent="editField(true)" style="cursor: pointer" v-b-tooltip.hover title="Breyta reglugerð"
        ><i class="fa fa-fw fa-pencil text-secondary"></i
      ></a>
      <a :href="item.url" target="_blank">{{ item.name }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
    </div>
    <div v-else>
      <b-form @submit.prevent="saveRegulation" novalidate>
        <b-row>
          <b-col>
            <b-form-group label-for="name" :state="submitting && errors.has('name') ? false : null" :feedback="errors.first('name')">
              <b-input-group>
                <b-form-input
                  id="name"
                  name="name"
                  type="text"
                  v-model="name"
                  required
                  placeholder="Sláðu inn heiti reglugerðar"
                  :state="submitting && errors.has('name') ? false : null"
                  v-validate="'required'"
                  data-vv-as="heiti"
                >
                </b-form-input>
                <b-input-group-button slot="left">
                  <button type="button" class="btn btn-sm btn-secondary" @click="editField(false)">
                    <i class="fa fa-remove" aria-hidden="true"></i>
                  </button>
                </b-input-group-button>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="url" :state="submitting && errors.has('url') ? false : null" :feedback="errors.first('url')">
              <b-input-group>
                <b-form-input
                  id="url"
                  name="url"
                  type="text"
                  v-model="url"
                  required
                  placeholder="Sláðu inn hlekk á reglugerðina"
                  :state="submitting && errors.has('url') ? false : null"
                  v-validate="'required'"
                  data-vv-as="hlekkur"
                  v-on:keyup.enter="saveRegulation()"
                >
                </b-form-input>
                <b-input-group-append>
                  <button type="button" class="btn btn-sm btn-primary" @click="saveRegulation()">
                    <i class="fa fa-save" aria-hidden="true"></i>
                  </button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';
import vClickOutside from 'v-click-outside';
import core from '@/api/core';
import { djangoErrorsToVee } from '@/utils';

// import InlineEdit from '@/components/InlineEdit.vue';

export default {
  name: 'regulation-list-item',
  components: {
    // InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: ['item'],
  data() {
    return {
      edit: false,
      submitting: false,
      loading: false,
      name: null,
      url: null,
      validUrl: true,
    };
  },
  methods: {
    validateUrl() {
      const regex = /^(http|https)/;
      if (this.url.length > 3 && !this.url.match(regex)) {
        this.url = `http://${this.url}`;
      }
    },
    async saveRegulation() {
      try {
        this.submitting = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) {
            this.submitting = false;
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        this.validateUrl();

        // Engar villur í formi.
        try {
          await core.regulationUpdate(this.item.id, {
            url: this.url,
            name: this.name,
          });

          this.updated = true;
          this.item.name = this.name;
          this.item.url = this.url;
          this.displaySuccess(`Reglugerðin „${this.name}“ uppfærð.`);
          this.$emit('successful', { id: this.item.id });
          this.name = null;
          this.url = null;
          this.edit = false;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.submitting = true;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    editField(doEdit) {
      if (doEdit === true) {
        this.edit = true;
        this.name = this.item.name;
        this.url = this.item.url;
      } else {
        this.edit = false;
        this.name = null;
        this.url = null;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>
<style lang="scss">
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
