<template>
  <fbk-page>
    <div v-if="!book.profession_version">
      <i class="fa fa-spin fa-spinner text-center"></i>
    </div>
    <div v-else>
      <b-alert variant="danger" :show="failed && errorList.length > 0">
        <ul class="list-unstyled mb-0">
          <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
        </ul>
      </b-alert>
      <b-form @submit.prevent="validateBeforeSubmit" novalidate>
        <fieldset :disabled="!canEdit">
          <b-card header="Almennar upplýsingar" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
            <b-row>
              <b-col>
                <b-form-group label="Starfsgrein">
                  <div class="edit-form-text">{{ book.profession_version.profession.name }}</div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Útgáfa">
                  <div v-if="!isUpgradable" class="edit-form-text">
                    Útgáfa {{ book.profession_version.version }} - {{ book.profession_version.date_created | moment('DD.MM.YYYY') }}
                  </div>
                  <b-form-select v-else v-model="selectedVersionId">
                    <option
                      v-for="version in professionVersionList"
                      :key="version.id"
                      :value="version.id"
                      :disabled="version.id !== book.profession_version.id && !version.latest"
                    >
                      Útgáfa {{ version.version }} - {{ version.date_created | moment('DD.MM.YYYY') }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :state="submitted && (errors.has('date_from') || invalid.date == true) ? false : null"
                  :feedback="errors.first('date_from')"
                >
                  <label label-for="date_from">
                    Dags. frá*
                    <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Hvenær ferilbók birtist nemanda."></i>
                  </label>
                  <flat-pickr
                    id="date_from"
                    name="date_from"
                    class="form-control"
                    type="text"
                    v-model="form.dateFrom"
                    placeholder="Veldu dags. frá"
                    :class="{ 'is-invalid': submitted && (errors.has('date_from') || invalid.date == true) }"
                    :config="flatpickrConfig"
                    data-vv-as="dags frá"
                    v-validate="'required'"
                    ref="dateFromPickr"
                  ></flat-pickr>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :state="submitted && (errors.has('date_to') || invalid.date == true) ? false : null"
                  :feedback="errors.first('date_to')"
                >
                  <label label-for="date_to">
                    Dags. til
                    <i
                      class="fa fa-fw fa-info-circle"
                      v-b-tooltip.hover
                      title="Hvenær nemandi má ekki lengur breyta ferilbók birtist nemanda.
                      Ef óútfyllt þá má hann breyta þangað til dagsetning er sett inn."
                    ></i>
                  </label>
                  <div class="input-group">
                    <flat-pickr
                      id="date_to"
                      name="date_to"
                      class="form-control"
                      type="text"
                      v-model="form.dateTo"
                      placeholder="Veldu dags. til"
                      :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
                      :config="flatpickrConfig"
                      data-vv-as="dags til"
                      ref="dateToPickr"
                    ></flat-pickr>
                    <div class="input-group-btn">
                      <button class="btn btn-default" type="button" title="Hreinsa" data-clear>
                        <i class="fa fa-times">
                          <span aria-hidden="true" class="sr-only">Clear</span>
                        </i>
                      </button>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="invalid.date">
              <b-col>
                <div class="text-danger">Dags frá verður að vera á undan dags til</div>
              </b-col>
            </b-row>
          </b-card>
          <br />
          <b-card header="Nemandi" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
            <b-row>
              <b-col>
                <b-form-group label="Kennitala">
                  <div class="edit-form-text">{{ book.student.id | subStr }}</div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Nafn">
                  <div class="edit-form-text">{{ book.student.name }}</div>
                </b-form-group>
              </b-col>
              <b-col> </b-col>
            </b-row>
            <b-row v-if="schoolList && schoolList.length > 0">
              <b-col cols="4">
                <b-form-group :state="submitted && errors.has('school') ? false : null" :feedback="errors.first('school')">
                  <label label-for="school">
                    Skóli nemanda
                    <i
                      class="fa fa-fw fa-info-circle"
                      v-b-tooltip.hover
                      title="Með því að velja skóla sem nemandi tilheyrir þá fær sá skóli
                      sjálfkrafa skoðunaraðgang að ferilbókinni hans."
                    ></i>
                  </label>
                  <b-form-select
                    id="school"
                    name="school"
                    v-model="form.school"
                    :state="submitted && errors.has('school') ? false : null"
                    data-vv-as="skóli"
                  >
                    <option :value="undefined">-- Veldu skóla --</option>
                    <option v-for="(school, index) in schoolList" :key="index" :value="school">{{ school.name }}</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Netfang" label-for="email" :feedback="errors.first('email')">
                  <b-form-input
                    id="email"
                    name="email"
                    type="email"
                    v-model="form.student.email"
                    required
                    placeholder="Breyta netfangi nemanda"
                    data-vv-as="netfang nemanda"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Farsími" label-for="mobile" :feedback="errors.first('mobile')">
                  <b-form-input
                    id="mobile"
                    name="mobile"
                    type="email"
                    v-model="form.student.mobile"
                    required
                    placeholder="Breyta farsímanúmeri nemanda"
                    data-vv-as="farsími nemanda"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <br />
          <b-card
            header="Vinnustaður/Iðnmeistari/Ábyrgðaraðili"
            header-bg-variant="secondary"
            header-text-variant="white"
            header-class="h5"
            class="mt-3"
            v-if="book.is_mms === 1"
          >
            <div v-if="book.workplaces.length == 0"><i>Engir vinnustaður skráður</i></div>
            <table class="table mb-2">
              <tr>
                <th>Virkur</th>
                <th>Heiti</th>
                <th>Kennitala</th>
                <th>Tími frá</th>
                <th>Tími til</th>
                <th style="width: 10px"></th>
              </tr>
              <tr
                v-for="(workplace, index) in book.workplaces"
                :key="workplace.id"
                :class="{ lightGray: index % 2 === 0, white: index % 2 !== 0 }"
              >
                <td>
                  <b-form-checkbox v-model="workplace.is_deleted" :value="false" :unchecked-value="true"></b-form-checkbox>
                </td>
                <td>{{ workplace.name }}</td>
                <td>{{ workplace.ssn | subStr }}</td>
                <td>{{ workplace.date_from | moment('DD.MM.YYYY') }}</td>
                <td>{{ workplace.date_to | moment('DD.MM.YYYY') }}</td>
                <td style="width: 10px">
                  <b-link href @click="workplaceEditModal(workplace)" :disabled="!canEdit">
                    <i class="fa fa-pencil"></i>
                  </b-link>
                </td>
              </tr>
            </table>

            <br />
            <div class="h7">Bæta við vinnustað</div>
            <b-row>
              <b-col cols="6">
                <b-form-group :state="submitted && errors.has('workplace') ? false : null" :feedback="errors.first('workplace')">
                  <b-form-select
                    id="workplace"
                    name="workplace"
                    v-model="form.workplace"
                    :state="submitted && errors.has('workplace') ? false : null"
                    data-vv-as="workplace"
                  >
                    <option :value="null">-- Veldu vinnustað til að bæta við --</option>
                    <option v-for="workplace in workplaceList" :key="workplace.id" :value="workplace">{{ workplace.name }}</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :state="submitted && (errors.has('workplace_date_from') || invalid.date == true) ? false : null"
                  :feedback="errors.first('workplace_date_from')"
                >
                  <flat-pickr
                    id="workplace_date_from"
                    name="workplace_date_from"
                    class="form-control"
                    type="text"
                    v-model="form.workplaceDateFrom"
                    placeholder="Veldu dags. frá"
                    :class="{ 'is-invalid': submitted && (errors.has('workplace_date_from') || invalid.date == true) }"
                    :config="flatpickrConfig"
                    data-vv-as="dags. frá"
                    ref="workplaceDateFromPickr"
                  ></flat-pickr>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :state="submitted && (errors.has('workplace_date_to') || invalid.date == true) ? false : null"
                  :feedback="errors.first('workplace_date_to')"
                >
                  <div class="input-group">
                    <flat-pickr
                      id="workplace_date_to"
                      name="workplace_date_to"
                      class="form-control"
                      type="text"
                      v-model="form.workplaceDateTo"
                      placeholder="Veldu dags. til"
                      :class="{ 'is-invalid': submitted && (errors.has('workplace_date_to') || invalid.date == true) }"
                      :config="flatpickrConfig"
                      data-vv-as="dags. til"
                      ref="workplaceDateToPickr"
                    ></flat-pickr>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div v-if="workplaceList.length == 0">
                  <i>ATH! Eingöngu er hægt að skrá samþykkta vinnustaði. Engir vinnustaðir fundust skráðir fyrir valda starfsgrein</i>
                </div>
                <div v-if="workplaceList.length > 0">
                  <i
                    >ATH! Eingöngu er hægt að skrá samþykkta vinnustaði. Ef vinnustað vantar í listann, vinsamlegast hafið samband við
                    umsýsluaðila starfsgreinarinnar
                  </i>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <br />
        </fieldset>
        <b-card
          :header="book.is_education_center === 1 ? 'Matsaðili' : 'Tilsjónaraðili/Leiðbeinandi'"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          class="mt-3"
        >
          <div v-if="book.supervisors.length == 0">
            <i v-if="book.is_education_center === 1">Enginn matsaðili skráður</i>
            <i v-else>Enginn tilsjónaraðili/leiðbeinandi skráður</i>
          </div>

          <table class="table mb-0" v-else>
            <tr>
              <th>Virkur</th>
              <th>Nafn</th>
              <th>Kennitala</th>
              <th>Netfang</th>
              <th>Farsími</th>
              <th v-if="book.is_mms === 1" style="text-align: center">Undirritandi</th>
              <th style="width: 10px"></th>
            </tr>
            <tr
              v-for="(supervisor, index) in book.supervisors"
              :key="supervisor.id"
              style="padding: 0px"
              :class="{ lightGray: index % 2 === 0, white: index % 2 !== 0 }"
            >
              <td>
                <b-form-checkbox v-model="supervisor.is_deleted" :value="false" :unchecked-value="true"></b-form-checkbox>
              </td>
              <td>{{ supervisor.name }}</td>
              <td>{{ supervisor.id | subStr }}</td>
              <td>{{ supervisor.email }}</td>
              <td>{{ supervisor.mobile }}</td>
              <td v-if="book.is_mms === 1" style="text-align: center">
                <b-form-checkbox v-model="supervisor.is_signet_signer" :value="true" :unchecked-value="false"></b-form-checkbox>
              </td>
              <td style="width: 10px">
                <b-link href @click="supervisorEditModal(supervisor)" :disabled="!canEdit"><i class="fa fa-pencil"></i></b-link>
              </td>
            </tr>
          </table>
          <div v-if="invalid.supervisorSigner && book.is_mms === 1" class="text-danger" style="text-align: right">Veldu undirritanda</div>

          <br />
          <div v-if="book.is_education_center === 1" class="h7">Bæta við matsaðila</div>
          <div v-else class="h7">Bæta við tilsjónaraðila</div>
          <div v-for="(supervisor, supervisorIndex) in form.supervisors" :key="supervisorIndex">
            <b-row>
              <b-col>
                <b-form-group label="Kennitala" :label-for="`supervisor-${supervisorIndex}`">
                  <b-form-input
                    :id="`supervisor-${supervisorIndex}`"
                    :name="`supervisor-${supervisorIndex}`"
                    type="text"
                    v-model="supervisor.ssn"
                    required
                    :placeholder="book.is_education_center === 1 ? 'Sláðu inn kennitölu matsaðila' : 'Sláðu inn kennitölu tilsjónaraðila'"
                    :state="
                      submitted && (errors.has(`supervisor-${supervisorIndex}`) || invalid.supervisor || invalid.noSupervisor)
                        ? false
                        : null
                    "
                    v-validate="'length:10'"
                    data-vv-as="kennitala tilsjónaraðila"
                    maxlength="10"
                    @input="validateSupervisor(supervisor)"
                  ></b-form-input>
                </b-form-group>
                <div v-if="supervisor.backendValidation != null" class="text-danger">{{ supervisor.backendValidation }}</div>
                <div v-if="supervisor.is_thjodskra == false" class="text-danger">
                  Eftirfarandi kennitala fannst ekki í þjóðskrá. Vinsamlegast sláið inn aðra kennitölu
                </div>
                <div v-if="invalid.supervisor" class="text-danger">
                  <div v-if="book.is_education_center === 1">Matsaðili má ekki vera skráður líka sem nemandi eða umsýsluaðili</div>
                  <div v-else>Tilsjónaraðili má ekki vera skráður líka sem nemandi eða umsýsluaðili</div>
                </div>
                <div v-if="invalid.noSupervisor" class="text-danger">
                  <div v-if="book.is_educationCenter === 1">Það verður að vera amk einn virkur matsaðili</div>
                  <div v-else>Það verður að vera amk einn virkur tilsjónaraðili</div>
                </div>
              </b-col>
              <b-col>
                <b-form-group
                  :state="submitted && errors.has(`supervisorName-${supervisorIndex}`) ? false : null"
                  :feedback="errors.first(`supervisorName-${supervisorIndex}`)"
                >
                  <label :for="`supervisorName-${supervisorIndex}`">Nafn <span v-if="supervisor.ssn.lenght > 0">*</span></label>
                  <b-input-group>
                    <b-form-input
                      :id="`supervisorName-${supervisorIndex}`"
                      :name="`supervisorName-${supervisorIndex}`"
                      :state="submitted && errors.has(`supervisorName-${supervisorIndex}`) ? false : null"
                      v-validate="{ required: supervisor.ssn.length > 0 }"
                      data-vv-as="nafn tilsjónaraðila"
                      type="text"
                      v-model="supervisor.name"
                      readonly
                    >
                    </b-form-input>
                    <b-input-group-button slot="right">
                      <button type="button" class="btn btn-sm btn-dark" @click="removeSupervisor(supervisorIndex)">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </b-input-group-button>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Netfang"
                  :label-for="`supervisorEmail-${supervisorIndex}`"
                  :feedback="errors.first(`supervisorName-${supervisorIndex}`)"
                >
                  <b-form-input
                    :id="`supervisorEmail-${supervisorIndex}`"
                    :name="`supervisorEmail-${supervisorIndex}`"
                    type="email"
                    v-model="supervisor.email"
                    :placeholder="book.is_education_center === 1 ? 'Sláðu inn netfang matsaðila' : 'Sláðu inn netfang tilsjónaraðila'"
                    data-vv-as="netfang tilsjónaraðila"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Farsími"
                  :label-for="`supervisorMobile-${supervisorIndex}`"
                  :feedback="errors.first(`supervisorMobile-${supervisorIndex}`)"
                >
                  <b-form-input
                    :id="`supervisorMobile-${supervisorIndex}`"
                    :name="`supervisorMobile-${supervisorIndex}`"
                    type="tel"
                    v-model="supervisor.mobile"
                    :placeholder="book.is_education_center === 1 ? 'Sláðu inn farsíma matsaðila' : 'Sláðu inn farsíma tilsjónaraðila'"
                    data-vv-as="farsími tilsjónaraðila"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="book.is_mms === 1">
              <b-col>
                <b-form-group :feedback="errors.first(`supervisorsigner-${supervisorIndex}`)">
                  <b-form-checkbox
                    :id="`supervisorsigner-${supervisorIndex}`"
                    :name="`supervisorsigner-${supervisorIndex}`"
                    v-model="supervisor.is_signet_signer"
                    :value="true"
                    :unchecked-value="false"
                    v-b-popover.hover.left="'Sé hakað við hér fær tilsjónaraðili boð um að undirrita samninga vegna viðkomandi ferilbókar'"
                  >
                    Undirritar samninga
                  </b-form-checkbox></b-form-group
                >
              </b-col>
            </b-row>
          </div>
          <b-btn @click.stop.prevent="addSupervisor" class="pull-right"><i class="fa fa-fw fa-plus-circle"></i> Bæta við</b-btn>
        </b-card>
        <br />
        <fieldset :disabled="!canEdit">
          <b-card
            :header="book.is_education_center ? 'Umsýsluaðili' : 'Umsýsluaðili/Skóli'"
            header-bg-variant="secondary"
            header-text-variant="white"
            header-class="h5"
            class="mt-3"
          >
            <div v-if="book.instructors.length == 0">
              <i v-if="book.is_education_center === 1">Enginn umsýsluaðili skráður</i>
              <i v-else>Enginn umsýsluaðili/skóli skráður</i>
            </div>
            <table class="table mb-0" v-else>
              <tr>
                <th>Virkur</th>
                <th>Nafn</th>
                <th>Kennitala</th>
                <th>Netfang</th>
                <th>Farsími</th>
                <th v-if="book.is_mms === 1" style="text-align: center">Undirritandi</th>
              </tr>
              <tr
                v-for="(instructor, index) in book.instructors"
                :key="instructor.id"
                :class="{ lightGray: index % 2 === 0, white: index % 2 !== 0 }"
              >
                <td>
                  <b-form-checkbox v-model="instructor.is_deleted" :value="false" :unchecked-value="true"></b-form-checkbox>
                </td>
                <td>{{ instructor.name }}</td>
                <td>{{ instructor.id | subStr }}</td>
                <td>{{ instructor.email }}</td>
                <td>{{ instructor.mobile }}</td>
                <td v-if="book.is_mms === 1" style="text-align: center">
                  <b-form-checkbox v-model="instructor.is_signet_signer" :value="true" :unchecked-value="false"></b-form-checkbox>
                </td>
              </tr>
            </table>
            <div v-if="invalid.instructorSigner" class="text-danger" style="text-align: right">Veldu undirritanda</div>

            <br />
            <div v-if="book.is_education_center" class="h7">Bæta við umsýsluaðila</div>
            <div v-else class="h7">Bæta við umsýsluaðila/skóla</div>
            <div v-for="(instructor, instructorIndex) in form.instructors" :key="instructorIndex">
              <b-row>
                <b-col>
                  <b-form-group
                    label="Kennitala"
                    :label-for="`instructor-${instructorIndex}`"
                    :state="submitted && (errors.has(`instructor-${instructorIndex}`) || invalid.instructor) ? false : null"
                    :feedback="errors.first(`instructor-${instructorIndex}`)"
                  >
                    <b-form-input
                      :id="`instructor-${instructorIndex}`"
                      :name="`instructor-${instructorIndex}`"
                      type="text"
                      v-model="instructor.ssn"
                      placeholder="Sláðu inn kennitölu umsýsluaðila"
                      :state="submitted && (errors.has(`instructor-${instructorIndex}`) || invalid.instructor) ? false : null"
                      v-validate="'length:10'"
                      data-vv-as="kennitala umsýsluaðila"
                      maxlength="10"
                      @input="validateInstructor(instructor)"
                    ></b-form-input>
                  </b-form-group>
                  <div v-if="instructor.backendValidation != null" class="text-danger">{{ instructor.backendValidation }}</div>
                  <div v-if="instructor.is_thjodskra == false" class="text-danger">
                    Eftirfarandi kennitala fannst ekki í þjóðskrá. Vinsamlegast sláið inn aðra kennitölu
                  </div>
                  <div v-if="invalid.instructor" class="text-danger">
                    <div v-if="book.is_education_center === 1">Umsýsluaðili má ekki vera skráður líka sem nemandi eða matsaðili</div>
                    <div v-else>Umsýsluaðili má ekki vera skráður líka sem nemandi eða tilsjónaraðili</div>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group
                    :state="submitted && errors.has(`instructorName-${instructorIndex}`) ? false : null"
                    :feedback="errors.first(`instructorName-${instructorIndex}`)"
                  >
                    <label :for="`instructorName-${instructorIndex}`">Nafn <span v-if="instructor.ssn.length > 0">*</span></label>
                    <b-input-group>
                      <b-form-input
                        :id="`instructorName-${instructorIndex}`"
                        :name="`instructorName-${instructorIndex}`"
                        :state="submitted && errors.has(`instructorName-${instructorIndex}`) ? false : null"
                        v-validate="{ required: instructor.ssn.length > 0 }"
                        data-vv-as="nafn umsýsluaðila"
                        type="text"
                        v-model="instructor.name"
                        readonly
                      ></b-form-input>
                      <b-input-group-button slot="right">
                        <button type="button" class="btn btn-sm btn-dark" @click="removeInstructor(instructorIndex)">
                          <i class="fa fa-fw fa-trash"></i>
                        </button>
                      </b-input-group-button>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Netfang"
                    :label-for="`instructorEmail-${instructorIndex}`"
                    :feedback="errors.first(`instructorEmail-${instructorIndex}`)"
                  >
                    <b-form-input
                      :id="`instructorEmail-${instructorIndex}`"
                      :name="`instructorEmail-${instructorIndex}`"
                      type="email"
                      v-model="instructor.email"
                      placeholder="Sláðu inn netfang umsýsluaðila"
                      data-vv-as="netfang umsýsluaðila"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Farsími"
                    :label-for="`instructorMobile-${instructorIndex}`"
                    :feedback="errors.first(`instructorMobile-${instructorIndex}`)"
                  >
                    <b-form-input
                      :id="`instructorMobile-${instructorIndex}`"
                      :name="`instructorMobile-${instructorIndex}`"
                      type="tel"
                      v-model="instructor.mobile"
                      placeholder="Sláðu inn farsíma umsýsluaðila"
                      data-vv-as="farsími umsýsluaðila"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="book.is_mms === 1">
                <b-col>
                  <b-form-group :feedback="errors.first(`instructorsigner-${instructorIndex}`)">
                    <b-form-checkbox
                      :id="`instructorsigner-${instructorIndex}`"
                      :name="`instructorsigner-${instructorIndex}`"
                      v-model="instructor.is_signet_signer"
                      :value="true"
                      :unchecked-value="false"
                      v-b-popover.hover.left="'Sé hakað við hér fær umsýsluaðili boð um að undirrita samninga vegna viðkomandi ferilbókar'"
                    >
                      Undirritar samninga
                    </b-form-checkbox></b-form-group
                  >
                </b-col>
              </b-row>
            </div>
            <b-btn @click.stop.prevent="addInstructor" class="pull-right"><i class="fa fa-fw fa-plus-circle"></i> Bæta við</b-btn>
          </b-card>
        </fieldset>
        <br />
        <b-btn type="submit" variant="primary" :disabled="creating">
          <i class="fa fa-fw fa-spinner fa-spin" v-if="creating"></i>
          <i class="fa fa-fw fa-floppy-o" v-if="!creating"></i>
          Breyta
        </b-btn>
        <b-btn variant="danger" class="pull-right" v-if="canDelete" :disabled="deleting" @click.prevent="deleteBook">
          <i class="fa fa-fw fa-spinner fa-spin" v-if="deleting"></i>
          <i class="fa fa-fw fa-trash" v-if="!deleting"></i>
          Eyða ferilbók
        </b-btn>
        <br /><br />
      </b-form>
    </div>
    <edit-workplace-modal ref="workplaceEditModal" @successful="workplaceUpdated"></edit-workplace-modal>
    <edit-supervisor-modal ref="supervisorEditModal" @successful="supervisorUpdated"></edit-supervisor-modal>
    <upgrade-profession-modal
      ref="upgradeProfesionModal"
      @ok="(book) => $refs.redirectModal.show(book.bookId)"
      @hidden="() => (selectedVersionId = book.profession_version.id)"
    ></upgrade-profession-modal>
    <redirect-modal ref="redirectModal"></redirect-modal>
  </fbk-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
import book from '@/api/book';

import EditWorkplaceModal from '@/components/careerbook/create/EditWorkplaceModal.vue';
import EditSupervisorModal from '@/components/careerbook/create/EditSupervisorModal.vue';
import UpgradeProfessionModal from '@/components/careerbook/create/UpgradeProfessionModal.vue';
import RedirectModal from '@/components/careerbook/create/RedirectModal.vue';

import { flatpickrConfig, djangoErrorsToVee } from '@/utils';

export default {
  name: 'edit-form',
  components: {
    EditWorkplaceModal,
    EditSupervisorModal,
    UpgradeProfessionModal,
    RedirectModal,
  },
  computed: {
    /**
     * Er hægt að uppfæra starfsstétt ferilbókar?
     * * Starfsstétt þarf að hafa nýrri útgáfu en þá sem er skráð í ferilbók.
     * * Það má ekki vera búið að afrita þessa ferilbók.
     */
    isUpgradable() {
      const latest = this.professionVersionList.find((v) => v.latest);
      return latest && this.book.profession_version && this.book.profession_version.id !== latest.id && !this.book.copied_to;
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      id: null,
      professionId: null,
      selectedVersionId: null,
      book: {},
      workplaceList: [],
      schoolList: [],
      professionVersionList: [],
      canEdit: false,
      canDelete: false,
      form: {
        dateFrom: null,
        dateTo: null,
        workplaceDateFrom: null,
        workplaceDateTo: null,
        workplace: null,
        school: null,
        student: {
          email: '',
          mobile: '',
        },
        supervisors: [
          {
            name: '',
            ssn: '',
            mobile: '',
            loading: false,
            is_thjodskra: null,
            backendValidation: null,
            is_signet_signer: false,
          },
        ],
        instructors: [
          {
            name: '',
            ssn: '',
            mobile: '',
            loading: false,
            is_thjodskra: null,
            backendValidation: null,
            is_signet_signer: false,
          },
        ],
      },
      loading: false,
      submitted: false,
      failed: false,
      errorList: [],
      creating: false,
      deleting: false,
      invalid: {
        date: false,
        supervisor: false,
        supervisorSigner: false,
        instructor: false,
        instructorSigner: false,
        noSupervisor: false,
        workdate: false,
      },
      formHasChanged: false,
      flatpickrConfig: flatpickrConfig(),
    };
  },
  filters: {
    subStr(string) {
      const newString = `${string.substring(0, 6)}-${string.substring(6, 10)}`;
      return newString;
    },
  },
  watch: {
    selectedVersionId(value) {
      if (value !== this.book.profession_version.id) {
        this.$refs.upgradeProfesionModal.show(this.id);
      }
    },
  },
  methods: {
    /* Fletta upp upplýsingum um ferilbókina */
    async loadBook() {
      this.loading = true;
      this.book = {};
      try {
        const response = await book.careerbookDetail(this.id);
        this.book = response.data;
        this.selectedVersionId = this.book.profession_version.id;
        this.loadWorkplaces();
        this.loadSchools();
        this.$nextTick(() => {
          this.form.dateFrom = this.book.date_from !== null ? this.book.date_from : null;
          this.form.dateTo = this.book.date_to !== null ? this.book.date_to : null;
        });
        this.form.student.email = this.book.student.email;
        this.form.student.mobile = this.book.student.mobile;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadWorkplaces() {
      this.workplaceList = [];
      this.form.workplace = null;
      try {
        const response = await book.searchWorkplace(this.professionId);
        this.workplaceList = response.data.filter((v1) => this.book.workplaces.filter((v2) => v2.id === v1.id).length === 0);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadSchools() {
      this.form.school = null;
      try {
        const response = await core.institutionList(null, 1);
        this.schoolList = response.data;
        this.form.school = this.book.school !== null ? this.schoolList.find((s) => s.id === this.book.school.id) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadProfessionVersions() {
      try {
        const response = await core.professionVersionList(this.professionId);
        this.professionVersionList = response.data;

        // Finnum nýjustu útgáfuna og merkjum hana sem nýjasta.
        const newest = this.professionVersionList.reduce((newestVersion, version) => {
          return newestVersion?.version > version.version ? newestVersion : version;
        }, null);
        newest.latest = true;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja útgáfur starfsgreinar.`);
      }
    },
    async canDeleteBook() {
      this.deleting = false;
      try {
        const response = await book.careerbookCategoryFactorCompletedCount(this.id);
        if (response.data.total_completed === 0) {
          this.deleting = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
      return this.deleting;
    },
    async deleteBook() {
      const conf = confirm('Ertu viss um að þú viljir eyða ferilbókinni?'); //eslint-disable-line

      try {
        if (conf) {
          if (this.canDeleteBook()) {
            await book.careerbookDelete(this.id);

            this.$router.push({ name: 'CareerbookList' });
            this.displaySuccess(`Ferilbók fyrir ${this.book.student.name} í „
              ${this.book.profession_version.profession.name}
              “ hefur verið eytt.`);
          } else {
            this.displayError('Ekki er hægt að eyða ferilbók ef skráning á verkflokka/verkþætti er til staðar');
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða ferilbók.
         Vinsamlegast reyndu aftur.`);
      } finally {
        this.deleting = false;
      }
    },
    workplaceEditModal(workplace) {
      this.$refs.workplaceEditModal.show(workplace);
    },

    async workplaceUpdated() {
      try {
        const response = await book.bookWorkplaceList(this.book.id);
        this.book.workplaces = response.data.results;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },

    supervisorEditModal(supervisor) {
      this.$refs.supervisorEditModal.show(supervisor);
    },

    async supervisorUpdated() {
      try {
        const response = await book.bookSupervisorList(this.book.id);
        this.book.supervisors = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },

    // Bæta við umsýsluaðila
    addInstructor() {
      this.form.instructors.push({
        ssn: '',
        name: '',
        email: '',
        mobile: '',
        loading: false,
        is_thjodskra: null,
        backendValidation: null,
        is_signet_signer: false,
      });
    },
    // Fjarlægja umsýsluaðila
    removeInstructor(index) {
      this.form.instructors.splice(index, 1);
    },
    // Bæta við umsýsluaðila
    addSupervisor() {
      this.form.supervisors.push({
        ssn: '',
        name: '',
        email: '',
        mobile: '',
        loading: false,
        is_thjodskra: null,
        backendValidation: null,
        is_signet_signer: false,
      });
    },
    // Fjarlægja umsýsluaðila
    removeSupervisor(index) {
      this.form.supervisors.splice(index, 1);
    },
    // Sækir tilsjónaraðila út frá kennitölu
    async validateSupervisor(supervisor) {
      supervisor.name = ''; // eslint-disable-line no-param-reassign
      supervisor.is_thjodskra = null; // eslint-disable-line no-param-reassign
      if (supervisor.ssn && supervisor.ssn.length === 10) {
        supervisor.loading = true; // eslint-disable-line no-param-reassign
        try {
          const response = await book.searchSupervisor(supervisor.ssn);
          supervisor.is_thjodskra = response.data.is_thjodskra; // eslint-disable-line no-param-reassign, max-len
          if (response.data.is_thjodskra) {
            supervisor.name = response.data.name; // eslint-disable-line no-param-reassign
            supervisor.email = response.data.email; // eslint-disable-line no-param-reassign
            supervisor.mobile = response.data.mobile; // eslint-disable-line no-param-reassign
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          supervisor.loading = false; // eslint-disable-line no-param-reassign
        }
      }
    },
    // Sækir umsýsluaðila útfrá kennitölu
    async validateInstructor(instructor) {
      instructor.name = ''; // eslint-disable-line no-param-reassign
      instructor.email = ''; // eslint-disable-line no-param-reassign
      instructor.mobile = ''; // eslint-disable-line no-param-reassign
      instructor.is_thjodskra = null; // eslint-disable-line no-param-reassign
      if (instructor.ssn && instructor.ssn.length === 10) {
        instructor.loading = true; // eslint-disable-line no-param-reassign
        try {
          const response = await book.searchInstructor(instructor.ssn);
          instructor.is_thjodskra = response.data.is_thjodskra; // eslint-disable-line no-param-reassign, max-len
          if (response.data.is_thjodskra) {
            instructor.name = response.data.name; // eslint-disable-line no-param-reassign
            instructor.email = response.data.email; // eslint-disable-line no-param-reassign
            instructor.mobile = response.data.mobile; // eslint-disable-line no-param-reassign
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          instructor.loading = false; // eslint-disable-line no-param-reassign
        }
      }
    },
    // Athugar hvort dags frá sé ekki örugglega á undan dags til
    validateDates() {
      let valid = true;
      this.invalid.date = false;
      if (this.form.dateFrom && this.form.dateTo) {
        const from = moment(this.form.dateFrom, 'YYYY-MM-DD');
        const to = moment(this.form.dateTo, 'YYYY-MM-DD');
        if (to.isBefore(from)) {
          valid = false;
          this.invalid.date = true;
        }
      }
      return valid;
    },
    // Skoðar hvort allir aðilar séu nokkuð skilgreindir á mörgum stöðum
    validatePersons() {
      this.invalid.supervisor = false;
      this.invalid.instructor = false;
      let valid = true;
      // Ath hvort nemandi sé líka tilsjónaraðili
      if (this.form.supervisors.find((i) => i.ssn === this.book.student.id) !== undefined) {
        valid = false;
        this.invalid.supervisor = true;
      }
      // Athuga hvort nemandi sé líka umsýsluaðili
      if (this.form.instructors.find((i) => i.ssn === this.book.student.id) !== undefined) {
        valid = false;
        this.invalid.instructor = true;
      }
      // Athuga hvort tilsjónaraðili (supervisor) sé nokkuð umsýsluaðili (instructor) (nýskráningar)
      this.form.supervisors.forEach((i) => {
        this.form.instructors.forEach((i2) => {
          if (i2.ssn === i.ssn && i.ssn !== '') {
            valid = false;
            this.invalid.supervisor = true;
            this.invalid.instructor = true;
          }
        });
      });

      // Athuga hvort nýskráður tilsjónaraðili sé nokkuð skráður umsýsluaðili
      this.form.supervisors.forEach((i) => {
        this.book.instructors.forEach((i2) => {
          if (i2.id === i.ssn && i2.is_deleted === false) {
            valid = false;
            this.invalid.supervisor = true;
          }
        });
      });
      // Athuga hvort nýskráður umsýsluaðili sé nokkuð skráður tilsjónaraðili
      this.form.instructors.forEach((i) => {
        this.book.supervisors.forEach((i2) => {
          if (i2.id === i.ssn && i2.is_deleted === false) {
            valid = false;
            this.invalid.instructor = true;
          }
        });
      });
      // Athuga hvort búið sé að skrá viðkomandi sem umsýsluaðila áður
      this.form.instructors.forEach((i) => {
        this.book.instructors.forEach((i2) => {
          if (i2.id === i.ssn) {
            valid = false;
            this.displayError('Þessi kennitala er þegar skráð á umsýsluaðila');
          }
        });
      });
      // Athuga hvort búið sé að skrá viðkomandi sem tilsjónaraðila áður
      this.form.supervisors.forEach((i) => {
        this.book.supervisors.forEach((i2) => {
          if (i2.id === i.ssn) {
            valid = false;
            this.displayError('Þessi kennitala er þegar skráð á tilsjónaraðila');
          }
        });
      });
      return valid;
    },
    // Athugar hvort það sé skráður tilsjónaraðili og umsjónaraðili
    signetSigners() {
      let valid = false;
      let instructor = false;
      let supervisor = false;
      this.invalid.instructorSigner = true;
      this.invalid.supervisorSigner = true;
      if (
        !!this.book.instructors.find((a) => a.is_signet_signer === true && a.is_deleted !== true) ||
        !!this.form.instructors.find((i) => i.is_signet_signer === true)
      ) {
        instructor = true;
        this.invalid.instructorSigner = false;
      }

      if (this.book.supervisors.length === 0) {
        supervisor = true;
        this.invalid.supervisorSigner = false;
      }

      valid = supervisor && instructor;

      return valid;
    },
    // Breyta ferilbók
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          // Athuga hvort formið sé invalid, dagsetningarnar invalid eða manneskjurnar
          if (!response || this.validateDates() === false || this.validatePersons() === false) {
            this.displayError('Gögn ekki rétt. Vinsamlegast skoðið formið og lagfærið.');
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi.
        try {
          this.creating = true;
          const workplaces = [];
          const supervisors = [];
          const instructors = [];

          // Vinnustaðir - nýir
          if (this.form.workplace != null) {
            workplaces.push({
              id: this.form.workplace.id,
              date_from: this.form.workplaceDateFrom,
              date_to: this.form.workplaceDateTo,
              is_deleted: 0,
            });
          }
          // Vinnustaðir - þeir sem fyrir voru
          this.book.workplaces.forEach((i) => {
            workplaces.push({
              id: i.id,
              date_from: i.date_from,
              date_to: i.date_to,
              is_deleted: i.is_deleted ? 1 : 0,
            });
          });

          // Tilsjónaraðilar - nýir
          this.form.supervisors.forEach((i) => {
            if (i.ssn.length === 10) {
              supervisors.push({
                id: i.ssn,
                email: i.email,
                mobile: i.mobile,
                is_deleted: 0,
                is_signet_signer: i.is_signet_signer,
              });
            }
          });
          // Tilsjónaraðilar - þeir sem fyrir voru
          this.book.supervisors.forEach((i) => {
            supervisors.push({
              id: i.id,
              mobile: i.mobile,
              email: i.email,
              is_deleted: i.is_deleted ? 1 : 0,
              is_signet_signer: i.is_signet_signer,
            });
          });
          // Umsýsluaðilar - nýir
          this.form.instructors.forEach((i) => {
            if (i.ssn.length === 10) {
              instructors.push({
                id: i.ssn,
                email: i.email,
                mobile: i.mobile,
                is_deleted: 0,
                is_signet_signer: i.is_signet_signer,
              });
            }
          });
          // Umsýsluaðilar - þeir sem fyrir voru
          this.book.instructors.forEach((i) => {
            instructors.push({
              id: i.id,
              is_deleted: i.is_deleted ? 1 : 0,
              is_signet_signer: i.is_signet_signer,
            });
          });
          const item = {
            student: this.book.student.id,
            studentEmail: this.form.student.email,
            studentMobile: this.form.student.mobile,
            profession_version: this.book.profession_version.id,
            workplaces: JSON.stringify(workplaces),
            supervisors: JSON.stringify(supervisors),
            instructors: JSON.stringify(instructors),
            school: this.form.school ? this.form.school.id : null,
            date_from: this.form.dateFrom,
            date_to: this.form.dateTo !== '' ? this.form.dateTo : null,
          };
          await book.careerbookUpdate(this.id, item);
          // Opna ferilbókina
          this.$router.push({ name: 'CareerbookById', params: { id: this.id } });

          this.displaySuccess(`Ferilbók fyrir ${this.book.student.name} í „
              ${this.book.profession_version.profession.name}
              “ hefur verið breytt.`);
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            if (e.response.data.instructors) {
              this.form.instructors.forEach((i, index) => {
                if (e.response.data.instructors[index] && e.response.data.instructors[index].length > 0) {
                  i.backendValidation = e.response.data.instructors[index]; // eslint-disable-line no-param-reassign, max-len
                }
              });
            }
            this.displayError('Upp kom villa sem tengist gögnunum sem verið að vista. Vinsamlegast skoðið formið og lagfærið.');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.creating = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async canEditBook() {
      try {
        const factors = await book.careerbookCategoryFactorCompletedCount(this.id);
        const probe = await book.careerbookProbe(this.id, this.isApplicationCareerbook);

        this.canEdit = probe.data.is_can_edit;
        this.canDelete = probe.data.is_can_edit && factors.data.total_completed === 0;
      } catch (e) {
        this.$log.error(e);
        if (e.response && e.response.status === 400) {
          this.displayError('Þú hefur ekki aðgang að þessari ferilbók.');
          this.$router.push({ name: 'Index' });
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að skoða aðgang. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.professionId = this.$route.params.profession;
    this.id = this.$route.params.id;
    this.canEditBook();
    this.loadBook();
    this.loadProfessionVersions();
  },
};
</script>
<style lang="scss">
.edit-form-text {
  font-size: 1rem;
}
.h7 {
  font-size: 1rem;
  border-top: 0.5px solid #a0a0a0;
  padding-top: 20px;
}
.lightGray {
  background-color: #f1f1f1;
}
.white {
  background-color: white;
}
</style>
