<template>
  <b-card no-body>
    <div slot="header" @click="collpaseCategory()" style="cursor: pointer">
      <b-row>
        <b-col cols="11">
          <h5 class="mb-0">
            {{ item.name }}
          </h5>
        </b-col>
        <b-col>
          <div class="pull-right h5 mb-0">
            <i class="fa fa-fw" :class="{ 'fa-chevron-down': collapse, 'fa-chevron-right': !collapse }"></i>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="collapse" v-model="collapse">
      <div class="card-body">
        <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
        <div v-else>
          <div class="alert alert-info" v-if="item.institution != null">
            Þessar starfsgreinar tilheyra stofnuninni <strong>{{ item.institution.name }}</strong
            >. Þær birtast ekki öðrum stofnunum.
          </div>
          <div v-if="professionList.length == 0"><i>Engar niðurstöður fundust.</i></div>
          <profession-item
            class="mb-3"
            v-for="(profession, index) in professionList"
            :key="index"
            :item="profession"
            ref="profession"
          ></profession-item>
        </div>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import ProfessionItem from '@/components/version/ProfessionItem.vue';

export default {
  name: 'profession-category-item',
  props: ['item', 'notCollapsed'],
  components: {
    ProfessionItem,
  },
  data() {
    return {
      collapse: false,
      loading: false,
      professionList: [],
      name: null,
      profession: '',
    };
  },
  methods: {
    async updateProfessions() {
      this.loading = true;
      try {
        const response = await core.professionList({ category: this.item.id });
        this.professionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Collapse-ar starfsgreinaflokk og nær í professions ef ekki er búið að því
     */
    async collpaseCategory() {
      this.collapse = !this.collapse;
      this.loading = false;
      if (this.professionList.length === 0 && this.collapse) {
        this.updateProfessions();
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if (this.notCollapsed) {
      this.collpaseCategory();
    }
  },
};
</script>
