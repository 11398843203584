<template>
  <div>
    <b-modal
      centered
      id="photoModal"
      size="lg"
      lazy
      :no-close-on-backdrop="false"
      :hide-header="true"
      :hide-footer="true"
      ok-title="Loka"
      :ok-only="true"
      ref="boxModal"
    >
      <photo-box-modal ref="photoBox" :image="image" @loading="setLoading" @close="successful"></photo-box-modal>
    </b-modal>
    <b-modal
      id="careerbookcategoryCreateModal"
      title="Skrá verkflokk"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      :ok-disabled="loading"
      ref="createModal"
    >
      <category-create-form
        ref="careerbookCategoryCreateForm"
        @successful="successful"
        @loading="setLoading"
        :professionId="professionId"
      ></category-create-form>
    </b-modal>
    <b-modal
      id="careerbookcategoryOverviewModal"
      title="Yfirlit"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Loka"
      ref="overviewModal"
    >
      <category-overview-modal
        ref="careerbookCategoryOverview"
        @successful="successful"
        :category="category"
        :professionId="professionId"
        :id="id"
      ></category-overview-modal>
    </b-modal>
    <b-modal
      id="careerbookcategoryPhotoModal"
      title="Myndir"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Loka"
      ref="photoModal"
    >
      <category-photo-modal
        ref="careerbookCategoryPhoto"
        @successful="successful"
        :category="category"
        :professionId="professionId"
        :id="id"
      ></category-photo-modal>
    </b-modal>
    <b-modal
      id="careerbookcategoryForumModal"
      title="Umræður"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Loka"
      ref="forumModal"
    >
      <category-forum-modal
        ref="careerbookCategoryForum"
        @successful="successful"
        :category="category"
        :professionId="professionId"
        :id="id"
      ></category-forum-modal>
    </b-modal>
    <b-modal
      id="careerbookFactorPhotoModal"
      size="xl"
      lazy
      @click.prevent="test"
      :no-close-on-backdrop="false"
      :hide-header="true"
      :hide-footer="true"
      ref="factorPhotoModal"
    >
      <factor-photo-modal ref="factorPhoto" :image="image" :id="id" @close="successful"></factor-photo-modal>
    </b-modal>
    <b-modal
      id="careerbookCompetenceConnectModal"
      title="Tengja hæfniviðmið"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Loka"
      cancel-title="Loka"
      @cancel.prevent="successful"
      @ok.prevent="successful"
      :ok-only="true"
      ref="competenceConnectModal"
    >
      <competence-connect-modal
        ref="competenceConnect"
        :professionId="professionId"
        :canEdit="canEdit"
        @close="successful"
      ></competence-connect-modal>
    </b-modal>
    <b-modal
      id="factorDescriptionEditModal"
      title="Breyta lýsingu verkþátta"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Loka"
      @ok.prevent="successful"
      :ok-only="true"
      :ok-disabled="loading"
      ref="descriptionEditModal"
    >
      <factor-description-edit-modal
        ref="factorDescriptionEdit"
        @successful="successful"
        @loading="setLoading"
        :category="category"
      ></factor-description-edit-modal>
    </b-modal>
    <b-modal
      id="factorGroupEditModal"
      title="Breyta flokkun verkþátta"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      :ok-disabled="loading"
      ref="groupEditModal"
    >
      <factor-group-edit-modal
        ref="factorGroupEdit"
        @successful="successful"
        @loading="setLoading"
        :category="category"
      ></factor-group-edit-modal>
    </b-modal>
    <b-modal
      id="professionVersionModal"
      title="Gera útgáfu"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Gera útgáfu"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      :ok-disabled="loading"
      ref="versionModal"
    >
      <careerbook-version-modal
        ref="professionVersion"
        @successful="successful"
        @loading="setLoading"
        :professionId="professionId"
        :professionName="professionName"
      ></careerbook-version-modal>
    </b-modal>
    <b-modal
      id="contractContactsModal"
      title="Samningur"
      size="lg"
      lazy
      :no-close-on-backdrop="false"
      ok-title="Loka"
      :ok-only="true"
      :ok-disabled="loading"
      ref="contractContactsModal"
    >
      <contract-contacts-modal ref="contractContacts" @successful="successful" @loading="setLoading" :id="id"></contract-contacts-modal>
    </b-modal>
  </div>
</template>

<script>
import CategoryCreateForm from '@/components/careerbook/category/CategoryCreateForm.vue';
import CategoryOverviewModal from '@/components/careerbook/modal/CategoryOverviewModal.vue';
import CategoryPhotoModal from '@/components/careerbook/modal/CategoryPhotoModal.vue';
import CategoryForumModal from '@/components/careerbook/modal/CategoryForumModal.vue';
import FactorPhotoModal from '@/components/careerbook/modal/FactorPhotoModal.vue';
import CompetenceConnectModal from '@/components/careerbook/modal/CompetenceConnectModal.vue';
import FactorDescriptionEditModal from '@/components/careerbook/modal/FactorDescriptionEditModal.vue';
import FactorGroupEditModal from '@/components/careerbook/modal/FactorGroupEditModal.vue';
import CareerbookVersionModal from '@/components/careerbook/modal/CareerbookVersionModal.vue';
import ContractContactsModal from '@/components/careerbook/modal/ContractContactsModal.vue';

export default {
  name: 'careerbook-category-modals',
  components: {
    CategoryCreateForm,
    CategoryOverviewModal,
    CategoryPhotoModal,
    CategoryForumModal,
    FactorPhotoModal,
    CompetenceConnectModal,
    FactorDescriptionEditModal,
    FactorGroupEditModal,
    CareerbookVersionModal,
    ContractContactsModal,
  },
  props: ['professionId', 'category', 'id', 'type', 'image', 'canEdit', 'professionName'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show() {
      if (this.type === 0) {
        this.$refs.createModal.show();
      } else if (this.type === 1) {
        this.$refs.overviewModal.show();
      } else if (this.type === 2) {
        this.$refs.photoModal.show();
      } else if (this.type === 3) {
        this.$refs.forumModal.show();
      } else if (this.type === 4) {
        this.$refs.factorPhotoModal.show();
      } else if (this.type === 5) {
        this.$refs.competenceConnectModal.show();
      } else if (this.type === 6) {
        this.$refs.descriptionEditModal.show();
      } else if (this.type === 7) {
        this.$refs.groupEditModal.show();
      } else if (this.type === 8) {
        this.$refs.versionModal.show();
      } else if (this.type === 9) {
        this.$refs.boxModal.show();
      } else if (this.type === 10) {
        this.$refs.contractContactsModal.show();
      }
    },
    handleCreate() {
      if (this.type === 0) {
        this.$refs.careerbookCategoryCreateForm.validateBeforeSubmit();
        // } else if (this.type === 6) {
        //   this.$refs.factorDescriptionEdit.validateBeforeSubmit();
      } else if (this.type === 7) {
        this.$refs.factorGroupEdit.validateBeforeSubmit();
      } else if (this.type === 8) {
        this.$refs.professionVersion.validateBeforeSubmit();
      }
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.createModal.hide();
      this.$refs.overviewModal.hide();
      this.$refs.photoModal.hide();
      this.$refs.forumModal.hide();
      this.$refs.factorPhotoModal.hide();
      this.$refs.competenceConnectModal.hide();
      this.$refs.descriptionEditModal.hide();
      this.$refs.groupEditModal.hide();
      this.$refs.versionModal.hide();
      this.$refs.boxModal.hide();
      this.$refs.contractContactsModal.hide();
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>
<style lang="scss">
#careerbookFactorPhotoModal {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-content {
    background-color: transparent;
    border-color: transparent;
  }
}
</style>
