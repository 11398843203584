import { render, staticRenderFns } from "./CategoryPhotoModal.vue?vue&type=template&id=90d21522&"
import script from "./CategoryPhotoModal.vue?vue&type=script&lang=js&"
export * from "./CategoryPhotoModal.vue?vue&type=script&lang=js&"
import style0 from "./CategoryPhotoModal.vue?vue&type=style&index=0&id=90d21522&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports