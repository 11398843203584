<template>
  <div v-if="list.length > 0">
    <b-card>
      <b-row>
        <b-col>
          <h5>Mínar ferilbækur sem umsýsluaðili</h5>
        </b-col>
      </b-row>
      <i class="fa fa-fw fa-spin fa-spinner" v-if="loading"></i>
      <table class="table table-hover" v-else>
        <thead>
          <tr>
            <th>Nemandi</th>
            <th>Starfsgrein</th>
            <th v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">Vinnustaður</th>
            <th v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">Tilsjónaraðili</th>
            <th v-else>Matsaðili</th>
            <th>Dags. frá</th>
            <th>Dags. til</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="list.length == 0">
            <td>
              <i>Engar niðurstöður fundust.</i>
            </td>
          </tr>
          <tr v-for="book in list" :key="book.id" @click="route(book)" style="cursor: pointer">
            <td>{{ book.student.name }}</td>
            <td>{{ book.profession_version.profession.name }}</td>
            <td v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1">
              <span v-for="(workplace, index) in book.workplaces" :key="workplace.id">
                <span v-if="index != 0"><br /></span>
                {{ workplace.name }}
              </span>
            </td>
            <td>
              <span v-for="(supervisor, index) in book.supervisors" :key="supervisor.id">
                <span v-if="index != 0"><br /></span>
                {{ supervisor.name }}
              </span>
            </td>
            <td>{{ book.date_from | moment('DD.MM.YYYY') }}</td>
            <td>{{ book.date_to | moment('DD.MM.YYYY') }}</td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="pagination && pagination.totalPages > 1"
        :pagination-response="pagination"
        @change-limit="changeLimit"
        @change-page="changePage"
      ></pagination>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import book from '@/api/book';
import Pagination from '@/components/Pagination.vue';
import { paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'instructor-book-list',
  mixins: [paginationMixin],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      page: null,
      pagination: null,
      list: null,
    };
  },
  methods: {
    route(item) {
      this.$router.push({ name: 'CareerbookById', params: { id: item.id } });
    },
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.pagination = null;
      this.list = [];
      try {
        const response = await book.myBooks(page, limit, null, null, 1);
        this.list = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss">
.lightGray {
  background-color: #f1f1f1;
}
.white {
  background-color: white;
}
.tbody {
  tr:nth-child(even) {
    background: #fff;
  }
  tr:nth-child(odd) {
    background: #f1f1f1;
  }
}
</style>
