<template>
  <fbk-page>
    <b-card>
      <b-row>
        <b-col>
          <h5 class="my-4">Yfirlit yfir ferilbækur umsækjanda</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <i
            >Hér að neðan er listi af þeim ferilbókum nemanda sem eiga umsóknir í stöðunni "Í vinnslu" eða "Samþykkt, í bið" í viðeigandi
            skóla. <br />
            Ferilbækur nemanda koma frá Símenntunarmiðstöðvum.
          </i>
        </b-col>
      </b-row>
      <br />
      <br />
      <table class="table">
        <tr>
          <th style="padding: 10px 0px 10px 0px">
            <b-form-input id="name" name="name" type="text" v-model="searchName" placeholder="Leita eftir nafni eða kennitölu nemanda">
            </b-form-input>
          </th>
        </tr>
      </table>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Starfsgrein</th>
            <th>Gildir frá</th>
            <th>Gildir til</th>
            <th>Vinnustaður</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="careerbookApplicationList.length == 0">
            <td colspan="2">
              <i>Engar leitarniðurstöður fundust.</i>
            </td>
          </tr>
          <tr
            class="table-row-linkable"
            v-for="(careerbookApplication, index) in careerbookApplicationList"
            :key="index"
            @click.prevent="
              $router.push({
                name: 'CareerbookById',
                params: {
                  id: careerbookApplicationList.id,
                  isApplicationCareerbook: true,
                },
              })
            "
          >
            <td class="with-link" style="font-size: 12px">
              <b-link
                :to="{
                  name: 'CareerbookById',
                  params: { profession: careerbookApplication.profession.id, id: careerbookApplication.id, isApplicationCareerbook: true },
                }"
                v-b-tooltip.hover
                title="Smelltu til að skoða ferilbók"
                @click.stop
                >{{ careerbookApplication.name }}
                <br />
                <div class="grayText">{{ careerbookApplication.ssn | subStr }}</div></b-link
              >
            </td>
            <td class="with-link" style="font-size: 12px">
              <b-link
                :to="{
                  name: 'CareerbookById',
                  params: { profession: careerbookApplication.profession, id: careerbookApplication.id, isApplicationCareerbook: true },
                }"
                v-b-tooltip.hover
                title="Smelltu til að skoða ferilbók"
                @click.stop
                >{{ careerbookApplication.profession }}
                <br />
                <div class="grayText">Útgáfa {{ careerbookApplication.profession_version }}</div></b-link
              >
            </td>
            <td style="font-size: 12px">
              {{ careerbookApplication.date_from | moment('DD.MM.YYYY') }}
            </td>
            <td style="font-size: 12px">
              {{ careerbookApplication.date_to | moment('DD.MM.YYYY') }}
            </td>
            <td style="font-size: 12px">
              <span v-for="(workplace, index) in workplaces(careerbookApplication.workplaces)" :key="index">
                <span v-if="index != 0"><br /></span>
                {{ workplace.workplace_name }}
              </span>
            </td>
            <td>
              <div v-b-tooltip.hover title="Afrita ferilbók" @click.stop.prevent="copyModal(careerbookApplication.id)">
                <i class="fa fa-fw fa-copy"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
      <copy-crud-modal ref="copyModal"></copy-crud-modal>
    </b-card>
  </fbk-page>
</template>
<script>
import { mapState } from 'vuex';
import { debounce, paginationMixin, parsePagination } from '@/utils';
import Pagination from '@/components/Pagination.vue';
import book from '@/api/book';
import CopyCrudModal from '@/components/careerbook/create/copyCrudModal.vue';

export default {
  name: 'careerbook-application-list',
  mixins: [paginationMixin],
  components: { Pagination, CopyCrudModal },
  data() {
    return {
      loading: false,
      page: null,
      pagination: null,
      careerbookApplicationList: [],
      searchName: null,
    };
  },
  filters: {
    subStr(string) {
      const newString = `${string.substring(0, 6)}-${string.substring(6, 10)}`;
      return newString;
    },
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async loadList(page = null, limit = null) {
      this.loading = true;
      this.pagination = null;
      this.careerbookApplicationList = [];
      try {
        const response = await book.careerbookApplicationList(page, limit, this.searchName);
        this.careerbookApplicationList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    workplaces(list) {
      return list.filter((v) => !v.is_deleted);
    },
    copyModal(id) {
      this.$refs.copyModal.show(id);
    },
  },
  created() {
    this.loadList();
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
  },
};
</script>
<style>
.grayText {
  font-size: 11px;
  color: #666;
}
</style>
