<template>
  <div class="line-hover" id="factorBody">
    <div
      v-if="item.subcategory && (!prevSubCategory || item.subcategory.id != prevSubCategory.id)"
      style="padding-top: 20px; padding-bottom: 5px; padding-left: 20px; margin-left: -15px; margin-right: -15px"
    >
      <i>{{ item.subcategory.name }}</i>
    </div>
    <b-row
      @click="collapseFactor()"
      class="pb-2 pt-2 line-title"
      :class="{ 'bg-secondary text-white': collapse, lightGray: index % 2 === 0 }"
      style="cursor: pointer"
    >
      <b-col cols="9" :id="`name${item.id}`">
        <div class="pull-left d-inline" style="min-width: 20px">{{ index + 1 }}.</div>
        <div class="d-inline">
          <p class="d-inline" style="padding-right: 1px" v-b-popover.hover.html="getFactorDescription" title="Lýsing verkþáttar">
            {{ item.name }}
          </p>
          <p class="d-inline" v-b-popover.hover.html="getCompetence" title="Hæfnikröfur">({{ item.competence.length }})</p>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="pull-right mb-0 d-inline">
          <i class="fa fa-fw" :class="{ 'fa-chevron-down': collapse, 'fa-chevron-right': !collapse }"></i>
        </div>
        <div v-if="notStudent !== null" class="pull-right h5 mb-0 pr-2 text-secondary d-inline-block">
          <div v-if="is_new_notification == true">
            <i class="fa fa-fw fa-send-o" style="color: red" v-b-tooltip.hover title="Verkþáttur hefur verið uppfærður"></i>
          </div>
          <div v-if="is_new_notification != true"><i class="fa fa-fw fa-send-o"></i></div>
        </div>
        <div class="pull-right mb-0 d-inline pr-2 text-secondary" v-if="id && item.complete && !item.supervisor_confirmed">
          <i>Hæfni náð</i>
        </div>
        <div class="pull-right mb-0 d-inline pr-2 text-secondary" v-if="id && item.supervisor_confirmed">
          <i v-if="loggedInUser.is_mms">Staðfest af tilsjónaraðila</i>
          <i v-else>Staðfest af matsaðila</i>
        </div>
        <!--Hæfni náð-->
        <div class="pull-right mb-0 d-inline pr-2 text-secondary" v-if="id && !item.complete && !item.supervisor_confirmed">
          <i>Hæfni ekki náð</i>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="collapse" class="p-2 border border-secondary">
      <b-col>
        <b-row v-if="item.factor_description != null && item.factor_description.length > 0">
          <b-col class="graybox">
            <div class="pt-2 pb-2"><span v-html="item.factor_description"></span></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col :class="{ colorbox: student, graybox: !student }" v-if="item.features.complete">
            <div class="pt-2">
              <strong
                v-b-tooltip.hover
                :title="
                  notStudent
                    ? 'Eingöngu nemandi getur breytt'
                    : !canEdit
                    ? 'Ekki hægt að breyta eftir að tilsjónaraðili hefur staðfest að hæfni hafi verið náð eða dags. til liðinn'
                    : ''
                "
                >Hæfni náð</strong
              >
            </div>
            <div class="pb-2">
              <b-form-checkbox
                v-model="item.complete"
                @change.native="save('complete')"
                :value="true"
                :unchecked-value="false"
                :disabled="notStudent || !canEdit"
                @change="updateCompleted"
              ></b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <div v-if="item.features.time_log">
          <b-row>
            <b-col :class="{ 'colorbox pb-2': student, 'graybox pb-2': !student }" style="padding-bottom: 4px">
              <div class="pt-2"><strong>Tímaskráning</strong></div>
              <div v-if="(notStudent && item.timelogs && (item.timelogs === undefined || item.timelogs.length == 0)) || !id">
                <i>Engar tímaskráningar skráðar</i>
              </div>
              <table>
                <tr v-if="student && canEdit">
                  <td class="pb-2">
                    <div v-if="timelog.period"><strong>Dags. frá</strong></div>
                    <b-input-group>
                      <flat-pickr
                        id="date"
                        name="date"
                        class="form-control"
                        type="text"
                        v-model="timelog.date"
                        :config="flatpickrConfig"
                        ref="date"
                        @change.native="changeDatePickr('date', timelog.date, $event)"
                      ></flat-pickr>
                      <b-input-group-append v-if="!timelog.period" style="padding-left: 5px">
                        <button
                          type="button"
                          @click.prevent="toggleTimelogPeriod()"
                          class="btn btn-sm"
                          v-b-tooltip.hover
                          title="Skrá tímabil"
                        >
                          <i class="fa fa-fw fa-calendar-plus-o"></i>
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                  <td class="pb-2 pl-2">
                    <div v-if="timelog.period"><strong>Dags. til</strong></div>
                    <b-input-group v-if="timelog.period">
                      <flat-pickr
                        id="dateTo"
                        name="dateTo"
                        class="form-control"
                        type="text"
                        v-model="timelog.dateTo"
                        :config="flatpickrConfig"
                        ref="dateTo"
                        @change.native="changeDatePickr('dateTo', timelog.dateTo, $event)"
                      ></flat-pickr>
                      <b-input-group-append style="padding-left: 5px">
                        <button
                          type="button"
                          @click.prevent="toggleTimelogPeriod()"
                          class="btn btn-sm"
                          v-b-tooltip.hover
                          title="Skrá stakan dag"
                        >
                          <i class="fa fa-fw fa-calendar-minus-o"></i>
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                  <td class="pb-2 pl-2">
                    <div v-if="timelog.period"><strong>Klst. á dag</strong></div>
                    <b-input-group>
                      <b-form-input v-model="timelog.hours" style="width: 70px" placeholder="klst"></b-form-input>
                      <b-input-group-append>
                        <button
                          type="button"
                          @click.prevent="saveTimelog()"
                          class="btn btn-sm btn-primary ml-2"
                          v-b-tooltip.hover
                          title="Vista"
                        >
                          <i class="fa fa-fw fa-save"></i>
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                </tr>
              </table>

              <table class="table">
                <tr v-if="item.timelogs && item.timelogs !== undefined && item.timelogs.length > 0">
                  <th>
                    <div
                      style="padding-bottom: 0px"
                      v-b-tooltip.hover
                      :title="
                        notStudent
                          ? 'Eingöngu nemandi getur breytt'
                          : !canEdit
                          ? 'Ekki hægt að breyta eftir að tilsjónaraðili hefur staðfest að hæfni hafi verið náð'
                          : ''
                      "
                    >
                      Dagsetning
                    </div>
                  </th>
                  <th></th>
                  <th class="text-center">
                    <div
                      style="padding-bottom: 0px"
                      v-b-tooltip.hover
                      :title="
                        notStudent
                          ? 'Eingöngu nemandi getur breytt'
                          : !canEdit
                          ? 'Ekki hægt að breyta eftir að tilsjónaraðili hefur staðfest að hæfni hafi verið náð'
                          : ''
                      "
                    >
                      Vika
                    </div>
                  </th>
                  <th></th>
                  <th class="text-center">
                    <div
                      style="padding-bottom: 0px"
                      v-b-tooltip.hover
                      :title="
                        notStudent
                          ? 'Eingöngu nemandi getur breytt'
                          : !canEdit
                          ? 'Ekki hægt að breyta eftir að tilsjónaraðili hefur staðfest að hæfni hafi verið náð'
                          : ''
                      "
                    >
                      Klukkutímar
                    </div>
                  </th>
                </tr>
                <tr v-for="(log, index) in item.timelogs" :key="log.id">
                  <td style="padding-top: 15px">{{ log.date | moment('DD.MM.YYYY') }}</td>
                  <td>{{ log }}</td>
                  <td class="text-center" style="padding-top: 15px">{{ log.date | moment('W') }}</td>
                  <td></td>
                  <td class="text-center" style="padding-top: 15px">{{ log.hours }}</td>
                  <td></td>
                  <td>
                    <button
                      @click.prevent="removeTimelog(index)"
                      class="btn btn-sm ml-2 pull-right"
                      v-if="!id || isStudentOrViewer"
                      :disabled="notStudent || !canEdit"
                    >
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
        <b-row v-if="item.features.description">
          <b-col :class="{ colorbox: student, graybox: !student }">
            <div class="pt-2"><strong>Umsögn nema</strong></div>
            <div v-if="student && canEdit">
              <b-form
                @submit.prevent="save('description')"
                novalidate
                v-on:keyup.esc="editField('description', false)"
                v-click-outside="descriptionClickOutside"
              >
                <inline-edit
                  class="mb-0"
                  field="description"
                  :doEdit="toEdit.description"
                  @editField="editField('description')"
                  @saveField="save('description')"
                >
                  <span slot="content"
                    ><span v-html="item.description"></span> <i v-if="!item.description">Smelltu hér til að skrá</i></span
                  >
                  <span slot="input">
                    <quill-editor
                      id="description"
                      name="description"
                      v-model="edit.description"
                      ref="descriptionQuillEditor"
                      v-b-tooltip.hover
                      :title="!id ? 'Ritill birtist eingöngu nemanda' : ''"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </span>
                </inline-edit>
              </b-form>
            </div>
            <div
              v-else
              v-b-tooltip.hover
              :title="
                notStudent
                  ? 'Eingöngu nemandi getur breytt'
                  : !canEdit
                  ? 'Ekki hægt að breyta eftir að tilsjónaraðili hefur staðfest að hæfni hafi verið náð eða dags. til liðinn'
                  : ''
              "
            >
              <span v-html="item.description"></span>
              <div
                v-if="!item.description || (item.description && (item.description === undefined || item.description.length == 0))"
                class="pb-2"
              >
                <i>Umsögn nema ekki skráð</i>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-2" :class="{ colorbox2col: student, graybox2col: !student }" v-if="item.features.files || item.features.photos">
          <b-col sm="12" md="12" lg="6" v-if="item.features.files">
            <div><strong>Skjöl</strong></div>
            <b-form
              style="padding-bottom: 10px"
              @submit.prevent="saveFiles"
              novalidate
              enctype="multipart/form-data"
              v-if="student && canEdit"
            >
              <b-form-group
                class="mb-0"
                :state="submitted && errors.has('file') ? false : null"
                :feedback="errors.first('file')"
                description="Hámarksstærð er 500 MB. Eftirfarandi skráarsnið eru ekki leyfð: .exe."
              >
                <vue-dropzone
                  ref="file"
                  id="file"
                  name="file"
                  style="min-height: 50px; padding: 0px"
                  @vdropzone-success="fileSuccess"
                  @vdropzone-error="fileError"
                  @vdropzone-file-added="saveFiles"
                  @vdropzone-sending="sendingFile"
                  @vdropzone-queue-complete="refetchFiles"
                  v-b-tooltip.hover
                  :title="!id ? 'Svæði birtist eingöngu nemanda' : ''"
                  :options="fileDropzoneConfig"
                ></vue-dropzone>
              </b-form-group>
            </b-form>
            <div style="text-align: right" v-if="item.files !== undefined && item.files.length !== 0" class="pb-2">
              <a @click.prevent="changeSort('files', 'name')"
                ><i class="fa fa-fw fa-sort-alpha-asc" v-b-tooltip.hover title="Raða í stafrófsröð"></i
              ></a>
              <a @click.prevent="changeSort('files', 'date_created')"
                ><i class="fa fa-fw fa-sort-amount-asc" v-b-tooltip.hover title="Raða í tímaröð"></i
              ></a>
            </div>
            <div v-if="notStudent && item.files && (item.files == undefined || item.files.length == 0)"><i>Engin skjöl skráð</i></div>
            <b-row class="pr-2 pl-2" v-if="item.files">
              <b-col
                cols="12"
                v-for="(file, index) in orderedFiles"
                :key="file.id"
                class="pt-2 pl-3"
                :class="{ light_gray: index % 2 === 0, white: index % 2 !== 0 }"
              >
                <span style="vertical-align: middle" v-b-tooltip.hover :title="getTitle(file)">{{ file.name | limitTo('20') }}</span>
                <button class="btn btn-sm pull-right" v-if="student && canEdit" @click.prevent="removeFile(index)">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
                <button
                  class="btn btn-sm pull-right"
                  @click.prevent="download('file', file.aws_file, id, file.name)"
                  v-b-tooltip.hover
                  :title="getTitle(file)"
                >
                  <i class="fa fa-fw fa-download"></i>
                </button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="12" lg="6" v-if="item.features.photos">
            <div :id="`photoList${item.id}`">
              <strong>Myndir</strong>
            </div>
            <b-form
              style="padding-bottom: 10px"
              @submit.prevent="saveImage"
              novalidate
              enctype="multipart/form-data"
              v-if="student && canEdit"
            >
              <b-form-group
                class="mb-0"
                :state="submitted && errors.has('image') ? false : null"
                :feedback="errors.first('image')"
                description="Hámarksstærð er 5 MB. Leyfð skráarsnið eru jpg, jpeg og png."
              >
                <vue-dropzone
                  style="min-height: 50px; padding: 0px"
                  ref="image"
                  id="image"
                  name="image"
                  @vdropzone-sending="sendingFile"
                  @vdropzone-file-added="saveImage"
                  @vdropzone-success="imageSuccess"
                  @vdropzone-error="imageError"
                  @vdropzone-queue-complete="refetchImages"
                  v-b-tooltip.hover
                  :title="!id ? 'Svæði birtist eingöngu nemanda' : ''"
                  :options="imageDropzoneConfig"
                >
                </vue-dropzone>
              </b-form-group>
            </b-form>
            <div style="text-align: right; padding-bottom: 5px" v-if="item.images !== undefined && item.images.length !== 0" class="pb-2">
              <a @click.prevent="changeSort('images', 'name')"
                ><i class="fa fa-fw fa-sort-alpha-asc" v-b-tooltip.hover title="Raða í stafrófsröð"></i
              ></a>
              <a @click.prevent="changeSort('images', 'date_created')"
                ><i class="fa fa-fw fa-sort-amount-asc" v-b-tooltip.hover title="Raða í tímaröð"></i
              ></a>
            </div>
            <div v-if="notStudent && item.images && (item.images == undefined || item.images.length == 0)"><i>Engar myndir skráðar</i></div>
            <b-row class="pr-2 pl-2" v-if="item.images">
              <b-col
                cols="12"
                v-for="(photo, pIdx) in orderedImages"
                :key="photo.id"
                class="pb-2"
                :class="{ light_gray: pIdx % 2 === 0, white: pIdx % 2 !== 0 }"
              >
                <button class="btn btn-sm" @click.prevent="factorPhotoModal(photo)">
                  <i class="fa fa-fw fa-search"></i>
                </button>
                <span style="vertical-align: middle" v-b-tooltip.hover :title="getTitleCaption(photo)">
                  {{ photo.name | limitTo('20') }}
                </span>
                <a
                  v-show="editOffset != pIdx && !notStudent"
                  v-b-tooltip.hover
                  title="Smelltu til að breyta heiti"
                  @click.prevent="startEditing(pIdx)"
                  class="fa fa-fw fa-pencil grab"
                  @click="editField('image_name', true)"
                ></a>
                <input
                  v-show="editOffset == pIdx"
                  type="text"
                  ref="photoCaption"
                  :id="'item-user-' + pIdx"
                  @keyup.esc="editField('image_name', false)"
                  @keydown.enter="save('image_name', photo.local_source)"
                  v-model="edit.image_name"
                  maxlength="50"
                />
                <a
                  v-show="editOffset == pIdx"
                  v-b-tooltip.hover
                  title="Vista"
                  class="fa fa-fw fa-check grab"
                  style="color: green"
                  @click="save('image_name', photo.local_source)"
                ></a>
                <a
                  v-show="editOffset == pIdx"
                  v-b-tooltip.hover
                  title="Hætta við"
                  class="fa fa-fw fa-times grab"
                  style="color: red"
                  @click="editField('image_name', false)"
                ></a>
                <button class="btn btn-sm pull-right" v-if="student && canEdit" @click.prevent="removeImage(pIdx)">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
                <button
                  class="btn btn-sm pull-right mr-1"
                  @click.prevent="download('image', photo.local_source, id, photo.name)"
                  v-b-tooltip.hover
                  :title="getTitle(photo)"
                >
                  <i class="fa fa-fw fa-download"></i>
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            :class="{ 'colorbox pt-2 pb-2': student || supervisor || instructor, 'graybox pt-2 pb-2': !student }"
            v-if="item.features.forum"
          >
            <div :id="`forumList${item.id}`"><strong>Umræður</strong></div>
            <div v-if="!item.messages || item.messages.length == 0"><i>Engar umræður skráðar</i></div>
            <div id="blablabla" style="max-height: 300px; overflow-y: auto">
              <b-row v-for="(f, index) in item.messages" :key="f.id" class="m-0">
                <b-col :class="{ 'forum-item-me': f.me, 'forum-item': !f.me }" cols="6" :offset="f.me ? 6 : ''">
                  <div class="remove pull-right" v-if="f.me && canEdit">
                    <i @click.prevent="removeComment(index)" class="fa fa-fw fa-remove"></i>
                  </div>
                  <div class="date pull-right" style="font-size: 0.6rem">{{ f.date | moment('DD.MM.YYYY HH:mm') }}</div>
                  <div class="name">{{ f.name }}</div>
                  <div class="text">{{ f.text }}</div>
                </b-col>
              </b-row>
              <span id="blabla"></span>
            </div>
            <div class="pt-2 pb-2" v-if="canEdit && !isViewer">
              <b-input-group>
                <b-form-input v-model="newComment" ref="forumNew" @keyup.enter.native="addNewComment"></b-form-input>
                <b-input-group-append>
                  <button type="button" @click.prevent="addNewComment" class="btn btn-sm btn-primary">Bæta við</button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col :class="{ 'colorbox pt-2': supervisor, 'graybox pt-2': !supervisor }" v-if="item.features.supervisor_confirmed">
            <div>
              <strong
                v-if="loggedInUser.is_mms"
                v-b-tooltip.hover
                :title="
                  notSupervisor ? 'Eingöngu tilsjónaraðili getur breytt' : !item.complete ? 'Ekki hægt að breyta fyrr en hæfni er náð' : ''
                "
                >Staðfest af tilsjónaraðila</strong
              >
              <strong v-else v-b-tooltip.hover :title="notSupervisor ? 'Eingöngu matsaðili getur breytt' : ''"
                >Staðfest af matsaðila</strong
              >
            </div>
            <div class="pb-2">
              <b-form-checkbox
                v-model="item.supervisor_confirmed"
                @change.native="save('supervisor_confirmed')"
                :value="true"
                :unchecked-value="false"
                :disabled="notSupervisor || !id || (!item.complete && !item.supervisor_confirmed)"
                @change="updateConfirmed"
              ></b-form-checkbox>
            </div>
          </b-col>
          <b-col :class="{ colorbox: supervisor, graybox: !supervisor }" v-if="item.features.grade">
            <div class="pt-2">
              <strong v-b-tooltip.hover :title="notSupervisor ? 'Eingöngu tilsjónaraðili getur breytt' : ''">Einkunn tilsjónaraðila</strong>
            </div>
            <div v-if="supervisor && canEdit">
              <b-form
                @submit.prevent="save('grade')"
                novalidate
                v-on:keyup.esc="editField('grade', false)"
                v-click-outside="gradeClickOutside"
              >
                <inline-edit class="mb-0" field="grade" :doEdit="toEdit.grade" @editField="editField('grade')" @saveField="save('grade')">
                  <span slot="content">{{ item.grade }} <i v-if="!item.grade">Smelltu hér til að skrá</i></span>
                  <span slot="input">
                    <b-form-input
                      id="grade"
                      name="grade"
                      type="text"
                      v-model="edit.grade"
                      v-on:keyup.enter="save('grade')"
                      ref="gradeInput"
                    ></b-form-input>
                  </span>
                </inline-edit>
              </b-form>
            </div>
            <div v-if="!canEdit" class="pb-2">
              {{ item.grade }} <span v-if="!item.grade && !canEdit"><i>Ekki skráð</i></span>
            </div>
          </b-col>
        </b-row>
        <b-row
          :class="{ 'colorbox2col pt-2 pb-2': supervisor, 'graybox2col pt-2 pb-2': !supervisor }"
          v-if="item.features.supervisor_evaluation"
        >
          <b-col>
            <div>
              <strong v-if="loggedInUser.is_mms">Umsögn tilsjónaraðila</strong>
              <strong v-else>Umsögn matsaðila</strong>
            </div>
            <div v-if="(supervisorEvaluations.length == 0 && notSupervisor) || !id"><i>Umsögn ekki skráð</i></div>
            <b-row v-for="evaluation in supervisorEvaluations" :key="evaluation.id" class="m-0">
              <b-col class="forum-item">
                <div class="date pull-right" style="font-size: 0.6rem">{{ evaluation.date_created | moment('DD.MM.YYYY HH:mm') }}</div>
                <div class="name">{{ evaluation.name }}</div>
                <div class="text"><span v-html="evaluation.text"></span></div>
              </b-col>
            </b-row>
            <div v-if="supervisor && canEdit">
              <b-form
                @submit.prevent="save('supervisor_evaluation')"
                novalidate
                v-on:keyup.esc="editField('supervisor_evaluation', false)"
                v-click-outside="supervisorEvaluationClickOutside"
              >
                <inline-edit
                  class="mb-0"
                  field="supervisor_evaluation"
                  :doEdit="toEdit.supervisor_evaluation"
                  @editField="editField('supervisor_evaluation')"
                  @saveField="save('supervisor_evaluation')"
                >
                  <span slot="content"
                    ><span v-html="item.supervisor_evaluation"></span
                    ><i v-if="!item.supervisor_evaluation">Smelltu hér til að skrá</i></span
                  >
                  <span slot="input">
                    <quill-editor
                      id="supervisor_evaluation"
                      name="supervisor_evaluation"
                      v-model="edit.supervisor_evaluation"
                      ref="supervisorQuillEditor"
                      v-b-tooltip.hover
                      :title="!id ? 'Ritill birtist eingöngu tilsjónaraðila' : ''"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </span>
                </inline-edit>
              </b-form>
            </div>
            <div
              v-if="id && supervisor && !canEdit"
              v-b-tooltip.hover
              title="Ekki hægt að breyta eftir að tilsjónaraðili hefur staðfest að hæfni hafi verið náð eða dags. til liðinn"
            >
              <span v-html="item.supervisor_evaluation"></span><i v-if="!item.supervisor_evaluation">Ekki skráð</i>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <category-modals ref="factorPhotoModal" :type="4" :image="imageToView" :id="id"></category-modals>
  </div>
</template>

<script>
import moment from 'moment';
import vue2Dropzone from 'vue2-dropzone';
import { mapActions, mapState } from 'vuex';
import { quillEditor } from 'vue-quill-editor';
import vClickOutside from 'v-click-outside';
import { flatpickrConfig, fileDropzoneConfig, imageDropzoneConfig } from '@/utils';
import config from '@/config';
import book from '@/api/book';
import InlineEdit from '@/components/InlineEdit.vue';
import CategoryModals from '@/components/careerbook/modal/CategoryModals.vue';

export default {
  name: 'factor-item',
  props: [
    'item',
    'category',
    'id',
    'student',
    'supervisor',
    'instructor',
    'openForum',
    'openPhotos',
    'openOverview',
    'index',
    'prevSubCategory',
    'competenceName',
  ],
  components: {
    vueDropzone: vue2Dropzone,
    quillEditor,
    CategoryModals,
    InlineEdit,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    openForum(newVal) {
      if (newVal === true) {
        this.collapse = true;
        this.$nextTick(() => {
          if (this.$refs.forumNew !== undefined) {
            this.$refs.forumNew.focus();
          }
          this.$scrollTo(`#forumList${this.item.id}`);
        });
      }
    },
    openPhotos(newVal) {
      if (newVal === true) {
        this.collapse = true;
        this.$nextTick(() => {
          this.$scrollTo(`#photoList${this.item.id}`);
        });
      }
    },
    openOverview(newVal) {
      if (newVal === true) {
        this.collapse = true;
        this.$nextTick(() => {
          this.$scrollTo(`#name${this.item.id}`);
        });
      }
    },
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
    notStudent() {
      return this.id && this.student === false;
    },
    notSupervisor() {
      return this.id && this.supervisor === false;
    },
    notInstructor() {
      return this.id && this.instructor === false;
    },
    isStudentOrViewer() {
      return this.id && this.supervisor === false && this.instructor === false;
    },
    isViewer() {
      return this.id && this.student === false && this.supervisor === false && this.instructor === false;
    },
    canEdit() {
      if (this.item.book) {
        const currentDate = moment().hour(0).minute(0).second(0);
        const dateFrom = this.item.book.date_from != null ? moment(this.item.book.date_from, 'YYYY-MM-DD') : currentDate;
        const dateTo = this.item.book.date_to != null ? moment(this.item.book.date_to, 'YYYY-MM-DD') : currentDate;
        return this.item.supervisor_confirmed === false && dateFrom <= currentDate && dateTo >= currentDate;
      }
      return false;
    },
    supervisorEvaluations() {
      return this.item.evaluations !== undefined ? this.item.evaluations.filter((v) => v.supervisor === 1 && !v.me) : [];
    },
    instructorEvaluations() {
      return this.item.evaluations !== undefined ? this.item.evaluations.filter((v) => v.instructor === 1 && !v.me) : [];
    },
    orderedFiles() {
      return [...this.item.files].sort((a, b) => {
        if (a[this.sort.files] < b[this.sort.files]) {
          return -1;
        }
        if (a[this.sort.files] > b[this.sort.files]) {
          return 1;
        }
        return 0;
      });
    },
    orderedImages() {
      return [...this.item.images].sort((a, b) => {
        if (a[this.sort.images] < b[this.sort.images]) {
          return -1;
        }
        if (a[this.sort.images] > b[this.sort.images]) {
          return 1;
        }
        return 0;
      });
    },
  },
  data() {
    return {
      editOffset: -1,
      editPost: {},
      editPostOri: {},

      imageToView: '',
      sort: {
        files: 'name',
        images: 'name',
      },
      timelog: {
        date: '',
        hours: '',
        period: false,
        dateTo: '',
      },
      toEdit: {
        image_name: false,
        description: false,
        supervisor_evaluation: false,
        instructor_evaluation: false,
        grade: false,
      },
      edit: {
        image_name: '',
        photo_id: null,
        description: '',
        supervisor_evaluation: '',
        instructor_evaluation: '',
        grade: '',
      },
      probe: false,
      submitted: false,
      flatpickrConfig: flatpickrConfig(),
      fileDropzoneConfig: fileDropzoneConfig(),
      imageDropzoneConfig: imageDropzoneConfig(),
      editorOption: {},
      collapse: false,
      newComment: '',
      images_counter: null,
      messages_counter: null,
      is_new_notification: false,
    };
  },
  methods: {
    startEditing(index) {
      this.editOffset = index;
      this.editPost = this.item.images[index];
      this.editPostOri = JSON.parse(JSON.stringify(this.editPost));
    },
    cancelEditing() {
      this.$set(this.item.images, this.editOffset, this.editPostOri);
      this.editOffset = -1;
      this.editPostOri = {};
      this.editPost = {};
    },
    // Sækir hæfnikröfur fyrir tooltip
    getCompetence() {
      let html = '<ul>';
      if (this.item.competence !== undefined) {
        this.item.competence.forEach((c) => {
          html += `<li>${c.name}</li>`;
        });
        html += '</ul>';
        if (this.item.competence.length === 0) {
          html = '<i>Engin tengd</i>';
        }
      } else {
        html = '<i>Engin tengd</i>';
      }

      html += '</ul>';
      return html;
    },

    // Sækir lýsingu verkþáttar fyrir tooltip
    getFactorDescription() {
      let html;
      if (this.item.factor_description !== null) {
        html = `<i>${this.item.factor_description}</i>`;
      } else {
        html = `<i>Engin lýsing</i>`;
      }
      return html;
    },

    collapseFactor() {
      this.collapse = !this.collapse;
      if (this.collapse !== false && this.notStudent !== null) {
        this.updateNotifications();
      }
    },

    updateCompleted(val) {
      this.item.complete = val;
      this.$emit('updateCompleted', {});
    },
    updateConfirmed(val) {
      this.item.supervisor_confirmed = val;
      this.$emit('updateCompleted', {});
    },
    updateSchoolConfirmed(val) {
      this.item.school_confirmed = val;
      this.$emit('updateCompleted', {});
    },
    changeDatePickr(name, date, event) {
      if (event.isTrusted) {
        this.$refs.date.fp.setDate(date, false);
      }
    },
    toggleTimelogPeriod() {
      this.timelog.period = !this.timelog.period;
      if (this.timelog.period && this.timelog.date !== '') {
        this.timelog.dateTo = new Date(this.timelog.date);
        this.timelog.dateTo.setDate(this.timelog.dateTo.getDate() + 1);
      }
    },
    getTitleCaption(item) {
      return `Lýsing: ${item.caption} - ${moment(item.date_created).format('DD.MM.YYYY HH:mm:ss')}`;
    },
    getTitle(item) {
      return `${item.name} - ${moment(item.date_created).format('DD.MM.YYYY HH:mm:ss')}`;
    },
    changeSort(field, orderBy) {
      this.$set(this.sort, field, orderBy);
    },
    /*
      Vista dálka
    */
    async save(feature, id) {
      if (this.id) {
        const data = {};
        let evaluation = false;
        let successResponse = '';
        switch (feature) {
          case 'complete':
            data.type = 'complete';
            data.complete = this.item.complete ? 1 : 0;
            if (this.item.complete) {
              successResponse = '"Hæfni náð" vistað';
              book.careerbookCategoryNotification({
                id: this.id,
                notification: 0,
                category: this.category,
                notificationType: 7,
                factor: this.item.book_factor,
                message: 'Hæfni náð vistað',
              });
            } else {
              successResponse = '"Hæfni ekki náð" vistað';
              book.careerbookCategoryNotification({
                id: this.id,
                notification: 0,
                category: this.category,
                notificationType: 7,
                factor: this.item.book_factor,
                message: 'Hæfni ekki náð vistað',
              });
            }
            break;
          case 'image_name':
            this.editOffset = -1;
            this.editPostOri = {};
            this.editPost = {};
            data.type = 'image_name';
            data.image_name = this.edit.image_name;
            data.photo_id = id;
            successResponse = 'Heiti myndar vistað';
            this.edit.image_name = '';
            book.careerbookCategoryNotification({
              id: this.id,
              notification: 0,
              category: this.category,
              notificationType: 1,
              factor: this.item.book_factor,
              message: 'Heiti myndar vistað',
            });
            break;
          case 'description':
            data.type = 'description';
            data.description = this.edit.description;
            successResponse = '"Umsögn nema" vistuð';
            book.careerbookCategoryNotification({
              id: this.id,
              notification: 0,
              category: this.category,
              notificationType: 6,
              factor: this.item.book_factor,
              message: 'Umsögn nema vistuð',
            });
            break;
          case 'grade':
            data.type = 'grade';
            data.grade = this.edit.grade;
            successResponse = '"Einkunn" vistuð';
            book.careerbookCategoryNotification({
              id: this.id,
              notification: 0,
              category: this.category,
              notificationType: 5,
              factor: this.item.book_factor,
              message: 'Einkunn vistuð',
            });
            break;
          case 'supervisor_confirmed':
            data.type = 'supervisor_confirmed';
            data.supervisor_confirmed = this.item.supervisor_confirmed ? 1 : 0;
            if (this.item.supervisor_confirmed) {
              successResponse = '"Staðfest" vistað';
              book.careerbookCategoryNotification({
                id: this.id,
                notification: 0,
                category: this.category,
                notificationType: 9,
                factor: this.item.book_factor,
                message: 'Staðfest vistað',
              });
            } else {
              successResponse = '"Óstaðfest" vistað';
              book.careerbookCategoryNotification({
                id: this.id,
                notification: 0,
                category: this.category,
                notificationType: 9,
                factor: this.item.book_factor,
                message: 'Óstaðfest vistað',
              });
            }
            break;
          case 'school_confirmed':
            data.type = 'school_confirmed';
            data.school_confirmed = this.item.school_confirmed ? 1 : 0;
            if (this.item.school_confirmed) {
              successResponse = '"Staðfest af skóla" vistað';
              book.careerbookCategoryNotification({
                id: this.id,
                notification: 0,
                category: this.category,
                notificationType: 9,
                factor: this.item.book_factor,
                message: 'Staðfest af skóla vistað',
              });
            } else {
              successResponse = '"Óstaðfest af skóla" vistað';
              book.careerbookCategoryNotification({
                id: this.id,
                notification: 0,
                category: this.category,
                notificationType: 9,
                factor: this.item.book_factor,
                message: 'Óstaðfest af skóla vistað',
              });
            }
            break;
          case 'supervisor_evaluation':
            data.text = this.edit.supervisor_evaluation;
            data.supervisor = 1;
            data.instructor = 0;
            evaluation = true;
            successResponse = '"Umsögn" vistuð';
            book.careerbookCategoryNotification({
              id: this.id,
              notification: 0,
              category: this.category,
              notificationType: 10,
              factor: this.item.book_factor,
              message: 'Umsögn vistuð',
            });
            break;
          case 'instructor_evaluation':
            data.text = this.edit.instructor_evaluation;
            data.supervisor = 0;
            data.instructor = 1;
            evaluation = true;
            successResponse = '"Námsmat" vistað';
            book.careerbookCategoryNotification({
              id: this.id,
              notification: 0,
              category: this.category,
              notificationType: 11,
              factor: this.item.book_factor,
              message: 'Námsmat vistað',
            });
            break;
          default:
            break;
        }
        try {
          if (evaluation) {
            await book.factorEvaluationCreate(this.id, this.category, this.item.id, data);
          } else {
            await book.factorPatch(this.id, this.category, this.item.id, data);
          }
          switch (feature) {
            case 'image_name':
              this.item.image_name = this.edit.image_name;
              this.refetchImages();
              this.editField('image_name', false);
              break;
            case 'description':
              this.item.description = this.edit.description;
              this.editField('description', false);
              break;
            case 'supervisor_evaluation':
              this.item.supervisor_evaluation = this.edit.supervisor_evaluation;
              this.refetchEvaluation();
              this.editField('supervisor_evaluation', false);
              break;
            case 'instructor_evaluation':
              this.item.instructor_evaluation = this.edit.instructor_evaluation;
              this.refetchEvaluation();
              this.editField('instructor_evaluation', false);
              break;
            case 'grade':
              this.item.grade = this.edit.grade;
              this.editField('grade', false);
              break;
            default:
              break;
          }
          this.displaySuccess(successResponse);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) við vistun kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    /*
      Athuga hvort þau vilji vista þegar klikka útfyrir
    */
    confirmOutside(field, id) {
      if (this.toEdit[field]) {
        const conf = confirm('Viltu vista breytingarnar? ' + field); //eslint-disable-line
        if (conf) {
          this.save(field, id);
        } else {
          this.editField(field, false);
        }
      }
    },
    photoCaptionClickOutside() {
      if (this.student) this.confirmOutside('image_name');
    },
    howOftenClickOutside() {
      if (this.student) this.confirmOutside('how_often');
    },
    descriptionClickOutside() {
      if (this.student) this.confirmOutside('description');
    },
    supervisorEvaluationClickOutside() {
      if (this.supervisor) this.confirmOutside('supervisor_evaluation');
    },
    instructorEvaluationClickOutside() {
      if (this.instructor) this.confirmOutside('instructor_evaluation');
    },
    gradeClickOutside() {
      if (this.supervisor) this.confirmOutside('grade');
    },
    /*
      Breyta í edit mode
    */
    editField(field, value) {
      this.$set(this.toEdit, field, value !== undefined ? value : !this.toEdit[field]);
      this.$set(this.edit, field, this.item[field]);
      if (field === 'image_name' && value === false) {
        this.cancelEditing();
      }
    },
    /*
      Námsmat
    */
    async refetchEvaluation() {
      try {
        const response = await book.factorEvaluationList(this.id, this.category, this.item.id);
        this.$set(this.item, 'evaluations', response.data);
        this.findMyEvaluations();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja mat. Vinsamlegast reyndu aftur.`);
      }
    },
    findMyEvaluations() {
      const supervisorEvaluation =
        this.item.evaluations !== undefined ? this.item.evaluations.find((e) => e.me && e.supervisor === 1) : undefined;
      const instructorEvaluation =
        this.item.evaluations !== undefined ? this.item.evaluations.find((e) => e.me && e.instructor === 1) : undefined;
      this.item.supervisor_evaluation = supervisorEvaluation !== undefined ? supervisorEvaluation.text : '';
      this.item.instructor_evaluation = instructorEvaluation !== undefined ? instructorEvaluation.text : '';
    },

    /*
      Tímaskráningar
    */
    async refetchTimelog() {
      try {
        const response = await book.factorTimelogList(this.id, this.category, this.item.id);
        this.$set(this.item, 'timelogs', response.data);
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja tímaskráningar. Vinsamlegast reyndu aftur.`,
        );
      }
    },
    async saveTimelog() {
      if (this.id && this.timelog.date && this.timelog.hours) {
        const dates = [];
        if (this.timelog.period) {
          const dateFrom = new Date(this.timelog.date);
          const dateTo = new Date(this.timelog.dateTo);
          while (dateFrom <= dateTo) {
            dates.push(moment(dateFrom).format('YYYY-MM-DD'));
            dateFrom.setDate(dateFrom.getDate() + 1);
          }
        } else {
          dates.push(this.timelog.date);
        }
        try {
          const promises = [];
          dates.forEach((date) => {
            promises.push(
              book.factorTimelogCreate(this.id, this.category, this.item.id, {
                register_date: date,
                hours: this.timelog.hours,
              }),
            );
          });
          await Promise.all(promises);
          this.displaySuccess('Timaskráning vistuð');
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 3,
            factor: this.item.book_factor,
            message: 'Timaskráning vistuð',
          });
          this.timelog = {
            date: '',
            hours: '',
            period: false,
            dateTo: '',
          };
          this.refetchTimelog();
        } catch (e) {
          this.$log.error(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að vista tímaskráningu. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    async removeTimelog(index) {
      if (this.student) {
        try {
          const name = moment(this.item.timelogs[index].date).format('DD.MM.YYYY'); // eslint-disable-line prefer-destructuring
          await book.factorTimelogDelete(this.id, this.category, this.item.id, this.item.timelogs[index].id); // eslint-disable-line
          this.displaySuccess(`Timaskráningu eytt fyrir„${name}“.`);
          this.refetchTimelog();
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 3,
            factor: this.item.book_factor,
            message: 'Timaskráningu eytt',
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða tímaskráningu. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    /*
      Skjöl
    */
    fileSuccess(file) {
      this.$refs.file.removeFile(file);
      this.$emit('file-success');
    },
    fileError(file, message, xhr) {
      if (xhr) {
        this.$emit('file-error', {
          response: {
            data: message,
            status: xhr.status,
          },
        });
      } else if (message !== undefined) {
        this.displayError(`Ekki tókst að vista skjal. ${message}`);
      }
    },
    async refetchFiles() {
      if (this.id) {
        try {
          this.$refs.file.removeAllFiles();
          const response = await book.factorFileList(this.id, this.category, this.item.id);
          this.$set(this.item, 'files', response.data);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða skrá. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    sendingFile(file, xhr) {
      xhr.setRequestHeader('filename', file.name);
      xhr.setRequestHeader('contentType', file.type);
    },
    async saveFiles() {
      if (this.id) {
        try {
          const uploadPromise = new Promise((resolve, reject) => {
            this.$once('file-success', () => {
              resolve();
            });

            this.$once('file-error', (message) => {
              reject(message);
            });
          });
          this.$refs.file.setOption(
            'url',
            config.BOOK_FACTOR_FILE_URL.replace('{id}', encodeURIComponent(this.id))
              .replace('{category}', encodeURIComponent(this.category))
              .replace('{factor}', encodeURIComponent(this.item.id)),
          );
          await uploadPromise;
          this.displaySuccess('Skjal vistað.');
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 4,
            factor: this.item.book_factor,
            message: 'Skjal vistað',
          });
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við vistun á skjali. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async removeFile(index) {
      if (this.student) {
        try {
          const name = this.item.files[index].name; // eslint-disable-line prefer-destructuring
          await book.factorFileDelete(this.id, this.category, this.item.id, this.item.files[index].id);
          this.displaySuccess(`Skjali eytt „${name}“.`);
          this.refetchFiles();
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 4,
            factor: this.item.book_factor,
            message: 'Skjali eytt',
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða skjali. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    /*
      Myndir
    */
    imageSuccess(image, response) {
      this.$refs.image.removeFile(image);
      this.$emit('image-success', { image, id: response.imageId });
    },
    imageError(file, message, xhr) {
      if (xhr) {
        this.$emit('image-error', {
          response: {
            data: message,
            status: xhr.status,
          },
        });
      } else if (message !== undefined) {
        this.displayError(`Ekki tókst að vista mynd. ${message}`);
      }
    },
    async refetchImages() {
      if (this.id) {
        try {
          const response = await book.factorImageList(this.id, this.category, this.item.id);
          this.$set(this.item, 'images', response.data);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja myndir. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async saveImage() {
      if (this.id) {
        try {
          const uploadPromise = new Promise((resolve, reject) => {
            this.$once('image-success', ({ image, id }) => {
              book.setFactorImageThumbnail(this.id, this.category, this.item.id, id, image);
              resolve();
            });
            this.$once('image-error', (message) => {
              reject(message);
            });
          });

          this.$refs.image.setOption(
            'url',
            config.BOOK_FACTOR_IMAGE_URL.replace('{id}', encodeURIComponent(this.id))
              .replace('{category}', encodeURIComponent(this.category))
              .replace('{factor}', encodeURIComponent(this.item.id)),
          );
          this.$refs.image.setOption('resizeWidth', 1280);
          this.$refs.image.processQueue();

          await uploadPromise;

          this.displaySuccess('Mynd vistuð.');
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 1,
            factor: this.item.book_factor,
            message: 'Mynd vistuð',
          });
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við vistun á mynd. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async removeImage(index) {
      if (this.student) {
        try {
          const name = this.item.images[index].name; // eslint-disable-line prefer-destructuring
          await book.factorImageDelete(this.id, this.category, this.item.id, this.item.images[index].id);
          this.displaySuccess(`Mynd eytt „${name}“.`);
          this.refetchImages();
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 1,
            factor: this.item.book_factor,
            message: 'Mynd eytt',
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða mynd. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    /*
      Hlaða niður myndum/skjölum
    */
    async download(type, id, bookId, filename) {
      try {
        if (type === 'file') {
          book.factorFileDownload(id, bookId, filename);
        } else if (type === 'image') {
          book.factorImageDownload(id, bookId, filename, 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.status}) kom upp við að sækja mynd. Vinsamlegast reyndu aftur.`);
      }
    },
    /*
      Umræðuþræðir
    */
    async refetchMessages() {
      try {
        const response = await book.factorMessageList(this.id, this.category, this.item.id);
        this.$set(this.item, 'messages', response.data);
        this.$scrollTo('#blabla', 200, {
          el: '#blabla',
          container: '#blablabla',
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja umræður. Vinsamlegast reyndu aftur.`);
      }
    },
    async addNewComment() {
      if (this.newComment.length > 0 && this.id && (this.student || this.supervisor || this.instructor)) {
        try {
          const response = await book.factorMessageCreate(this.id, this.category, this.item.id, { text: this.newComment });
          this.$set(this.item, 'messages', response.data);
          this.newComment = '';
          this.refetchMessages();
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 2,
            factor: this.item.book_factor,
            message: 'Ný skilaboð vistuð',
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(
            `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að vista skilaboð. Vinsamlegast reyndu aftur.`,
          );
        }
      }
    },
    async removeComment(index) {
      if (this.item.messages[index].me) {
        try {
          const name = this.item.messages[index].text; // eslint-disable-line prefer-destructuring
          await book.factorMessageDelete(this.id, this.category, this.item.id, this.item.messages[index].id);
          this.displaySuccess(`Umræðu eytt „${name}“.`);
          this.refetchMessages();
          book.careerbookCategoryNotification({
            id: this.id,
            notification: 0,
            category: this.category,
            notificationType: 2,
            factor: this.item.book_factor,
            message: 'Umræðu eytt',
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að eyða umræðu. Vinsamlegast reyndu aftur.`);
        }
      }
    },

    /**
     * Tilkynningar
     */
    async loadFactorCount() {
      this.loading = true;
      this.images_counter = null;
      this.messages_counter = null;
      this.is_new_notification = false;
      try {
        const response = await book.careerbookFactorCount(this.id, this.item.book_factor);
        this.images_counter = response.data[0].type;
        this.messages_counter = response.data[1].type;
        this.is_new_notification = response.data[11].new;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja tilkynningar. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    async updateCategoryNotifications() {
      this.loading = true;
      try {
        const response = await book.careerbookCategoryCount(this.id, this.item.id);
        this.$set(this.item, 'categorynotifications', response.data);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    async updateNotifications() {
      try {
        const response = await book.careerbookFactorNotificationUpdate({
          bookFactor: this.item.book_factor,
        });
        this.$set(this.item, 'notifications', response.data);
        this.loadFactorCount();
        this.updateCategoryNotifications();
      } catch (e) {
        this.$log.error(e);
      }
    },

    /**
     * Modal gluggar
     */
    factorPhotoModal(image) {
      this.imageToView = image;
      this.$refs.factorPhotoModal.show();
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    if (this.notStudent !== null) {
      this.loadFactorCount(this.id, this.item.book_factor);
      this.refetchEvaluation();
    }
  },
};
</script>
<style lang="scss">
@import '../../../style/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.graybox {
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin: 4px;
}

.colorbox {
  border-radius: 5px;
  border: 1px solid #8ca6b5;
  margin: 4px;
}

.graybox2col {
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin: 5px -11px 5px -11px;
  padding-bottom: 10px;
}

.colorbox2col {
  border-radius: 5px;
  border: 1px solid #8ca6b5;
  margin: 5px -11px 5px -11px;
  padding-bottom: 10px;
}

.forum-item {
  background-color: #e6e6e6;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  .name {
    font-size: 0.7rem;
    font-style: italic;
  }
}

.forum-item-me {
  background-color: #d1ecf1;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  .name {
    font-size: 0.7rem;
    font-style: italic;
  }
}

.line-hover {
  .line-title {
    &:hover,
    &:focus {
      background-color: #e6e6e6;
    }
  }
}

.table.lessPadding {
  tr {
    th,
    td {
      padding: 0.5rem;
    }
  }
}

.lightGray {
  background-color: #f1f1f1;
}
.white {
  background-color: white;
}

.grab {
  cursor: -webkit-grab;
  cursor: grab;
}
</style>
