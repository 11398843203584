<template>
  <div>
    <b-alert variant="danger" :show="failed">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Heiti starfsgreinar*:"
        label-for="name"
        :state="submitted && errors.has('name') ? false : null"
        :feedback="errors.first('name')"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          required
          placeholder="-- Sláðu inn heiti --"
          :state="submitted && errors.has('name') ? false : null"
          v-validate="'required'"
          data-vv-as="heiti"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Stofnun sem starfsgreinin tilheyrir*:"
        label-for="institution"
        :state="submitted && errors.has('institution') ? false : null"
        :feedback="errors.first('institution')"
      >
        <b-form-select
          id="institution"
          name="institution"
          v-model="form.institution"
          required
          :disabled="institutionList.length == 1"
          :state="submitted && errors.has('institution') ? false : null"
          v-validate="'required'"
          data-vv-as="stofnun"
          @change="getModules"
        >
          <option :value="null" disabled>-- Veldu stofnun --</option>
          <option v-for="(institution, index) in institutionList" :key="index" :value="institution">{{ institution.name }}</option>
        </b-form-select>
      </b-form-group>
      <!--b-form-group
          :state="submitted && errors.has('profession') ? false : null"
          :feedback="errors.first('profession')">
          <label label-for"profession">
            Opinber starfsgrein sem starfsgreinin tilheyrir ef á við:
            <i class="fa fa-fw fa-info-circle"
            v-b-tooltip.hover title="Tengt til þess að geta notað hæfnikröfu opinberu starfsgreinarinnar"></i>
          </label>
        <b-form-select id="profession" name="profession"
                      v-model="form.profession"
                      :state="submitted && errors.has('profession') ? false : null"
                      data-vv-as="starfsgrein">
          <option :value="null">-- Veldu opinbera starfsgrein --</option>
          <option v-for="(profession, index) in professionList" :key="profession.id" :value="profession">{{ profession.name }}</option>
        </b-form-select>
      </b-form-group-->
      <b-form-group
        label="Áfangi/ar:"
        label-for="modules"
        v-if="form.institution && form.institution.is_school == true"
        :state="submitted && errors.has('modules') ? false : null"
        :feedback="errors.first('modules')"
      >
        <div v-for="(module, index) in form.modules" :key="index" class="pb-2">
          {{ module.code }} {{ module.name }}
          <b-link @click.prevent="removeModule(index)" class="text-secondary"><i class="fa fa-remove"></i></b-link>
        </div>
        <v-select
          id="modules"
          name="modules"
          multiple
          placeholder="Skrifaðu inn í reitinn til að leita að áföngum."
          :on-change="modulesChanged"
          :debounce="250"
          v-model="selected"
          :options="options"
        >
          <template slot="no-options">Engir áfangar fundust.</template>
        </v-select>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import inna from '@/api/inna';

import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'profession-create-form',
  data() {
    return {
      submitted: false,
      loading: false,
      failed: false,
      created: false,
      errorList: null,
      institutionList: [],
      moduleList: [],
      professionList: [],
      selected: [],
      form: {
        name: '',
        institution: null,
        modules: [],
        profession: null,
      },
    };
  },
  computed: {
    options() {
      return this.moduleList
        .filter((v) => this.form.modules.filter((p) => p.id === v.id).length === 0)
        .map((v) => ({ value: v.id, label: `${v.code} ${v.name}` }));
    },
  },
  methods: {
    modulesChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }
      val.forEach((v) =>
        this.form.modules.push({
          id: v.value,
          name: v.label,
        }),
      );
    },
    async getInstitutions() {
      this.institutionList = [];
      const response = await core.institutionList();
      this.institutionList = response.data;
      if (this.institutionList.length === 1) {
        this.form.institution = this.institutionList[0]; // eslint-disable-line prefer-destructuring
        this.getModules(this.form.institution);
      }
    },
    async getModules(institution) {
      this.moduleList = [];
      this.form.modules = [];
      if (institution && institution.is_school === 1) {
        const response = await inna.moduleList(institution.ssn);
        this.moduleList = response.data.items;
      }
    },
    async getProfessions() {
      try {
        const response = await core.professionList({});
        this.professionList = response.data.filter((v) => v.category.institution === null);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    removeModule(index) {
      this.form.modules.splice(index, 1);
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          const response = await core.professionCreate({
            institution: this.form.institution.id,
            name: this.form.name,
            modules: JSON.stringify(this.form.modules),
          });

          this.id = response.data.id;
          this.created = true;

          this.displaySuccess(`Starfsgreinin „${this.form.name}“ stofnuð.`);
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },
  created() {
    this.getInstitutions();
    this.getProfessions();
  },
};
</script>
