<template>
  <div>
    <h4 v-if="!id">Hér kemur tímalína ferilbókar</h4>
    <b-card v-if="id" class="pt-2 pl-2 pr-2">
      <!-- <div class="mb-3 d-inline-block pull-right pr-1" v-if="id">
        <b-link class="btn btn-secondary" href @click.prevent="getHistoryExcel()" v-b-tooltip.hover title="Taka upplýsingar út í Excel">
          <i class="fa fa-fw fa-file-excel-o"></i>
        </b-link>
      </div> -->
      <table class="table table-hover">
        <thead>
          <th style="width: 12%">Hlutur</th>
          <th style="width: 12%">Aðgerð</th>
          <th style="width: 15%">Dagsetning skráð</th>
          <th style="width: 12%">Breytingar</th>
          <th style="width: 15%">Skráningaraðili</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in historyList" :key="index">
            <td v-if="item.object_type === 'FERILBOK'"><i class="fa fa-fw fa-book"></i>Ferilbók</td>
            <td v-if="item.object_type === 'USER'"><i class="fa fa-fw fa-user"></i>Nemi</td>
            <td v-if="item.object_type === 'SUPERVISOR'"><i class="fa fa-fw fa-user"></i>Tilsjónaraðili</td>
            <td v-if="item.object_type === 'INSTRUCTOR'"><i class="fa fa-fw fa-user"></i>Umsýsluaðili</td>
            <td v-if="item.object_type === 'FACTOR_DESCRIPTION'"><i class="fa fa-fw fa-pencil-square-o"></i>Umsögn nemanda</td>
            <td v-if="item.object_type === 'FACTOR_COMPLETE'"><i class="fa fa-fw fa-check-square-o"></i>Hæfni náð</td>
            <td v-if="item.object_type === 'EVALUATION'"><i class="fa fa-fw fa-pencil-square-o"></i>Umsögn tilsjónaraðila</td>
            <!-- <td v-if="item.object_type === 'Umsögn skóla'"><i class="fa fa-fw fa-pencil-square-o"></i>Umsögn skóla</td> -->
            <td v-if="item.object_type === 'TIMELOG'"><i class="fa fa-fw fa-clock-o"></i>Tímaskráning</td>
            <!-- <td v-if="item.object_type === 'Hæfni náð'"><i class="fa fa-fw fa-check-square-o"></i>Hæfni náð</td> -->
            <td v-if="item.object_type === 'FACTOR_SUPERVISOR_CONFIRMED'">
              <i class="fa fa-fw fa-check-square-o"></i>Staðfesting tilsjónaraðila
            </td>
            <td v-if="item.object_type === 'FACTOR_SCHOOL_CONFIRMED'"><i class="fa fa-fw fa-check-square-o"></i>Staðfesting skóla</td>
            <td v-if="item.object_type === 'IMAGE'"><i class="fa fa-fw fa-picture-o"></i>Mynd</td>
            <td v-if="item.object_type === 'FILE'"><i class="fa fa-fw fa-file-text-o"></i>Skjal</td>
            <td v-if="item.object_type === 'FACTOR_GRADE'"><i class="fa fa-fw fa-bar-chart-o"></i>Einkunn tilsjónaraðila</td>
            <td v-if="item.object_type === 'WORKPLACE'"><i class="fa fa-fw fa-building-o"></i>Hóf nám á vinnustað</td>
            <td v-if="item.object_type === 'FACTOR_MESSAGE'"><i class="fa fa-fw fa-comments"></i>Umræður</td>
            <!--
              <td v-if="item.object_type === 'Lauk námi á vinnustað'"><i class="fa fa-fw fa-building-o"></i>Lauk námi á vinnustað</td>
            -->
            <td v-if="item.object_type === 'BOOK_FACTOR'"><i class="fa fa-fw fa-building-o"></i>Verkþætti lokið</td>
            <td>
              <div v-if="item.action === 'DELETE'">Eyða</div>
              <div v-if="item.action === 'UPDATE'">Uppfæra</div>
              <div v-if="item.action === 'CREATE'">Skrá</div>
              <div v-if="item.action === 'DOWNLOAD'">Sótt</div>
            </td>
            <td>{{ item.time_create | moment('DD.MM.YYYY') }}</td>
            <td>
              <span v-if="item.object_type === 'FERILBOK' && item.action === 'CREATE'">
                <span>Ferilbók stofnuð</span>
                <!-- <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'date_from'">Dags. frá<br /></strong>
                  <strong v-if="name === 'date_to'">Dags. til<br /></strong>
                  <strong v-if="name === 'school'">Skóli<br /></strong>
                  Frá: {{ value.from }}<br />
                  Til: {{ value.to }}<br />
                </span> -->
              </span>
              <span v-else-if="item.object_type === 'FERILBOK' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'date_from'">Dags. frá<br /></strong>
                  <strong v-if="name === 'date_to'">Dags. til<br /></strong>
                  <strong v-if="name === 'school'">Skóli<br /></strong>
                  Frá: {{ value.from }}<br />
                  Til: {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'WORKPLACE' && item.action === 'CREATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  Vinnustaður<br />
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'WORKPLACE' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'date_from'">Dags. frá<br /></strong>
                  <strong v-if="name === 'date_to'">Dags. til<br /></strong>
                  <strong v-if="name === 'school'">Skóli<br /></strong>
                  Frá: {{ value.from }}<br />
                  Til: {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'FILE' && item.action === 'DELETE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'deleted'">Eytt</strong><br />
                  {{ value.data }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'FILE' && item.action === 'CREATE'">
                <span v-for="(value, index) in item.data" :key="index">
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'FILE' && item.action === 'DOWNLOAD'">
                <span v-for="(value, index) in item.data" :key="index">
                  {{ value.data }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'IMAGE' && item.action === 'DOWNLOAD'">
                <span v-for="(value, index) in item.data" :key="index">
                  {{ value.data }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'IMAGE' && item.action === 'DELETE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'deleted'">Eytt</strong><br />
                  {{ value.data }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'IMAGE' && item.action === 'CREATE'">
                <span v-for="(value, index) in item.data" :key="index">
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'TIMELOG' && item.action === 'DELETE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'deleted'">Eytt</strong><br />
                  Færsludags. {{ value.data | moment('DD.MM.YYYY') }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'FACTOR_DESCRIPTION' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'description'">Texti<br /></strong>
                  <div v-html="value.to"></div>
                </span>
              </span>
              <span v-else-if="item.object_type === 'FACTOR_SUPERVISOR_CONFIRMED' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'supervisor confirmed'">Staðfesting<br /></strong>
                  <div v-if="value.to === '0'">Merkt óstaðfest</div>
                  <div v-if="value.to === '1'">Merkt staðfest</div>
                </span>
              </span>
              <span v-else-if="item.object_type === 'FACTOR_COMPLETE' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'complete'">Staðfesting<br /></strong>
                  <div v-if="value.to === '0'">Merkt óstaðfest</div>
                  <div v-if="value.to === '1'">Merkt staðfest</div>
                </span>
              </span>
              <span v-else-if="item.object_type === 'FACTOR_SCHOOL_CONFIRMED' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'school confirmed'">Staðfesting<br /></strong>
                  <div v-if="value.to === '0'">Merkt óstaðfest</div>
                  <div v-if="value.to === '1'">Merkt staðfest</div>
                </span>
              </span>
              <span v-else-if="item.object_type === 'FACTOR_GRADE' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <span v-if="name === 'grade'">Einkunn </span>
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'FACTOR_MESSAGE' && item.action === 'CREATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'EVALUATION' && item.action === 'CREATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'text'">Texti<br /></strong>
                  <div v-html="value.to"></div>
                </span>
              </span>
              <span v-else-if="item.object_type === 'EVALUATION' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <strong v-if="name === 'text'">Texti<br /></strong>
                  <div v-html="value.to"></div>
                </span>
              </span>
              <span v-else-if="item.object_type === 'SUPERVISOR' && item.action === 'CREATE'">
                <span v-for="(value, name) in item.data" :key="name"
                  >Tilsjónaraðili skráður á bók<br />
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'SUPERVISOR' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name"
                  >Tilsjónaraðila eytt af bók<br />
                  {{ value.from }}</span
                >
              </span>
              <span v-else-if="item.object_type === 'USER' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name">
                  <div v-if="name === 'mobile'">Símanúmerið breytt úr <br /></div>
                  <div v-else-if="name === 'email'">Netfangið breytt úr <br /></div>
                  {{ value.from }} í {{ value.to }}</span
                >
              </span>
              <span v-else-if="item.object_type === 'INSTRUCTOR' && item.action === 'CREATE'">
                <span v-for="(value, name) in item.data" :key="name"
                  >Umsýsluaðili skráður á bók<br />
                  {{ value.to }}
                </span>
              </span>
              <span v-else-if="item.object_type === 'INSTRUCTOR' && item.action === 'UPDATE'">
                <span v-for="(value, name) in item.data" :key="name"
                  >Umsýsluaðila eytt af bók<br />
                  {{ value.from }}</span
                >
              </span>
              <!-- <span v-else>{{ item.data }}</span> -->
              <span v-else>''</span>
            </td>
            <td>{{ item.name }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import book from '@/api/book';
// import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'history-list',
  components: {},
  props: ['id'],
  data() {
    return {
      loading: false,
      historyList: [],
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const response = await book.careerbookHistory(this.id);
        this.historyList = response.data.results;

        this.historyList.forEach((h, idx) => {
          this.$set(h, 'data', JSON.parse(this.historyList[idx].data));
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /**
     *
     */

    async getHistoryExcel() {
      try {
        await book.historyToExcel(this.id);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja söguna í excel.
        Vinsamlegast reyndu aftur.`);
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss">
.break {
  border-top: 0.5px solid #c4c9ce;
  padding-top: 10px;
}

.table-border {
  thead,
  tbody {
    tr {
      td,
      th {
        border: 1px solid #868e96;
      }
    }
  }
}

tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
