<template>
  <div>
    <b-modal
      id="workplaceEditModal"
      title="Breyta tímabili"
      size="sm"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="createModal"
    >
      <b-row>
        <b-col style="font-size: 15px">
          {{ this.form.workplaceName }}
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col> Dags. frá </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :state="submitted && (errors.has('workplace_date_from') || invalid.date == true) ? false : null"
            :feedback="errors.first('workplace_date_from')"
          >
            <flat-pickr
              id="workplace_date_from"
              name="workplace_date_from"
              class="form-control"
              type="text"
              v-model="form.workplaceDateFrom"
              placeholder="Veldu dags. frá"
              :class="{ 'is-invalid': submitted && (errors.has('workplace_date_from') || invalid.date == true) }"
              :config="flatpickrConfig"
              data-vv-as="dags. frá"
              ref="workplaceDateFromPickr"
            ></flat-pickr>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col> Dags. til </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :state="submitted && (errors.has('workplace_date_to') || invalid.date == true) ? false : null"
            :feedback="errors.first('workplace_date_to')"
          >
            <div class="input-group">
              <flat-pickr
                id="workplace_date_to"
                name="workplace_date_to"
                class="form-control"
                type="text"
                v-model="form.workplaceDateTo"
                placeholder="Veldu dags. til"
                :class="{ 'is-invalid': submitted && (errors.has('workplace_date_to') || invalid.date == true) }"
                :config="flatpickrConfig"
                data-vv-as="dags. til"
                ref="workplaceDateToPickr"
              ></flat-pickr>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { flatpickrConfig, djangoErrorsToVee } from '@/utils';

import book from '@/api/book';

export default {
  name: 'edit-workplace-modal',
  data() {
    return {
      workplace: {},
      form: {
        workplaceDateFrom: null,
        workplaceDateTo: null,
        workplaceName: null,
      },
      // loading: false,
      submitted: false,
      // failed: false,
      errorList: [],
      // creating: false,
      invalid: {
        date: false,
        supervisor: false,
        instructor: false,
        noSupervisor: false,
        workdate: false,
      },
      flatpickrConfig: flatpickrConfig(),
    };
  },
  methods: {
    show(workplace) {
      this.$refs.createModal.show();
      this.form.workplaceId = workplace.book_workplace_id;
      this.form.workplaceName = workplace.name;
      this.form.workplaceDateFrom = moment(workplace.date_from).format('YYYY-MM-DD');
      this.form.workplaceDateTo = moment(workplace.date_to).format('YYYY-MM-DD');
    },

    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response || this.validateDates() === false) {
            this.displayError('Dags. frá má ekki vera á eftir dags. til. Vinsamlegast skoðið formið og lagfærið.');
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi
        try {
          this.loading = true;

          await book.careerbookWorkplaceUpdate(this.form.workplaceId, {
            date_from: this.form.workplaceDateFrom !== 'Invalid date' ? this.form.workplaceDateFrom : null,
            date_to: this.form.workplaceDateTo !== 'Invalid date' ? this.form.workplaceDateTo : null,
          });

          this.displaySuccess(`Tímabili vinnustaðar „${this.form.workplaceName}“ hefur verið breytt.`);
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            this.displayError('Upp kom villa sem tengist gögnunum sem verið að vista. Vinsamlegast skoðið formið og lagfærið.');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },

    validateDates() {
      let valid = true;
      this.invalid.date = false;
      if (this.form.workplaceDateFrom && this.form.workplaceDateTo) {
        const from = moment(this.form.workplaceDateFrom, 'YYYY-MM-DD');
        const to = moment(this.form.workplaceDateTo, 'YYYY-MM-DD');
        if (to.isBefore(from)) {
          valid = false;
          this.invalid.date = true;
        }
      }
      return valid;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
