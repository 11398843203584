import Vue from 'vue';

import config from '../config';

/**
 * Tekur við Http response, sækir skjal og niðurhalar því.
 * @param {*} param0 Response
 */
const downloadFileInResponse = ({ headers, data }) => {
  const a = document.createElement('a');
  const disposition = headers['content-disposition'];
  let filename = null;

  if (disposition?.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
  }
  const file = new Blob([data], { type: headers['content-type'] });
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export default {
  /**
   * Sækir tölfræði nemenda
   * @param {string} dateFrom Frá hvaða dagsetningu á að leita.
   * @param {string} dateTo Til hvaða dagsetningu á að leita.
   * @param {number} page Númer síðu sem á að sækja?
   * @param {number} limit Hámark raða á hverri síðu.
   * @returns Listi með tölfræði nemenda.
   */
  students(dateFrom, dateTo, page = 1, limit = 10) {
    return Vue.http
      .get(config.STATISTICS_STUDENTS_URL, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          current_page: page,
          page_limit: limit,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Sækir tölfræði nemenda í Excel skjali.
   * @param {String} dateFrom Frá hvaða dagsetningu á að leita.
   * @param {String} dateTo Til hvaða dagsetningu á að leita.
   * @returns Excel skjal.
   */
  studentsToExcel(dateFrom, dateTo) {
    return Vue.http
      .get(config.STATISTICS_STUDENTS_EXCEL_URL, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
        },
        responseType: 'arraybuffer',
      })
      .then(downloadFileInResponse)
      .catch((error) => Promise.reject(error));
  },
  /**
   * Sækir tölfræði vinnustaða
   * @param {Date} dateFrom Frá hvaða dagsetningu á að leita.
   * @param {Date} dateTo Til hvaða dagsetningu á að leita.
   * @param {number} page Númer síðu sem á að sækja?
   * @param {number} limit Hámark raða á hverri síðu.
   * @returns Listi með tölfræði vinnustaða.
   */
  workplaces(dateFrom, dateTo, page = 1, limit = 10) {
    return Vue.http
      .get(config.STATISTICS_WORKPLACES_URL, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          current_page: page,
          page_limit: limit,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Sækir tölfræði vinnustaða í Excel skjali.
   * @param {Date} dateFrom Frá hvaða dagsetningu á að leita.
   * @param {Date} dateTo Til hvaða dagsetningu á að leita.
   * @returns Excel skjal.
   */
  workplacesToExcel(dateFrom, dateTo) {
    return Vue.http
      .get(config.STATISTICS_WORKPLACES_EXCEL_URL, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
        },
        responseType: 'arraybuffer',
      })
      .then(downloadFileInResponse)
      .catch((error) => Promise.reject(error));
  },
  /**
   * Sækir tölfræði samninga
   * @param {Date} dateFrom Frá hvaða dagsetningu á að leita.
   * @param {Date} dateTo Til hvaða dagsetningu á að leita.
   * @param {number} page Númer síðu sem á að sækja?
   * @param {number} limit Hámark raða á hverri síðu.
   * @returns Listi með tölfræði samninga.
   */
  professions(dateFrom, dateTo, page = 1, limit = 10) {
    return Vue.http
      .get(config.STATISTICS_CONTRACTS_URL, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          current_page: page,
          page_limit: limit,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Sækir tölfræði starfsgreina í Excel skjali.
   * @param {Date} dateFrom Frá hvaða dagsetningu á að leita.
   * @param {Date} dateTo Til hvaða dagsetningu á að leita.
   * @returns Excel skjal.
   */
  professionsToExcel(dateFrom, dateTo) {
    return Vue.http
      .get(config.STATISTICS_CONTRACTS_EXCEL_URL, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
        },
        responseType: 'arraybuffer',
      })
      .then(downloadFileInResponse)
      .catch((error) => Promise.reject(error));
  },
};
