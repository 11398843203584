<template>
  <div>
    <h5 class="pl-2 pb-2">Almennur hluti skólasamnings</h5>
    <contract-text-edit v-if="loggedInUser.is_admin === 1" :contractText="contract" @successful="loadContract"> </contract-text-edit>

    <div v-else>
      <b-row class="p-2 pt-3">
        <b-col>
          <span v-html="contract"></span>
          <span v-if="contract.id == null"><i>Upplýsingar ekki skráðar</i></span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
// import { djangoErrorsToVee } from '@/utils';

import ContractTextEdit from '@/components/contract/ContractTextEdit.vue';

export default {
  name: 'school-contract-list',
  components: {
    ContractTextEdit,
  },
  data() {
    return {
      contract: '',
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    async loadContract() {
      try {
        const response = await core.contractList({});
        this.contract = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja samning. Vinsamlegast reyndu aftur.`);
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    this.loadContract();
  },
};
</script>
