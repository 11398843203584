<template>
  <div>
    <div v-if="!connect">
      <h5 class="grayBox">Starfalýsing</h5>
      <profession-description-edit
        v-if="!id && canEdit"
        :description="description"
        :professionId="professionId"
        @successful="updateProfessionDescription"
        class="box"
      >
      </profession-description-edit>
      <div v-else class="box" style="padding: 20px">
        <span v-html="description"></span>
        <span v-if="description == null || description.length == 0">Starfalýsing ekki skráð</span>
      </div>
      <br />
      <div>
        <h5 class="grayBox">Hæfnikröfur starfs</h5>
        <category-list
          :professionId="professionId"
          :versionId="versionId"
          :id="id"
          :canEdit="canEdit"
          :canEditCompetence="canEditCompetence"
          @connectCompetence="changeConnect(true)"
          class="box"
        >
        </category-list>
      </div>
    </div>
    <div v-else>
      <div>
        <h5 class="d-inline-block">
          <span v-if="canEdit"> Tengja hæfnikröfur </span>
          <span v-else> Tengingar hæfnikrafna </span>
        </h5>
        <b-btn @click.prevent="changeConnect(false)" class="mb-2 d-inline-block pull-right"
          ><i class="fa fa-fw fa-reply"></i> Til baka í yfirlit</b-btn
        >
      </div>
      <connect-competence :professionId="professionId" :canEdit="canEdit"></connect-competence>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ProfessionDescriptionEdit from '@/components/careerbook/competence/ProfessionDescriptionEdit.vue';
import CategoryList from '@/components/careerbook/competence/CategoryList.vue';
import ConnectCompetence from '@/components/careerbook/competence/ConnectCompetence.vue';

export default {
  name: 'overview',
  props: ['professionId', 'id', 'versionId', 'description', 'canEdit', 'canEditCompetence'],
  components: {
    ProfessionDescriptionEdit,
    CategoryList,
    ConnectCompetence,
  },
  data() {
    return {
      loading: true,
      connect: false,
    };
  },
  methods: {
    changeConnect(value) {
      this.connect = value;
    },
    async updateProfessionDescription() {
      this.$emit('successful', {});
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },
};
</script>

<style>
.box {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
h5.grayBox {
  background-color: #868e96;
  color: #fff !important;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0px;
  font-size: 1.03125rem;
}
</style>
