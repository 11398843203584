import { createModule, ADD_TOAST_MESSAGE } from 'vuex-toast';

import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/api/auth';

import * as types from './mutation-types';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const INITIAL_STATE = {
  authLoading: false,
  loggedInUser: null,
};

const state = JSON.parse(JSON.stringify(INITIAL_STATE));

const getters = {
  /**
   * Athuga hvort innskráður notandi hafi réttindi.
   *
   * @param {string} permission Réttindi á sniðmátinu app.object.permission (auth.ferilbokuser.add)
   */
  loggedInUserHasAnyPermission:
    (s) =>
    (...permission) => {
      const { loggedInUser } = s;

      if (!loggedInUser) {
        return false;
      }
      if (loggedInUser.is_admin === 1) {
        return true;
      }

      return loggedInUser.permissions.filter((p) => permission.includes(p)).length > 0;
    },
};

const actions = {
  displaySuccess({ dispatch }, message) {
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: message,
        type: 'success',
        dismissAfter: 5000,
      },
      {
        root: true,
      },
    );
  },
  displayError({ dispatch }, message) {
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: message,
        type: 'danger',
        dismissAfter: 5000,
      },
      {
        root: true,
      },
    );
  },
  doClean({ commit }) {
    commit(types.CLEAN);
  },
  getLoggedInUser({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.AUTH_LOADING, true);
      auth
        .me()
        .then((response) => {
          resolve(response.data);
          commit(types.ME_SUCCESS, response.data);
          commit(types.AUTH_LOADING, false);
        })
        .catch((error) => {
          reject(error);
          commit(types.ME_FAILED);
          commit(types.AUTH_LOADING, false);
        });
    });
  },
};

const mutations = {
  [types.CLEAN](s) {
    Object.keys(INITIAL_STATE).forEach((k) => {
      Vue.set(s, k, INITIAL_STATE[k]);
    });
  },
  [types.ME_SUCCESS](s, data) {
    Vue.set(s, 'loggedInUser', data);
  },
  [types.ME_FAILED](s) {
    Vue.set(s, 'loggedInUser', null);
  },
  [types.AUTH_LOADING](s, authLoading) {
    Vue.set(s, 'authLoading', authLoading);
  },
};

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    toast: createModule({
      dismissInterval: 8000,
    }),
  },
  strict: debug,
});
