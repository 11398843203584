<template>
  <div>
    <b-modal
      id="supervisorEditModal"
      title="Uppfæra tilsjónaraðila"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="createModal"
    >
      <b-row>
        <b-col style="font-size: 15px">
          <div>
            <span>{{ this.form.name }}</span>
            <br />
            <span class="small text-muted">{{ this.form.ssn }}</span>
          </div>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <b-form-group label="Netfang:" label-for="email">
            <b-form-input
              id="email"
              name="email"
              type="email"
              v-model="form.email"
              placeholder="Sláðu inn netfang tilsjónaraðila"
              data-vv-as="netfang tilsjónaraðila"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <b-form-group label="Farsími:" label-for="mobile">
            <b-form-input
              id="mobile"
              name="mobile"
              type="tel"
              v-model="form.mobile"
              placeholder="Sláðu inn farsíma tilsjónaraðila"
              data-vv-as="farsími tilsjónaraðila"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { djangoErrorsToVee } from '@/utils';

import book from '@/api/book';

export default {
  data() {
    return {
      supervisor: {},
      form: {
        email: null,
        mobile: null,
      },
    };
  },
  methods: {
    show(supervisor) {
      this.$refs.createModal.show();
      this.form.id = supervisor.supervisor_id;
      this.form.name = supervisor.name;
      this.form.ssn = supervisor.id;
      this.form.email = supervisor.email;
      this.form.mobile = supervisor.mobile;
      this.form.is_deleted = supervisor.is_deleted;
    },
    async validateBeforeSubmit() {
      try {
        await book.careerbookSupervisorUpdate(this.form.id, {
          mobile: this.form.mobile,
          email: this.form.email,
        });

        this.displaySuccess(`Upplýsingum tilsjónaraðila „${this.form.name}“ hefur verið breytt.`);
        this.$emit('successful', {});
        this.$refs.createModal.hide();
      } catch (e) {
        this.$log.debug(e);
        if (e.response && e.response.status === 400) {
          djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          this.displayError('Upp kom villa sem tengist gögnunum sem verið að vista. Vinsamlegast skoðið formið og lagfærið.');
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displaySuccess: 'displaySuccess',
      displayError: 'displayError',
    }),
  },
};
</script>
