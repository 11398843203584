<template>
  <fbk-page>
    <div>
      <b-alert variant="danger" :show="failed && errorList.length > 0">
        <ul class="list-unstyled mb-0">
          <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
        </ul>
      </b-alert>
      <b-form @submit.prevent="validateBeforeSubmit" novalidate>
        <b-card header="Almennar upplýsingar" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
          <!-- ADA: símmenntunviðbót: velja þarf ef notandi er bæði mms og símmenntun -->
          <div v-if="loggedInUser.is_master_admin === 1 || (loggedInUser.is_mms === 1 && loggedInUser.is_education_center === 1)">
            <b-row>
              <b-col>
                <b-form-group label="Tegund ferilbókar">
                  <b-form-checkbox
                    id="is_mms"
                    name="is_mms"
                    v-model="form.isMMS"
                    :disabled="form.isEducationCenter"
                    :value="true"
                    :unchecked-value="false"
                    v-b-popover.hover.left="'Sé hakað við hér stofnast ferilbókin sem MMS'"
                  >
                    MMS
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    id="is_educationcenter"
                    name="is_educationcenter"
                    v-model="form.isEducationCenter"
                    :disabled="form.isMMS"
                    :value="true"
                    :unchecked-value="false"
                    v-b-popover.hover.left="'Sé hakað við hér stofnast ferilbókin sem símenntun'"
                  >
                    Símenntun
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <br />
          </div>
          <b-row>
            <b-col>
              <b-form-group
                label="Starfsgrein*"
                label-for="profession"
                :state="submitted && errors.has('profession') ? false : null"
                :feedback="errors.first('profession')"
              >
                <b-form-select
                  id="profession"
                  name="profession"
                  v-model="form.profession"
                  required
                  :state="submitted && errors.has('profession') ? false : null"
                  v-validate="'required'"
                  data-vv-as="starfsgrein"
                  @change="loadProfessionData"
                >
                  <option :value="null" disabled>-- Veldu starfsgrein --</option>
                  <option v-for="(profession, index) in professionList" :key="index" :value="profession">{{ profession.name }}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Útgáfa*"
                label-for="version"
                :state="submitted && errors.has('version') ? false : null"
                :feedback="errors.first('version')"
              >
                <b-form-select
                  id="version"
                  name="version"
                  v-model="form.version"
                  required
                  :state="submitted && errors.has('version') ? false : null"
                  v-validate="'required'"
                  data-vv-as="útgáfa"
                >
                  <option :value="null" disabled>-- Veldu útgáfu --</option>
                  <option v-for="(version, index) in versionList" :key="index" :value="version">
                    Útgáfa {{ version.version }} - {{ version.date_created | moment('DD.MM.YYYY') }}
                  </option>
                </b-form-select>
              </b-form-group>
              <div
                v-if="form.profession == null && (loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1)"
                style="margin-top: -5px; margin-bottom: 5px"
              >
                <i>ATH! Til þess að geta valið vinnustað þarf að velja starfsgrein fyrst.</i>
              </div>
              <div
                v-if="form.profession != null && !loading.version && versionList.length == 0"
                style="margin-top: -5px; margin-bottom: 5px"
              >
                <i>ATH! Engar útgáfur fundust fyrir valda starfsgrein. Ekki er hægt að virkja ferilbók nema til sé útgáfa.</i>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :state="submitted && (errors.has('date_from') || invalid.date == true) ? false : null"
                :feedback="errors.first('date_from')"
              >
                <label label-for="date_from">
                  Dags. frá*
                  <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Hvenær ferilbók birtist nemanda."></i>
                </label>
                <flat-pickr
                  id="date_from"
                  name="date_from"
                  class="form-control"
                  type="text"
                  v-model="form.dateFrom"
                  placeholder="Veldu dags frá"
                  :class="{ 'is-invalid': submitted && (errors.has('date_from') || invalid.date == true) }"
                  :config="flatpickrConfig"
                  data-vv-as="dags frá"
                  v-validate="'required'"
                  ref="dateFromPickr"
                >
                </flat-pickr>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :state="submitted && (errors.has('date_to') || invalid.date == true) ? false : null"
                :feedback="errors.first('date_to')"
              >
                <label label-for="date_to">
                  Dags. til
                  <i
                    class="fa fa-fw fa-info-circle"
                    v-b-tooltip.hover
                    title="Hvenær nemandi má ekki lengur breyta ferilbók birtist nemanda. Ef óútfyllt má breyta þar til dags. er sett inn."
                  ></i>
                </label>
                <div class="input-group">
                  <flat-pickr
                    id="date_to"
                    name="date_to"
                    class="form-control"
                    type="text"
                    v-model="form.dateTo"
                    placeholder="Veldu dags til"
                    :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
                    :config="flatpickrConfig"
                    data-vv-as="dags frá"
                    ref="dateToPickr"
                  >
                  </flat-pickr>
                  <div class="input-group-btn">
                    <button class="btn btn-default" type="button" title="Hreinsa" data-clear>
                      <i class="fa fa-times">
                        <span aria-hidden="true" class="sr-only">Clear</span>
                      </i>
                    </button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="invalid.date">
            <b-col>
              <div class="text-danger">Dags frá verður að vera á undan dags til</div>
            </b-col>
          </b-row>
        </b-card>
        <br />
        <b-card header="Nemandi" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mt-3">
          <b-row>
            <b-col>
              <b-form-group
                class="mb-0"
                label="Kennitala*"
                label-for="student"
                :state="submitted && (errors.has('student') || invalid.student) ? false : ''"
                :feedback="errors.first('student')"
              >
                <b-form-input
                  id="student"
                  name="student"
                  type="text"
                  v-model="form.student.ssn"
                  required
                  placeholder="Sláðu inn kennitölu"
                  :state="
                    (submitted && (errors.has('student') || invalid.student)) ||
                    form.student.is_thjodskra == false ||
                    (form.student.is_school == false && form.student.is_institution == false)
                      ? false
                      : null
                  "
                  v-validate="'required|length:10'"
                  data-vv-as="kennitala"
                  maxlength="10"
                  @input="validateStudent"
                >
                </b-form-input>
              </b-form-group>
              <div v-if="form.student.is_thjodskra == false" class="text-danger">
                Eftirfarandi kennitala fannst ekki í þjóðskrá. Vinsamlegast sláið inn aðra kennitölu
              </div>
              <div v-else>
                <div v-if="form.student.is_school == false && form.student.is_institution == false" class="text-danger">
                  Ekki er hægt að velja eftirfarandi kennitölu
                </div>
              </div>
              <div v-if="invalid.student" class="text-danger">Nemandi má ekki vera skráður líka sem ábyrgðar- eða umsýsluaðili</div>
            </b-col>
            <b-col>
              <b-form-group label="Nafn*" label-for="name">
                <b-form-input
                  id="name"
                  name="name"
                  :state="submitted && errors.has('name') ? false : null"
                  v-validate="'required'"
                  data-vv-as="nafn"
                  type="text"
                  v-model="form.student.name"
                  required
                  disabled
                >
                </b-form-input>
                <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" style="color: #c1c1c1">
                  Nemandanúmer: {{ form.student.student_id }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Netfang" label-for="email" :feedback="errors.first('email')">
                <b-form-input
                  id="email"
                  name="email"
                  type="email"
                  v-model="form.student.email"
                  required
                  placeholder="Sláðu inn netfang"
                  data-vv-as="netfang nemanda"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Farsími" label-for="mobile" :feedback="errors.first('mobile')">
                <b-form-input
                  id="mobile"
                  name="mobile"
                  type="tel"
                  v-model="form.student.mobile"
                  required
                  placeholder="Sláðu inn farsíma"
                  data-vv-as="farsími nemanda"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.student.is_institution || (form.student.is_school && schoolList.length > 1)">
            <b-col cols="6">
              <b-form-group :state="submitted && errors.has('school') ? false : null" :feedback="errors.first('school')">
                <label label-for="school">
                  Skóli nemanda <span v-if="form.student.is_school && schoolList.length > 1">*</span>
                  <i
                    class="fa fa-fw fa-info-circle"
                    v-b-tooltip.hover
                    title="Með því að velja skóla sem nemandi tilheyrir þá fær sá skóli sjálfkrafa skoðunaraðgang að ferilbókinni hans."
                  ></i>
                </label>
                <b-form-select
                  id="school"
                  name="school"
                  v-model="form.school"
                  :state="submitted && errors.has('school') ? false : null"
                  v-validate="{ required: form.student.is_school && schoolList.length > 1 }"
                  data-vv-as="skóli"
                  @change="getStudentId(form.school.ssn)"
                >
                  <option :value="null">-- Veldu skóla --</option>
                  <option v-for="(school, index) in schoolList" :key="index" :value="school">{{ school.name }}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="form.student.relatives.length !== 0"
            class="mt-2 border-bottom"
            style="border-top: 1px lightgray solid; padding: 30px 0 0 0"
          >
            <b-col> Aðstandandi/-endur </b-col>
            <b-col> Netfang </b-col>
            <b-col> Símanúmer </b-col>
          </b-row>
          <div v-for="relative in form.student.relatives" :key="relative.forrad_id">
            <b-row class="mt-2">
              <b-col>
                <div>{{ relative.nafn }}</div>
              </b-col>
              <b-col>
                <div>{{ relative.netfang }}</div>
              </b-col>
              <b-col>
                <div>{{ relative.simi_as }}</div>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <br />
        <b-card
          header="Vinnustaður/Iðnmeistari/Ábyrgðaraðili"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          class="mt-3"
          v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group :state="submitted && errors.has('workplace') ? false : null" :feedback="errors.first('workplace')">
                <label label-for="workplace"> Heiti </label>
                <b-form-select
                  id="workplace"
                  name="workplace"
                  v-model="form.workplace"
                  :state="submitted && errors.has('workplace') ? false : null"
                  data-vv-as="workplace"
                >
                  <option :value="null">-- Veldu vinnustað --</option>
                  <option v-for="workplace in workplaceList" :key="workplace.id" :value="workplace">{{ workplace.name }}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <label label-for="workplace">Dags. frá</label>
              <b-form-group
                :state="submitted && (errors.has('workplace_date_from') || invalid.date == true) ? false : null"
                :feedback="errors.first('workplace_date_from')"
              >
                <flat-pickr
                  id="workplace_date_from"
                  name="workplace_date_from"
                  class="form-control"
                  type="text"
                  v-model="form.workplaceDateFrom"
                  placeholder="Veldu dags. frá"
                  :class="{ 'is-invalid': submitted && (errors.has('workplace_date_from') || invalid.date == true) }"
                  :config="flatpickrConfig"
                  data-vv-as="dags. frá"
                  ref="workplaceDateFromPickr"
                ></flat-pickr>
              </b-form-group>
            </b-col>
            <b-col>
              <label label-for="workplace">Dags. til </label>
              <b-form-group
                :state="submitted && (errors.has('workplace_date_to') || invalid.date == true) ? false : null"
                :feedback="errors.first('workplace_date_to')"
              >
                <div class="input-group">
                  <flat-pickr
                    id="workplace_date_to"
                    name="workplace_date_to"
                    class="form-control"
                    type="text"
                    v-model="form.workplaceDateTo"
                    placeholder="Veldu dags. til"
                    :class="{ 'is-invalid': submitted && (errors.has('workplace_date_to') || invalid.date == true) }"
                    :config="flatpickrConfig"
                    data-vv-as="dags. til"
                    ref="workplaceDateToPickr"
                  ></flat-pickr>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="form.profession == null">
                <i>ATH. Til þess að geta valið vinnustað þarf að velja starfsgrein fyrst.</i>
              </div>
              <div v-if="form.profession != null && !loading.workplaces && workplaceList.length == 0">
                <i>ATH. Eingöngu er hægt að skrá samþykkta vinnustaði. Engir vinnustaðir fundust skráðir fyrir valda starfsgrein.</i>
              </div>
              <div v-if="form.profession != null && !loading.workplaces && workplaceList.length > 0">
                <i
                  >ATH. Eingöngu er hægt að skrá samþykkta vinnustaði. Ef vinnustað vantar í listann, vinstamlegast hafið samband við
                  umsýsluaðila starfsgreinarinnar.</i
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <br />
        <b-card
          :header="loggedInUser.is_education_center === 1 ? 'Matsaðili' : 'Tilsjónaraðili/Leiðbeinandi'"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          class="mt-3"
        >
          <div v-for="(supervisor, supervisorIndex) in form.supervisors" :key="supervisorIndex">
            <b-row>
              <b-col>
                <b-form-group
                  label="Kennitala"
                  :label-for="`supervisor-${supervisorIndex}`"
                  :state="submitted && (errors.has(`supervisor-${supervisorIndex}`) || invalid.supervisor) ? false : null"
                  :feedback="errors.first(`supervisor-${supervisorIndex}`)"
                >
                  <b-form-input
                    :id="`supervisor-${supervisorIndex}`"
                    :name="`supervisor-${supervisorIndex}`"
                    type="text"
                    v-model="supervisor.ssn"
                    required
                    :placeholder="
                      loggedInUser.is_master_admin === 1 || loggedInUser.is_mms
                        ? 'Sláðu inn kennitölu tilsjónaraðila'
                        : 'Sláðu inn kennitölu matsaðila'
                    "
                    :state="submitted && (errors.has(`supervisor-${supervisorIndex}`) || invalid.supervisor) ? false : null"
                    data-vv-as="kennitala tilsjónaraðila"
                    maxlength="10"
                    @input="validateSupervisor(supervisor)"
                  >
                  </b-form-input>
                </b-form-group>
                <div v-if="supervisor.backendValidation == false" class="text-danger">
                  {{ supervisor.backendValidation }}
                </div>
                <div v-if="supervisor.is_thjodskra == false" class="text-danger">
                  Eftirfarandi kennitala fannst ekki í þjóðskrá. Vinsamlegast sláið inn aðra kennitölu
                </div>
                <div v-if="invalid.supervisor" class="text-danger">
                  Tilsjónaraðili má ekki vera skráður líka sem nemandi eða umsýsluaðili
                </div>
              </b-col>
              <b-col>
                <b-form-group
                  :state="submitted && errors.has(`supervisorName-${supervisorIndex}`) ? false : null"
                  :feedback="errors.first(`supervisorName-${supervisorIndex}`)"
                >
                  <label :for="`supervisorName-${supervisorIndex}`">Nafn <span v-if="supervisor.ssn.length > 0">*</span></label>
                  <b-input-group>
                    <b-form-input
                      :id="`supervisorName-${supervisorIndex}`"
                      :name="`supervisorName-${supervisorIndex}`"
                      :state="submitted && errors.has(`supervisor-${supervisorIndex}`) ? false : null"
                      data-vv-as="nafn tilsjónaraðila"
                      type="text"
                      v-model="supervisor.name"
                      readonly
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <button type="button" class="btn btn-sm btn-dark" @click="removeSupervisor(supervisorIndex)">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Netfang" label-for="email" :feedback="errors.first(`email-${supervisorIndex}`)">
                  <b-form-input
                    :id="`email-${supervisorIndex}`"
                    :name="`email-${supervisorIndex}`"
                    type="email"
                    v-model="supervisor.email"
                    required
                    :placeholder="
                      loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1
                        ? 'Sláðu inn netfang tilsjónaraðila'
                        : 'Sláðu inn netfang matsaðila'
                    "
                    data-vv-as="netfang tilsjónaraðila"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Farsími" label-for="mobile" :feedback="errors.first(`mobile-${supervisorIndex}`)">
                  <b-form-input
                    :id="`mobile-${supervisorIndex}`"
                    :name="`mobile-${supervisorIndex}`"
                    type="tel"
                    v-model="supervisor.mobile"
                    required
                    :placeholder="
                      loggedInUser.is_master_admin === 1 || loggedInUser.is_mms
                        ? 'Sláðu inn farsími tilsjónaraðila'
                        : 'Sláðu inn farsími matsaðila'
                    "
                    data-vv-as="farsími tilsjónaraðila"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :feedback="errors.first(`supervisorsigner-${supervisorIndex}`)">
                  <b-form-checkbox
                    :id="`supervisorsigner-${supervisorIndex}`"
                    :name="`supervisorsigner-${supervisorIndex}`"
                    v-model="supervisor.is_signet_signer"
                    v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1"
                    :value="true"
                    :unchecked-value="false"
                    v-b-popover.hover.left="'Sé hakað við hér fær tilsjónaraðili boð um að undirrita samninga vegna viðkomandi ferilbókar'"
                  >
                    Undirritar samninga
                  </b-form-checkbox></b-form-group
                >
              </b-col>
              <div v-if="invalid.supervisorSigner" class="text-danger">Veldu undirritanda</div>
            </b-row>
          </div>
          <!--Búa til fallið addSupervisor-->
          <b-btn @click.stop.prevent="addSupervisor" class="pull-right"><i class="fa fa-fw fa-plus-circle"></i>Bæta við</b-btn>
        </b-card>
        <br />
        <b-card
          :header="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1 ? 'Umsýsluaðili/Skóli' : 'Umsýsluaðili'"
          header-bg-variant="secondary"
          header-text-variant="white"
          header-class="h5"
          class="mt-3"
        >
          <div v-for="(instructor, instructorIndex) in form.instructors" :key="instructorIndex">
            <b-row>
              <b-col>
                <b-form-group
                  label="Kennitala*"
                  :label-for="`instructor-${instructorIndex}`"
                  :state="submitted && (errors.has(`instructor-${instructorIndex}`) || invalid.instructor) ? false : null"
                  :feedback="errors.first(`instructor-${instructorIndex}`)"
                >
                  <b-form-input
                    :id="`instructor-${instructorIndex}`"
                    :name="`instructor-${instructorIndex}`"
                    type="text"
                    v-model="instructor.ssn"
                    placeholder="Sláðu inn kennitölu umsýsluaðila"
                    :state="submitted && (errors.has(`instructor-${instructorIndex}`) || invalid.instructor) ? false : null"
                    v-validate="'length:10'"
                    data-vv-as="kennitala umsýsluaðila"
                    maxlength="10"
                    @input="validateInstructor(instructor)"
                  >
                  </b-form-input>
                </b-form-group>
                <div v-if="instructor.backendValidation != null" class="text-danger">
                  {{ instructor.backendValidation }}
                </div>
                <div v-if="instructor.is_thjodskra == false" class="text-danger">
                  Eftirfarandi kennitala fannst ekki í þjóðskrá. Vinsamlegast sláið inn aðra kennitölu
                </div>
                <div v-if="invalid.instructor" class="text-danger">
                  <div v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms">
                    Umsýsluaðili má ekki vera skráður líka sem nemandi eða tilsjónaraðili
                  </div>
                  <div v-else>Umsýsluaðili má ekki vera skráður líka sem nemandi eða matsaðili</div>
                </div>
              </b-col>
              <b-col>
                <b-form-group
                  :state="submitted && errors.has(`instructorName-${instructorIndex}`) ? false : null"
                  :feedback="errors.first(`instructorName-${instructorIndex}`)"
                >
                  <label :for="`instructorName-${instructorIndex}`">Nafn <span v-if="instructor.ssn.length > 0">*</span></label>
                  <b-input-group>
                    <b-form-input
                      :id="`instructorName-${instructorIndex}`"
                      :name="`instructorName-${instructorIndex}`"
                      :state="submitted && errors.has(`instructorName-${instructorIndex}`) ? false : null"
                      v-validate="{ required: instructor.ssn.length > 0 }"
                      data-vv-as="nafn umsýsluaðila"
                      type="text"
                      v-model="instructor.name"
                      readonly
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <button type="button" class="btn btn-sm btn-dark" @click="removeInstructor(instructorIndex)">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Netfang" label-for="email" :feedback="errors.first(`instructorEmail-${instructorIndex}`)">
                  <b-form-input
                    :id="`instructorEmail-${instructorIndex}`"
                    :name="`instructorEmail-${instructorIndex}`"
                    type="email"
                    v-model="instructor.email"
                    required
                    placeholder="Sláðu inn netfang umsjónaraðila"
                    data-vv-as="netfang umsjónaraðila"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Farsími" label-for="mobile" :feedback="errors.first(`instructorMobile-${instructorIndex}`)">
                  <b-form-input
                    :id="`instructorMobile-${instructorIndex}`"
                    :name="`instructorMobile-${instructorIndex}`"
                    type="tel"
                    v-model="instructor.mobile"
                    required
                    placeholder="Sláðu inn farsími umsjónaraðila"
                    data-vv-as="farsími umsjónaraðila"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :feedback="errors.first(`instructorsigner-${instructorIndex}`)">
                  <b-form-checkbox
                    :id="`instructorsigner-${instructorIndex}`"
                    :name="`instructorsigner-${instructorIndex}`"
                    v-model="instructor.is_signet_signer"
                    v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1"
                    :value="true"
                    :unchecked-value="false"
                    v-b-popover.hover.left="'Sé hakað við hér fær umsýsluaðili boð um að undirrita samninga vegna viðkomandi ferilbókar'"
                  >
                    Undirritar samninga
                  </b-form-checkbox></b-form-group
                >
              </b-col>
              <div v-if="invalid.instructorSigner" class="text-danger">Veldu undirritanda</div>
            </b-row>
          </div>
          <b-btn @click.stop.prevent="addInstructor" class="pull-right"><i class="fa fa-fw fa-plus-circle"></i> Bæta við</b-btn>
        </b-card>
        <br />
        <b-btn type="submit" variant="primary" :disabled="creating" v-if="loggedInUser.can_create_book === 1">
          <i class="fa fa-fw fa-spinner fa-spin" v-if="creating"></i>
          <i class="fa fa-fw fa-floppy-o" v-if="!creating"></i>
          Virkja
        </b-btn>
        <br /><br />
      </b-form>
    </div>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import core from '@/api/core';
import book from '@/api/book';

import { flatpickrConfig, djangoErrorsToVee } from '@/utils';

export default {
  name: 'create-form',
  data() {
    return {
      isMMS: false /* ADA: símmenntunviðbót */,
      isEducationCenter: false /* ADA: símmenntunviðbót */,
      supervisorHeader: '',
      instructorHeader: '',
      submitted: false,
      creating: false,
      loading: {
        profession: false,
        schools: false,
        workplaces: false,
        version: false,
      },
      failed: false,
      invalid: {
        date: false,
        student: false,
        supervisor: false,
        instructor: false,
        supervisorSigner: false,
        instructorSigner: false,
      },
      errorList: [],
      professionList: [],
      versionList: [],
      schoolList: [],
      workplaceList: [],
      flatpickrConfig: flatpickrConfig(),
      form: {
        isMMS: false /* ADA: símmenntunviðbót */,
        isEducationCenter: false /* ADA: símmenntunviðbót */,
        school: null,
        profession: null,
        version: null,
        workplaceDateFrom: null,
        workplaceDateTo: null,
        dateFrom: '',
        dateTo: '',
        student: {
          name: '',
          ssn: '',
          email: '',
          mobile: '',
          student_id: '',
          loading: false,
          is_thjodskra: null,
          is_school: null,
          is_institution: null,
          relatives: [],
        },
        workplace: null,
        supervisors: [
          {
            name: '',
            ssn: '',
            loading: false,
            is_thjodskra: null,
            backendValidation: null,
            is_signet_signer: false,
          },
        ],
        instructors: [
          {
            name: '',
            ssn: '',
            loading: false,
            is_thjodskra: null,
            backendValidation: null,
            is_signet_signer: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    // Sækir allar starfsgreinar
    async loadProfessions() {
      this.loading.profession = true;
      this.professionList = [];
      try {
        const response = await core.professionList({ createBook: 1 });
        this.professionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.profession = false;
      }
    },

    // Sækir allar útgáfur starfsgreinar
    async loadVersions(val) {
      this.loading.version = true;
      this.versionList = [];
      try {
        const response = await core.professionVersionList(val.id);
        this.versionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.version = false;
      }
    },

    loadProfessionData(val) {
      this.loadVersions(val);
      this.validateWorkplace(val);
    },

    // Nær í alla skóla - Snýst um aðgang skóla að ferilbókinni
    async loadSchools() {
      let isSchool = null;
      let allSchools = null;
      // Ef nemandinn sem þú valdir kemur frá skóla þá sækjum við bara þá skóla
      // sem þú hefur aðgang að. Annars sækjum við alla skóla í kerfinu.
      if (this.form.student.is_school) {
        isSchool = 1;
        allSchools = null;
      } else {
        isSchool = null;
        allSchools = 1;
      }
      this.loading.schools = true;
      this.schoolList = [];
      try {
        const response = await core.institutionList(isSchool, allSchools);
        this.schoolList = response.data;
        // Ef nemandinn kom frá skólanum og þú hefur bara aðgang að einum skóla
        // er sá skóli default valinn og þú getur ekki breytt.
        if (this.schoolList.length === 1 && this.form.student.is_school) {
          this.form.school = this.schoolList[0]; // eslint-disable-line prefer-destructuring
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.schools = false;
      }
    },

    // Sækir nemanda útfrá kennitölu
    async validateStudent() {
      this.form.student.name = '';
      if (this.form.student.ssn && this.form.student.ssn.length === 10) {
        this.form.student.loading = true;
        this.form.student.is_thjodskra = null;
        this.form.student.is_school = null;
        this.form.student.is_institution = null;
        try {
          const response = await book.searchStudent(this.form.student.ssn);
          this.form.student.is_thjodskra = response.data.is_thjodskra;
          this.form.student.is_school = response.data.is_school;

          // Ef símenntunarmiðstöð eða ef skóli er ekki til í kennslukerfinu.
          if (this.isEducationCenter === 1 || response.data.has_inna_connection === false) {
            this.form.student.is_school = true;
          }
          this.form.student.is_institution = response.data.is_institution;
          if (response.data.is_thjodskra && (this.form.student.is_school || this.form.student.is_institution)) {
            this.form.student.name = response.data.name;
            this.form.student.mobile = response.data.mobile;
            this.loadSchools();
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.form.student.loading = false;
        }
      }
    },

    // Sækir nemandanúmer ef nemandi er til í völdum skóla
    async getStudentId(schoolSsn) {
      this.form.student.loading = true;
      try {
        const response = await book.searchStudentInformation(this.form.student.ssn, schoolSsn);

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!response.data.results[0]) {
          this.form.student.student_id = response.data.results[0].nemandanr;
          this.form.student.relatives = response.data.results[0].adstandendur;
        } else {
          this.form.student.student_id = '';
          this.form.student.relatives = [];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.form.student.loading = false;
      }
    },

    // Sækir tilsjónaraðila útfrá kennitölu
    async validateSupervisor(supervisor) {
      supervisor.name = ''; // eslint-disable-line no-param-reassign
      supervisor.is_thjodskra = null; // eslint-disable-line no-param-reassign
      if (supervisor.ssn && supervisor.ssn.length === 10) {
        supervisor.loading = true; // eslint-disable-line no-param-reassign
        supervisor.is_thjodskra = null; // eslint-disable-line no-param-reassign
        try {
          const response = await book.searchSupervisor(supervisor.ssn);
          supervisor.is_thjodskra = response.data.is_thjodskra; // eslint-disable-line no-param-reassign
          supervisor.email = response.data.email; // eslint-disable-line no-param-reassign,
          supervisor.mobile = response.data.mobile; // eslint-disable-line no-param-reassign,
          if (response.data.is_thjodskra) {
            supervisor.name = response.data.name; // eslint-disable-line no-param-reassign
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          supervisor.loading = false; // eslint-disable-line no-param-reassign
        }
      }
    },

    // Sækir umsýsluaðila útfrá kennitölu
    async validateInstructor(instructor) {
      instructor.name = ''; // eslint-disable-line no-param-reassign
      instructor.is_thjodskra = null; // eslint-disable-line no-param-reassign
      if (instructor.ssn && instructor.ssn.length === 10) {
        instructor.loading = true; // eslint-disable-line no-param-reassign
        try {
          const response = await book.searchInstructor(instructor.ssn);
          instructor.is_thjodskra = response.data.is_thjodskra; // eslint-disable-line no-param-reassign, max-len
          instructor.email = response.data.email; // eslint-disable-line no-param-reassign,
          instructor.mobile = response.data.mobile; // eslint-disable-line no-param-reassign,
          if (response.data.is_thjodskra) {
            instructor.name = response.data.name; // eslint-disable-line no-param-reassign
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          instructor.loading = false; // eslint-disable-line no-param-reassign
        }
      }
    },

    // Sækir vinnustað útfrá kennitölu
    async validateWorkplace(val) {
      this.workplaceList = [];
      this.form.workplace = null;
      try {
        this.loading.workplaces = true;
        const response = await book.searchWorkplace(val.id);
        this.workplaceList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplaces = false;
      }
    },

    // Athugar hvort dags frá sé ekki örugglega á undan dags til
    validateDates() {
      let valid = true;
      this.invalid.date = false;
      if (this.form.dateFrom && this.form.dateTo) {
        const from = moment(this.form.dateFrom, 'YYYY-MM-DD');
        const to = moment(this.form.dateTo, 'YYYY-MM-DD');
        if (to.isBefore(from)) {
          valid = false;
          this.invalid.date = true;
        }
      }
      return valid;
    },

    // Athugar hvort það sé skráður tilsjónaraðili og umsjónaraðili
    signetSigners() {
      let instructor = false;
      let supervisor = false;
      this.invalid.instructorSigner = true;
      this.invalid.supervisorSigner = true;

      if (!!this.form.instructors.find((i) => i.is_signet_signer) === true) {
        instructor = true;
        this.invalid.instructorSigner = false;
      }

      if (!!this.form.supervisors.find((i) => i.is_signet_signer) === true) {
        supervisor = true;
        this.invalid.supervisorSigner = false;
      }
      return supervisor && instructor;
    },

    // Skoðar hvort einhver aðili sé skilgreindur í mörgum hlutverkum
    validatePersons() {
      this.invalid.student = false;
      this.invalid.supervisor = false;
      this.invalid.instructor = false;
      let valid = true;
      if (this.form.supervisors.find((i) => i.ssn === this.form.student.ssn) !== undefined) {
        valid = false;
        this.invalid.student = true;
        this.invalid.supervisor = true;
      }
      if (this.form.instructors.find((i) => i.ssn === this.form.student.ssn) !== undefined) {
        valid = false;
        this.invalid.student = true;
        this.invalid.instructor = true;
      }
      this.form.supervisors.forEach((i) => {
        this.form.instructors.forEach((i2) => {
          if (i2.ssn === i.ssn) {
            valid = false;
            this.invalid.supervisor = true;
            this.invalid.instructor = true;
          }
        });
      });
      return valid;
    },

    // Virkja ferilbók
    async validateBeforeSubmit() {
      try {
        /* ADA: símmenntunviðbót */
        if (this.form.school) {
          this.isMMS = this.form.school.is_mms;
          this.isEducationCenter = this.form.school.is_educationCenter;
        } else {
          this.isMMS = this.isMMS === 1 && this.isEducationCenter === 1 ? this.form.isMMS : this.isMMS;
          this.isEducationCenter = this.isMMS === 1 && this.isEducationCenter === 1 ? this.form.isEducationCenter : this.isEducationCenter;
        }

        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          // Athuga hvort formið sé invalid, dagsetningarnar invalid eða manneskjurnar
          if (!response || this.validateDates() === false || this.validatePersons() === false) {
            this.displayError('Gögn ekki rétt. Vinsamlegast skoðið formið og lagfærið.');
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });

        // Engar villur í formi.
        try {
          this.creating = true;
          const workplaces = [];
          const supervisors = [];
          const instructors = [];

          if (this.form.workplace != null) {
            workplaces.push({
              id: this.form.workplace.id,
              date_from: this.form.workplaceDateFrom,
              date_to: this.form.workplaceDateTo,
              is_delete: 0,
            });
          }
          this.form.supervisors.forEach((i) => {
            if (i.ssn.length === 10) {
              supervisors.push({ ssn: i.ssn, email: i.email, mobile: i.mobile, is_signet_signer: i.is_signet_signer });
            }
          });
          this.form.instructors.forEach((i) => {
            if (i.ssn.length === 10) {
              instructors.push({ ssn: i.ssn, email: i.email, mobile: i.mobile, is_signet_signer: i.is_signet_signer });
            }
          });
          const item = {
            is_mms: this.isMMS ? 1 : 0 /* ADA: símmenntunviðbót */,
            is_educationCenter: this.isEducationCenter ? 1 : 0 /* ADA: símmenntunviðbót */,
            student: this.form.student.ssn,
            student_email: this.form.student.email,
            student_mobile: this.form.student.mobile,
            profession: this.form.profession.id,
            profession_version: this.form.version.id,
            school: this.form.school ? this.form.school.id : null,
            date_from: this.form.dateFrom,
            date_to: this.form.dateTo !== '' ? this.form.dateTo : null,
            workplaces: JSON.stringify(workplaces),
            supervisors: JSON.stringify(supervisors),
            instructors: JSON.stringify(instructors),
          };
          const response = await book.careerbookCreate(item);
          this.id = response.data.id;
          // Opna ferilbókina
          this.$router.push({ name: 'CareerbookById', params: { id: this.id } });

          this.displaySuccess(`Ferilbók fyrir ${this.form.student.name} í „${this.form.profession.name}“ virkjuð`);
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400 && e.response.data.error !== '') {
            this.displayError('Aðeins má stofna ferilbók einu sinni fyrir hverja opinbera starfsgrein');
          } else if (e.response && e.response.status === 400 && e.response.data.error === '') {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
            if (e.response.data.instructors) {
              this.form.instructors.forEach((i, index) => {
                if (e.response.data.instructors[index] && e.response.data.instructors[index].length > 0) {
                  i.backendValidation = e.response.data.instructors[index]; // eslint-disable-line no-param-reassign, max-len
                }
              });
            }
            this.displayError('Upp kom villa sem tengist gögnunum sem verið að vista. Vinsamlegast skoðið formið og lagfærið');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur`);
          }
        } finally {
          this.creating = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    /* ADA: símmenntunviðbót */
    async bookUserType() {
      this.isMMS = false;
      this.isEducationCenter = false;

      try {
        const response = await book.checkBookUserType();
        this.isMMS = response.data.is_mms;
        this.isEducationCenter = response.data.is_educationCenter;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur`);
      }
    },
    // Bæta við tilsjónaraðila
    addSupervisor() {
      this.form.supervisors.push({
        ssn: '',
        name: '',
        loading: false,
        is_thjodskra: null,
        backendValidation: null,
      });
    },

    // Fjarlægja tilsjónaraðila
    removeSupervisor(index) {
      this.form.supervisors.splice(index, 1);
    },
    // Bæta við umsýsluaðila
    addInstructor() {
      this.form.instructors.push({
        ssn: '',
        name: '',
        loading: false,
        is_thjodskra: null,
        backendValidation: null,
      });
    },

    // Fjarlægja umsýsluaðila
    removeInstructor(index) {
      this.form.instructors.splice(index, 1);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },

  created() {
    this.bookUserType(); /* ADA: símmenntunviðbót  */
    this.loadProfessions();
    this.loadSchools();
  },
};
</script>

<style lang="scss">
.divider {
  border-bottom: 1px solid #868e96;
  padding-top: 10px;
}
</style>
