<template>
  <div class="categoryBox" style="clear: left">
    <h3>{{ categoryName }}</h3>
    <div v-for="factor in factorList" :key="factor.id">
      <div v-if="factor.features.photos === true">
        <category-factor-photos
          :careerbookId="id"
          :factorId="factor.id"
          :categoryId="categoryId"
          :factorName="factor.name"
          :isModal="false"
          :student="student"
          class="photo"
        ></category-factor-photos>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import book from '@/api/book';
import CategoryFactorPhotos from '@/components/careerbook/modal/CategoryFactorPhotos.vue';

export default {
  name: 'category-photos',
  props: ['id', 'categoryId', 'categoryName', 'student'],
  components: {
    CategoryFactorPhotos,
  },
  data() {
    return {
      factorList: [],
      loading: false,
    };
  },

  methods: {
    async getCategoryFactors() {
      this.loading = true;
      this.factorList = {};
      try {
        let response = [];
        response = await book.careerbookFactorList(this.id, this.categoryId);
        this.factorList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkþætti. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getCategoryFactors();
  },
};
</script>
<style lang="scss">
.categoryBox {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}
</style>
