<template>
  <div>
    <b-modal
      id="institutionCreateModal"
      title="Skrá stofnun"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      ref="createModal"
    >
      <institution-create-form ref="institutionCreateForm" @successful="successful"></institution-create-form>
    </b-modal>
    <b-modal
      id="institutionUpdateModal"
      title="Breyta stofnun"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista breytingar"
      cancel-title="Hætta við"
      @ok.prevent="handleUpdate"
      ref="updateModal"
    >
      <institution-update-form ref="institutionUpdateForm" :id="id" @successful="successful"></institution-update-form>
    </b-modal>
  </div>
</template>

<script>
import InstitutionCreateForm from '@/components/institution/InstitutionCreateForm.vue';
import InstitutionUpdateForm from '@/components/institution/InstitutionUpdateForm.vue';

export default {
  name: 'institution-crud-modal',
  components: {
    InstitutionCreateForm,
    InstitutionUpdateForm,
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(id = null) {
      this.id = id;

      if (id) {
        this.$refs.updateModal.show();
      } else {
        this.$refs.createModal.show();
      }
    },
    handleCreate() {
      this.$refs.institutionCreateForm.validateBeforeSubmit();
    },
    handleUpdate() {
      this.$refs.institutionUpdateForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.updateModal.hide();
      this.$refs.createModal.hide();
    },
  },
};
</script>
