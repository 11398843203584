<template>
  <fbk-page>
    <b-card>
      <b-row>
        <b-col>
          <div
            class="mb-1 d-inline-block"
            v-if="loggedInUser.can_edit === 1 && (loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1)"
          >
            <b-link class="btn btn-secondary" href @click.prevent="professionCreateModal"> Skrá starfsgrein stofnunar </b-link>
          </div>
          <div
            class="mb-1 ml-2 d-inline-block pr-1"
            v-if="loggedInUser.is_master_admin === 1 || (loggedInUser.is_admin === 1 && loggedInUser.is_mms === 1)"
          >
            <b-link class="btn btn-secondary" href @click.prevent="professionCategoryCreateModal"> Skrá starfsgreinaflokk </b-link>
          </div>
        </b-col>
      </b-row>
      <div class="text-center" v-if="loading"><i class="fa fa-fw fa-spin fa-spinner"></i> Augnablik...</div>
      <div v-else>
        <h5 class="my-4">Yfirlit yfir starfsgreinaflokka</h5>

        <div v-if="categoryList.length == 0"><i>Engar niðurstöður fundust</i></div>
        <profession-list-category
          class="mb-3"
          v-for="(category, index) in categoryList"
          :key="index"
          :item="category"
          :not-collapsed="category.id === createdId"
          ref="category"
        ></profession-list-category>
      </div>
    </b-card>
    <!-- Modal til að skrá starfsgreinaflokk -->
    <profession-category-crud-modal
      ref="professionCategoryCreateModal"
      @successful="professionCategoryCreated"
    ></profession-category-crud-modal>
    <!-- Modal til að skrá starfsgrein stofnunar-->
    <profession-crud-modal ref="professionCreateModal" @successful="professionCreated"></profession-crud-modal>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import core from '@/api/core';
// import Pagination from '@/components/Pagination.vue';
import ProfessionCategoryCrudModal from '@/components/professioncategory/ProfessionCategoryCrudModal.vue';
import ProfessionCrudModal from '@/components/profession/ProfessionCrudModal.vue';
import ProfessionListCategory from '@/components/profession/ProfessionListCategory.vue';

import { paginationMixin } from '@/utils';

export default {
  name: 'profession-list',
  mixins: [paginationMixin],
  components: {
    // Pagination,
    ProfessionCategoryCrudModal,
    ProfessionCrudModal,
    ProfessionListCategory,
  },
  data() {
    return {
      createdId: null,
      loading: false,
      categoryList: [],
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir starfsgreinaflokkar.
     */
    async loadList() {
      this.loading = true;
      this.categoryList = [];
      try {
        const response = await core.professionCategoryList();
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Aðgerðir á modal gluggum.
     */
    professionCategoryCreateModal() {
      this.$refs.professionCategoryCreateModal.show();
    },
    professionCategoryCreated(data) {
      this.createdId = data.id;
      this.loadList();
    },
    professionCreateModal() {
      this.$refs.professionCreateModal.show();
    },
    professionCreated(data) {
      this.createdId = data.id;
      this.loadList();
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    this.loadList();
  },
};
</script>
