<template>
  <div>
    <b-modal
      id="professionModuleUpdateModal"
      title="Breyta áföngum starfsgreinar"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      :ok-disabled="loading"
      ref="updateModal"
    >
      <profession-module-update-form
        ref="professionModuleUpdateForm"
        :id="id"
        :institutuion="institutuion"
        @successful="successful"
        @loading="setLoading"
      ></profession-module-update-form>
    </b-modal>
  </div>
</template>

<script>
import ProfessionModuleUpdateForm from '@/components/profession/ProfessionModuleUpdateForm.vue';

export default {
  name: 'profession-module-crud-modal',
  components: {
    ProfessionModuleUpdateForm,
  },
  data() {
    return {
      id: null,
      institutuion: null,
      loading: false,
    };
  },
  methods: {
    show(id = null, institutuion = null) {
      this.id = id;
      this.institutuion = institutuion;
      this.$refs.updateModal.show();
    },
    handleCreate() {
      this.$refs.professionModuleUpdateForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.updateModal.hide();
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>
