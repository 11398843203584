<template>
  <div>
    <b-alert variant="danger" :show="failed && errorList.length > 0">
      <ul class="list-unstyled mb-0">
        <li v-for="(err, idx) in errorList" :key="idx">{{ err }}</li>
      </ul>
    </b-alert>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Áfangi/ar:"
        label-for="modules"
        :state="submitted && errors.has('modules') ? false : null"
        :feedback="errors.first('modules')"
      >
        <div v-for="(module, index) in form.modules" :key="index" class="pb-2">
          {{ module.code }} {{ module.name }}
          <b-link @click.prevent="removeModule(index)" class="text-secondary"><i class="fa fa-remove"></i></b-link>
        </div>
        <v-select
          id="modules"
          name="modules"
          multiple
          placeholder="Skrifaðu inn í reitinn til að leita að áföngum."
          :on-change="modulesChanged"
          :debounce="250"
          v-model="selected"
          :options="options"
        >
          <template slot="no-options">Engir áfangar fundust.</template>
        </v-select>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import core from '@/api/core';
import inna from '@/api/inna';

import { djangoErrorsToVee } from '@/utils';

export default {
  name: 'profession-module-update-form',
  props: ['id', 'institutuion'],
  data() {
    return {
      submitted: false,
      loading: false,
      failed: false,
      updated: false,
      errorList: null,
      moduleList: [],
      selected: [],
      form: {
        name: '',
        modules: [],
      },
    };
  },
  computed: {
    options() {
      return this.moduleList
        .filter((v) => this.form.modules.filter((p) => p.id === v.id).length === 0)
        .map((v) => ({ value: v.id, label: `${v.code} ${v.name}` }));
    },
  },
  methods: {
    modulesChanged(val) {
      if (this.selected.length > 0) {
        this.selected = [];
      }
      val.forEach((v) =>
        this.form.modules.push({
          id: v.value,
          name: v.label,
        }),
      );
    },
    async getModules() {
      this.moduleList = [];
      const response = await inna.moduleList(this.institutuion.ssn);
      this.moduleList = response.data.items;
    },
    async load() {
      this.form.modules = [];
      const response = await core.professionDetail(this.id);
      this.form.name = response.data.name;
      if (response.data.modules) {
        this.form.modules = response.data.modules;
      }
    },
    removeModule(index) {
      this.form.modules.splice(index, 1);
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then((response) => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;

          const response = await core.professionUpdate(this.id, {
            name: this.form.name,
            modules: JSON.stringify(this.form.modules),
          });

          this.id = response.data.id;
          this.created = true;

          this.updated = true;
          this.displaySuccess('Skráðir áfangar hafa verið uppfærðir.');
          this.$emit('successful', { id: this.id });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.status === 400) {
            djangoErrorsToVee(e.response.data, this.errors, this.errorList);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.loading = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },
  created() {
    this.load();
    this.getModules();
  },
};
</script>
