<template>
  <fbk-page>
    <b-card class="careerbook-tabs">
      <b-row cols="2" class="d-none d-sm-none d-md-none d-lg-block">
        <b-col>
          <span ref="tabs"></span>
          <div class="pb-2">
            <b-nav class="mobile-navbar">
              <b-nav-item @click.prevent="tabIndex = 0" :active="tabIndex === 0">Allir notendur</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 1" :active="tabIndex === 1">Nemar</b-nav-item>
              <b-nav-item @click.prevent="tabIndex = 2" :active="tabIndex === 2">Umsýsluaðilar</b-nav-item>
              <b-nav-item v-if="loggedInUser.is_mms === 1" @click.prevent="tabIndex = 3" :active="tabIndex === 3"
                >Tilsjónaraðilar</b-nav-item
              >
              <b-nav-item v-else @click.prevent="tabIndex = 3" :active="tabIndex === 3">Matsaðilar</b-nav-item>
              <!-- <b-nav-item v-if="loggedInUser.is_education_center === 1" @click.prevent="tabIndex = 3" :active="tabIndex === 3"
                >Matsaðilar</b-nav-item
              >
              <b-nav-item v-else @click.prevent="tabIndex = 3" :active="tabIndex === 3">Tilsjónaraðilar</b-nav-item> -->
            </b-nav>
          </div>
        </b-col>
      </b-row>
      <b-col sm="12" md="12" lg="12" style="padding: 0px">
        <all-users-list v-show="tabIndex === 0"> </all-users-list>
        <students-list v-show="tabIndex === 1"> </students-list>
        <instructors-list v-show="tabIndex === 2"> </instructors-list>
        <supervisor-list v-show="tabIndex === 3"> </supervisor-list>
      </b-col>
    </b-card>
  </fbk-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import auth from '@/api/auth';
import core from '@/api/core';
import StudentsList from '@/components/user/StudentsList.vue';
import AllUsersList from '@/components/user/AllUsersList.vue';
import InstructorsList from '@/components/user/InstructorsList.vue';
import SupervisorList from '@/components/user/SupervisorList.vue';

import { debounce, paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'user-list',
  mixins: [paginationMixin],
  components: {
    AllUsersList,
    StudentsList,
    InstructorsList,
    SupervisorList,
  },
  data() {
    return {
      loading: {
        workplace: false,
        profession: false,
      },
      page: null,
      pagination: null,
      tabsFixed: false,
      tabIndex: 0,
      userList: [],
      searchName: '',
      institutionList: [],
      searchInstitution: null,
    };
  },
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir notendur.
     */
    async loadList(page = null, limit = null) {
      this.loading.workplace = true;
      this.pagination = null;
      this.userList = [];

      try {
        const response = await auth.userList(page, limit, this.searchName, this.searchInstitution);
        this.userList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplace = false;
      }
    },
    async loadInstitution() {
      this.loading.Institution = true;
      try {
        const response = await core.institutionList(null, null, null);
        this.institutionList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja yfirlit yfir stofnanir. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading.profession = false;
      }
    },

    /*
     * Aðgerðir á modal gluggum.
     */
    userCreateModal() {
      this.$refs.userCreateModal.show();
    },
    userUpdateModal(id) {
      this.$refs.userUpdateModal.show(id);
    },
    userCreated() {
      this.loadList();
    },
    userUpdated() {
      this.loadList();
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
    searchInstitution: debounce(function () {
      this.loadList(null, null);
    }, 500),
  },
  created() {
    this.loadList();
    this.loadInstitution();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
