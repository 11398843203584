<template>
  <div>
    <b-row>
      <b-col>
        <h5 class="my-4">Yfirlit yfir samninga nema</h5>
      </b-col>
    </b-row>
    <table class="table">
      <tr>
        <th style="padding: 10px 10px 10px 0px">
          <b-form-group label="" label-for="contract">
            <b-form-input id="name" name="name" type="text" v-model="searchName"
              placeholder="Leita eftir nafni eða kennitölu">
            </b-form-input></b-form-group>
        </th>
        <th style="padding: 10px 10px 10px 0px">
          <b-form-group label="" label-for="contract">
            <b-form-select id="status" v-model="form.status" :options="status" />
          </b-form-group>
        </th>
        <th style="padding: 10px 0px 0px 0px">
          <b-form-group label="" label-for="contract">
            <!-- <b-form-checkbox v-model="deactivated" :value="true" :unchecked-value="false"></b-form-checkbox> -->
            <b-form-select id="deactivated" v-model="form.deactivated" :options="deactivated" />
          </b-form-group>
        </th>
      </tr>
    </table>

    <table class="table table-hover">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Samningur</th>
          <th>Skóli</th>
          <th>Staða</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="contractList.length == 0">
          <td colspan="3">
            <i>Engar leitarniðurstöður fundust.</i>
          </td>
        </tr>
        <tr
          class="table-row-linkable"
          v-for="(contract, index) in contractList"
          :key="index"
          v-b-tooltip.hover.left
          title="Smelltu til að skoða ferilbók og samning"
          @click.prevent="
            $router.push({
              name: 'CareerbookById',
              params: { profession: contract.profession_version, id: contract.book_id, institution: contract.institution },
            })
          "
        >
          <td>
            <strong>{{ contract.name }}</strong>

            <br />
            <span class="small text-muted">{{ contract.ssn }}</span>
          </td>
          <td v-if="contract.contract === 'SKOLALEID'">Skólaleið</td>
          <td v-if="contract.contract === 'MEISTARALEID'">Meistaraleið</td>
          <td>{{ contract.institution }}</td>
          <td v-if="contract.status === 'NEW'">Vistaður</td>
          <td v-if="contract.status === 'SUBMITTED'">
            Sendur til undirritunar<br />
            <span v-if="contract.status === 'SUBMITTED'" class="small text-muted"
              >Sendur: {{ contract.submitted_at | moment('DD.MM.YYYY HH:mm') }}</span
            >
          </td>
          <td v-if="contract.status === 'SIGNED'">
            Undirritaður<br />
            <span v-if="contract.status === 'SIGNED'" class="small text-muted"
              >Undirritaður: {{ contract.signed_at | moment('DD.MM.YYYY HH:mm') }}</span
            >
          </td>
          <td v-if="contract.status === 'REJECTED'">Hafnað</td>
          <td v-if="contract.status === 'REGISTERED'">Skráður</td>
          <td v-if="contract.status === 'DEACTIVATED'">
            Afvirkjaður <br />
            <span v-if="contract.status === 'DEACTIVATED'" class="small text-muted">Ástæða: {{ contract.deactivate_reason }} </span>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit" @change-page="changePage"></pagination>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Pagination from '@/components/Pagination.vue';
import core from '@/api/core';

import { debounce, paginationMixin, parsePagination } from '@/utils';

export default {
  name: 'contract',
  mixins: [paginationMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      loading: {
        contracts: false,
      },
      status: [
        {
          text: '-- Veldu stöðu --',
          value: null,
        },
        {
          value: 'NEW',
          text: 'Vistaður',
        },
        {
          value: 'SUBMITTED',
          text: 'Sendur til undirritunar',
        },
        {
          value: 'SIGNED',
          text: 'Undirritaður',
        },
        {
          value: 'REJECTED',
          text: 'Hafnað',
        },
        {
          value: 'REGISTERED',
          text: 'Skráður',
        },
      ],
      deactivated: [
        {
          value: 0,
          text: 'Virkur',
        },
        {
          value: 1,
          text: 'Óvirkur',
        },
        {
          value: 2,
          text: 'Allir',
        },
      ],
      page: null,
      pagination: null,
      tabsFixed: false,
      contractList: [],
      statusList: [],
      searchName: '',
      searchStatus: '',
      form: {
        status: null,
        deactivated: 0,
      },
    };
  },
  computed: {
    currentValueStatus() {
      return this.status.find((status) => status.value === this.form.status);
    },
    currentDeactivatedValue() {
      return this.deactivated.find((status) => status.value === this.form.deactivated);
    },
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  methods: {
    /*
     * Sækja yfirlit yfir samninga.
     */
    async loadList(page = null, limit = null) {
      this.loading.contracts = true;
      this.pagination = null;
      this.contractList = [];

      try {
        const response = await core.signetContractList(
          page,
          limit,
          this.searchName,
          this.currentValueStatus.value,
          this.currentDeactivatedValue.value,
          this.loggedInUser.user_institution,
        );
        this.contractList = response.data.items;
        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.workplace = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  watch: {
    searchName: debounce(function () {
      this.loadList(null, null);
    }, 500),
    currentValueStatus() {
      this.loadList(null, null);
    },
    currentDeactivatedValue() {
      this.loadList(null, null);
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss" scoped>
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
</style>
