<template>
  <b-modal
    title="Sendi þig í nýja bók..."
    size="sm"
    :no-close-on-backdrop="true"
    ok-title="Skoða bók núna"
    cancel-title="Vera kyrr"
    @ok.prevent="ok"
    @cancel="cancel"
    hide-header-close
    ref="redirectModal"
  >
    <div class="count-down">
      {{ countDown }}
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'redirect-modal',
  data() {
    return {
      countDown: 10,
      redirectBookId: null,
      timeout: null,
    };
  },
  methods: {
    show(bookId) {
      this.redirectBookId = bookId;
      this.$refs.redirectModal.show();
      this.interval();
    },
    async ok() {
      this.clearInterval();
      this.redirect();
    },
    cancel() {
      this.clearInterval();
    },
    async interval() {
      this.timeout = setTimeout(() => {
        if (this.countDown === 0) {
          this.redirect();
        } else {
          this.countDown -= 1;
          this.interval();
        }
      }, 1000);
    },
    clearInterval() {
      clearTimeout(this.timeout);
    },
    redirect() {
      this.$router.push(`/ferilbok/${this.redirectBookId}`);
    },
  },
};
</script>

<style scoped lang="scss">
.count-down {
  font-size: 2rem;
  text-align: center;
}
</style>
