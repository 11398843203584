<template>
  <div>
    <b-modal
      id="remindContractModal"
      title="Minna undirritendur á að skrifa undir samning"
      lazy
      ok-title="Senda áminningu"
      cancel-title="Hætta við"
      @ok="accept"
      ref="remindModal"
    >
      <ul>
        <li v-for="contact in contacts" :key="contact.name">
          <i v-if="contact.signedAt" class="fa fa-check-circle-o signed-icon"></i>
          <i v-else class="fa fa-circle-o unsigned-icon"></i>
          {{ contact.name }}
          <em v-if="contact.signedAt"> - hefur skrifað undir</em>
          <em v-else-if="lastReminder"> - áminning var send {{ lastReminder }}</em>
          <em v-else> - engin áminning send</em>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'contract-remind-modal',
  data() {
    return {
      id: null,
      lastReminder: null,
      contacts: [
        {
          name: null,
          signedAt: null,
        },
      ],
    };
  },
  methods: {
    show({ id, lastReminder, contacts }) {
      this.id = id;
      this.lastReminder = lastReminder ? moment(lastReminder).format('DD.MM.YYYY') : null;
      this.contacts = contacts;
      this.$refs.remindModal.show();
    },
    accept() {
      this.$emit('accept', this.id);
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0px;
}
.signed-icon {
  color: green;
}
.unsigned-icon {
  color: lightgray;
}
.fa {
  margin-right: 3px;
}
</style>
