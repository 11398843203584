<template>
  <div>
    <b-modal
      id="userCreateModal"
      :title="modalTitle"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      ref="createModal"
    >
      <user-create-form ref="userCreateForm" :id="id" @successful="successful"></user-create-form>
    </b-modal>
    <b-modal
      id="userUpdateModal"
      title="Breyta notanda"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista breytingar"
      cancel-title="Hætta við"
      @ok.prevent="handleUpdate"
      ref="updateModal"
    >
      <user-update-form ref="userUpdateForm" :id="id" @successful="successful"></user-update-form>
    </b-modal>
  </div>
</template>

<script>
import UserCreateForm from '@/components/user/UserCreateForm.vue';

export default {
  name: 'user-crud-modal',
  components: {
    UserCreateForm,
  },
  data() {
    return {
      id: null,
      modalTitle: 'Skrá notanda',
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      if (this.id) {
        this.modalTitle = 'Breyta notanda';
      }
      this.$refs.createModal.show();
    },
    handleCreate() {
      this.$refs.userCreateForm.validateBeforeSubmit();
    },
    handleUpdate() {
      this.$refs.userUpdateForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.updateModal.hide();
      this.$refs.createModal.hide();
    },
  },
};
</script>
