<template>
  <div>
    <b-modal
      id="workplaceCreateModal"
      :title="modalTitle"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      ref="createModal"
    >
      <workplace-create-form ref="workplaceCreateForm" :id="id" @successful="successful"></workplace-create-form>
    </b-modal>
    <b-modal
      id="workplaceUpdateModal"
      title="Breyta vinnustað"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista breytingar"
      cancel-title="Hætta við"
      @ok.prevent="handleUpdate"
      ref="updateModal"
    >
      <workplace-update-form ref="workplaceUpdateForm" :id="id" @successful="successful"></workplace-update-form>
    </b-modal>
  </div>
</template>

<script>
import WorkplaceCreateForm from '@/components/workplace/WorkplaceCreateForm.vue';

export default {
  name: 'workplace-crud-modal',
  components: {
    WorkplaceCreateForm,
  },
  data() {
    return {
      id: null,
      modalTitle: 'Skrá vinnustað',
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      if (this.id) {
        this.modalTitle = 'Breyta vinnustað';
      }
      this.$refs.createModal.show();
    },
    handleCreate() {
      this.$refs.workplaceCreateForm.validateBeforeSubmit();
    },
    handleUpdate() {
      this.$refs.workplaceUpdateForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.updateModal.hide();
      this.$refs.createModal.hide();
    },
  },
};
</script>
