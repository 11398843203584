<template>
  <div>
    <b-modal
      id="competencecategoryCreateModal"
      title="Skrá flokk hæfnikrafna"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="handleCreate"
      :ok-disabled="loading"
      ref="createModal"
    >
      <category-create-form
        ref="competenceCategoryCreateForm"
        :professionId="professionId"
        @successful="successful"
        @loading="setLoading"
      ></category-create-form>
    </b-modal>
  </div>
</template>

<script>
import CategoryCreateForm from '@/components/careerbook/competence/CategoryCreateForm.vue';

export default {
  name: 'category-crud-modal',
  components: {
    CategoryCreateForm,
  },
  props: ['professionId'],
  data() {
    return {
      id: null,
      loading: false,
    };
  },
  methods: {
    show(id = null) {
      this.id = id;
      this.$refs.createModal.show();
    },
    handleCreate() {
      this.$refs.competenceCategoryCreateForm.validateBeforeSubmit();
    },
    successful(data) {
      this.$emit('successful', data);
      this.$refs.createModal.hide();
    },
    setLoading(value) {
      this.loading = value;
    },
  },
};
</script>
