<template>
  <div>
    <b-form>
      <b-card header="Ferilbók" header-bg-variant="secondary" header-text-variant="white" header-class="h5" class="mb-3">
        <br />
        <b-row>
          <b-col>
            <b-form-group label="Nemandi:" label-for="name" label-class="font-weight-bold pt-0">
              <b-form>
                <b-form-text id="name" style="font-size: 14px">{{ form.book.studentName }}</b-form-text>
                <div style="color: #c1c1c1">{{ form.book.studentId }}</div>
              </b-form>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Starfsgrein:" label-for="profession" label-class="font-weight-bold pt-0">
              <b-form>
                <b-form-text id="profession" style="font-size: 14px"> {{ form.book.profession }}</b-form-text>
              </b-form>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Dagsetning ferilbókar:" label-for="date" label-class="font-weight-bold pt-0">
              <b-form>
                <b-form-text id="date" style="font-size: 14px">
                  <div>
                    <span>{{ form.book.date_from | moment('DD.MM.YYYY') }}</span>
                    <span v-if="form.book.date_to != null"> - {{ form.book.date_to | moment('DD.MM.YYYY') }}</span>
                    <span v-else> - óákveðið </span>
                  </div>
                </b-form-text>
              </b-form>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Afrita frá/til:" label-for="fromto" label-class="font-weight-bold pt-0">
              <b-form>
                <b-form-text id="fromto" style="font-size: 14px">
                  <span> {{ form.book.schoolName }} - {{ loggedInUser.user_institution }}</span>
                </b-form-text>
              </b-form>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <br />
        <br />
      </b-card>
      <b-card
        header="Upplýsingar um nýja umsýsluaðila"
        header-bg-variant="secondary"
        header-text-variant="white"
        header-class="h5"
        class="mb-3"
      >
        <br />
        <div>
          <b-row>
            <b-col>
              <b-form-group>
                <b-form-input
                  id="instructor-ssn"
                  name="instructor-ssn"
                  type="text"
                  placeholder="Kennitala"
                  v-model="form.instructor.ssn"
                  ref="instructor"
                  required
                  :state="form.instructor.is_thjodskra == false || (submitted && errors.has('instructor-ssn')) ? false : null"
                  maxLength="10"
                  @input="validateInstructor(form.instructor)"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label=""
                label-for="instructor-name"
                :state="submitted && errors.has('instructor-name') ? false : null"
                :feedback="errors.first('instructor-name')"
              >
                <b-form-input
                  id="instructor-name"
                  name="instructor-name"
                  type="text"
                  placeholder="Nafn"
                  v-model="form.instructor.name"
                  required
                  disabled
                  :state="submitted && errors.has('instructor-name') ? false : null"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="" label-for="instructor-mobile" :feedback="errors.first('instructor-mobile')">
                <b-form-input
                  id="instructor-mobile"
                  name="instructor-mobile"
                  type="tel"
                  v-model="form.instructor.mobile"
                  required
                  placeholder="Farsímanr."
                  data-vv-as="farsími umsýsluaðila"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="" label-for="instructor-email" :feedback="errors.first('instructor-email')">
                <b-form-input
                  id="instructor-email"
                  name="instructor-email"
                  type="tel"
                  v-model="form.instructor.email"
                  required
                  placeholder="Netfang"
                  data-vv-as="netfang umsýsluaðila"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="">
                <b-btn
                  :disabled="form.instructor.ssn === '' || form.instructor.is_thjodskra == false"
                  @click.stop.prevent="addInstructor"
                  class="pull-right"
                >
                  <i class="fa fa-fw fa-plus-circle"></i>
                </b-btn>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  id="instructor-signer"
                  name="instructor-signer"
                  v-model="form.instructor.is_signet_signer"
                  :value="true"
                  :unchecked-value="false"
                  v-b-popover.hover.left="'Sé hakað við hér fær umsýsluaðili boð um að undirrita samninga vegna viðkomandi ferilbókar'"
                >
                  Undirritar samninga
                </b-form-checkbox></b-form-group
              >
            </b-col>
          </b-row>
          <br />
          <br />
          <div>
            <table class="table table-sm" v-if="instructors.length > 0">
              <thead>
                <tr v-if="instructors.length > 0">
                  <th>Kennitala</th>
                  <th>Nafn</th>
                  <th>Símanúmer</th>
                  <th>Netfang</th>
                  <th>Undirritandi</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(instructor, instructorIndex) in instructors" :key="instructorIndex">
                  <td>{{ instructor.ssn }}</td>
                  <td>{{ instructor.name }}</td>
                  <td>{{ instructor.mobile }}</td>
                  <td>{{ instructor.email }}</td>
                  <td v-if="instructor.is_signet_signer">Já</td>
                  <td v-else>Nei</td>
                  <td>
                    <i class="fa fa-fw fa-trash" @click="removeInstructor"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import book from '@/api/book';

export default {
  name: 'copy-form',
  props: ['id'],
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      newBookId: '',
      book: [],
      invalid: {
        instructor: false,
        instructorSigner: false,
      },
      instructors: [],
      form: {
        book: {
          studentName: '',
          studentId: '',
          profession: '',
          date_from: null,
          date_to: null,
          schoolName: '',
        },
        instructor: {
          id: '',
          name: '',
          ssn: '',
          mobile: '',
          email: '',
          is_signet_signer: false,
        },
      },
    };
  },
  methods: {
    async loadBook() {
      const response = await book.careerbookDetail(this.id, null, null, null, 1);
      this.book = response.data;

      this.$set(this.form.book, 'studentName', response.data.student.name);
      this.$set(this.form.book, 'studentId', response.data.student.id);
      this.$set(this.form.book, 'profession', response.data.profession_version.profession.name);
      this.$set(this.form.book, 'date_from', response.data.date_from);
      this.$set(this.form.book, 'date_to', response.data.date_to);
      this.$set(this.form.book, 'schoolName', response.data.school.name);
    },
    async validateBeforeSubmit() {
      this.loading = true;

      try {
        await this.$validator.validateAll().then((response) => {
          if (!response || this.validate() === false) {
            this.displayError('Gögn ekki rétt. Vinsamlegast skoðið');
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          const item = {
            id: this.book.id,
            school: this.loggedInUser.user_institution_id,
            instructors: JSON.stringify(this.instructors),
          };
          const response = await book.careerbookCopy(item);

          this.newBookId = response.data.id;

          this.$router.push({ name: 'CareerbookById', params: { id: this.newBookId } });
          this.displaySuccess(`Ferilbók fyrir ${this.form.book.studentName} í „${this.form.book.profession}“ virkjuð`);
        } catch (e) {
          this.$log.debug(e);
          if (e.response && e.response.status === 400 && e.response.data.error !== '') {
            this.displayError('Aðeins má stofna ferilbók einu sinni fyrir hverja opinbera starfsgrein');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur`);
          }
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    async validateInstructor(instructor) {
      instructor.name = ''; // eslint-disable-line no-param-reassign
      instructor.is_thjodskra = null; // eslint-disable-line no-param-reassign
      if (instructor.ssn && instructor.ssn.length === 10) {
        instructor.loading = true; // eslint-disable-line no-param-reassign
        try {
          const response = await book.searchInstructor(instructor.ssn);
          instructor.is_thjodskra = response.data.is_thjodskra; // eslint-disable-line no-param-reassign, max-len
          instructor.email = response.data.email; // eslint-disable-line no-param-reassign,
          instructor.mobile = response.data.mobile; // eslint-disable-line no-param-reassign,
          if (response.data.is_thjodskra) {
            instructor.name = response.data.name; // eslint-disable-line no-param-reassign
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          instructor.loading = false; // eslint-disable-line no-param-reassign
        }
      }
    },
    addInstructor() {
      this.instructors.push(this.form.instructor);
      this.form.instructor = { id: '', name: '', ssn: '', mobile: '', email: '' };
    },
    removeInstructor(index) {
      this.instructors.splice(index, 1);
    },
    validate() {
      let valid = true;

      if (this.instructors.length === 0) {
        valid = false;
      }
      this.instructors.forEach((e) => {
        if (e.name === '' || e.ssn === '' || e.mobile === '' || e.email === '') {
          valid = false;
        }
      });

      return valid;
    },
    ...mapActions({
      displaySuccess: 'displaySuccess',
      displayError: 'displayError',
    }),
  },
  created() {
    this.loadBook();
  },
};
</script>
