<template>
  <div>
    <!-- Hér er flokkað niður í verkflokka -->
    <!-- Svo er flokkað niður í verkþætti verkflokks og þar undir eru myndir verkþáttar -->
    <h4 v-if="!id">Hér koma allar myndir ferilbókar</h4>
    <div v-for="category in categoryList" :key="category.id">
      <div v-if="category.hasPhotos !== 0">
        <category-photos :categoryId="category.id" :categoryName="category.name" :id="id" :student="student"></category-photos>
      </div>
    </div>
  </div>
</template>

<script>
import book from '@/api/book';
import CategoryPhotos from '@/components/careerbook/image/CategoryPhotos.vue';

export default {
  name: 'category-photo',
  props: ['id', 'student'],
  components: {
    CategoryPhotos,
  },
  data() {
    return {
      loading: false,
      categoryList: [],
    };
  },

  methods: {
    async loadCategory() {
      this.loading = true;
      this.categoryList = {};
      try {
        let response = null;
        response = await book.careerbookCategoryList(this.id);
        this.categoryList = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(
          `Óvænt villa (${e.response ? e.response.status : -1}) kom upp við að sækja verkflokka. Vinsamlegast reyndu aftur.`,
        );
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadCategory();
  },
};
</script>
<style>
div.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 24%;
  padding: 10px;
  min-height: 300px;
}

div.gallery img {
  width: 100%;
  height: auto;
  padding-top: 20px;
}
</style>
